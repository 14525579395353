import React from 'react';
import { Button, message, Drawer, Space } from 'antd';
import { useApp } from '@/utils/useapp';
import { TBill } from '@/types';
import { useForm } from 'antd/lib/form/Form';
import PaymentForm from './PaymentForm';

interface Props {
  onSaved: () => void;
  bills: TBill[];
}

const Payment: React.FC<Props> = ({ onSaved, bills = [] }) => {
  const app = useApp();

  const [visible, setVisible] = React.useState(false);
  const [form] = useForm();
  const [submitting, setSubmitting] = React.useState(false);

  const showDrawer = () => {
    if (bills.find((b) => !b.is_approved)) {
      return message.error('There has unapproved bill');
    }

    if (bills.length === 0) {
      return message.error('Please select at least one bill');
    }
    form.resetFields();
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleSave = async () => {
    const values = await form.validateFields();

    setSubmitting(true);

    try {
      !values.id
        ? await app.service.post('orderBillPayments', { data: values })
        : await app.service.put(`orderBillPayments/${values.id}`, {
            data: values,
          });
      message.success('Saved');

      onSaved && onSaved();
      onClose();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setSubmitting(false);
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        Make Payment
      </Button>
      <Drawer
        width={'80%'}
        title="Receive Payment"
        placement="right"
        onClose={onClose}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" loading={submitting} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <PaymentForm form={form} bills={bills} />
      </Drawer>
    </>
  );
};

export default Payment;
