import AutoResizeTable from '@/components/AutoResizeTable';
import { Button, Form, Space, TablePaginationConfig } from 'antd';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
} from 'antd/lib/table/interface';
import { Rate } from './QuoteForm';
import { Key, useState } from 'react';
import { EditableText } from '@/components/Editable';
import { useForm } from 'antd/lib/form/Form';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

export interface RateTableProps {
  rates: Rate[];
  selectedRates: Key[];
  disableSelect: boolean;
  setSelectedRate: (rate: Key) => void;
  handleSelect: (rate: Rate) => void;
  handleUpdateRate: (rateId: number, newRate: number) => void;
}

export const RateTable = ({
  rates,
  selectedRates,
  disableSelect,
  setSelectedRate,
  handleSelect,
  handleUpdateRate,
}: RateTableProps) => {
  const [tableParams, setTableParams] = useState<TableParams>({});
  const [form] = useForm();

  const columns: ColumnsType<Rate> = [
    {
      title: 'Source',
      dataIndex: ['vendor', 'name'],
      width: 120,
    },
    {
      title: 'Transit days',
      dataIndex: 'transit_days',
      defaultSortOrder: 'ascend',
      width: 120,
    },
    {
      title: 'Price',
      dataIndex: 'quote_rate',
      width: 120,
    },
    {
      title: 'Recommend Rate',
      dataIndex: 'recommend_rate',
      width: 120,
    },
    {
      title: 'Final Rate',
      dataIndex: 'rate',
      width: 120,
      sorter: true,
      render: (text, record) => {
        return (
          <Form.Item
            noStyle
            name={['data', record.id, 'rate']}
            initialValue={record.rate}
          >
            <EditableText
              type="number"
              name="rate"
              value={record.rate.toString()}
              onChange={(name: string, value: any) => {
                handleUpdateRate(record.id, parseFloat(value));
                form.setFieldValue(['data', record.id, 'rate'], value);
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Margin %',
      width: 120,
      render: (text, record) => {
        const rate = form.getFieldValue(['data', record.id, 'rate']);
        return (
          <div>
            {(((rate - record.quote_rate) / record.quote_rate) * 100).toFixed(
              2,
            )}
          </div>
        );
      },
    },
    {
      title: 'Margin $',
      width: 120,
      render: (text, record) => {
        const rate = form.getFieldValue(['data', record.id, 'rate']);
        return <div>{(rate - record.quote_rate).toFixed(2)}</div>;
      },
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <Space>
          <Button
            disabled={disableSelect}
            size="small"
            onClick={() =>
              handleSelect({
                ...record,
                rate: form.getFieldValue(['data', record.id, 'rate']),
              })
            }
          >
            Select
          </Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Rate> | SorterResult<Rate>[],
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <Space direction="vertical">
      <Form form={form} component={false}>
        <AutoResizeTable
          size="small"
          tableLayout="auto"
          pagination={false}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedRates,
            onChange: (selectedRowKeys) => setSelectedRate(selectedRowKeys[0]),
          }}
          filter={tableParams.filters}
          rowKey="id"
          columns={columns}
          onChange={handleTableChange}
          dataSource={rates || []}
          sticky
          scroll={{
            x: 'auto',
          }}
        />
      </Form>
    </Space>
  );
};
