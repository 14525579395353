import { Button, Modal } from 'antd';
import { FC } from 'react';
import { TPrepaidOrder } from '..';

interface IPreChargeButtonProps {
  order: TPrepaidOrder;
  onSubmit: () => void;
}

const PreChargeButton: FC<IPreChargeButtonProps> = ({ order, onSubmit }) => {
  return (
    <Button
      size="small"
      type="link"
      onClick={() => {
        Modal.confirm({
          title: 'Approve Payment',
          content: (
            <>
              <div> {order.account.user.name}</div>
              <div>
                Prepaid Amount: {order.amount}{' '}
                <span className="text-gray">(USD)</span>
              </div>
              <div className="mt-sm text-success">
                Please confirm the prepayment. After the prepayment, the
                customer prepayment amount will increase
              </div>
            </>
          ),
          onOk: () => onSubmit(),
        });
      }}
    >
      Approve
    </Button>
  );
};

export default PreChargeButton;
