import request from 'umi-request';
import { auth } from '@/stores';
import qs from 'qs';
import { RequestConfig } from 'umi';
import { saveAs } from 'file-saver';

const UNAUTHORIZED = 401;

const errorHandler = (error: any) => {
  if (error.type === 'TypeError' && error.response === null) {
    throw new Error('Service temporarily unavailable');
  }

  throw error;
};

export const toSessionHeaders = () => {
  return auth.token ? { Authorization: `Bearer ${auth.token}` } : '';
};

const toDefaultOptions: () => RequestConfig = () => ({
  errorHandler,
  timeout: 300000,
  headers: {
    ...toSessionHeaders(),
    Accept: 'application/json',
  },
  // 'paramSerializer' is a function in charge of serializing 'params'. ( be aware of 'params' was merged by extends's 'params' and request's 'params' and URLSearchParams will be transform to plain object. )
  paramsSerializer: function (params: { [key: string]: any }) {
    return qs.stringify(params);
  },
});

// request.interceptors.request.use((url, options) => {
//   if (auth.token) {
//     const headers = {
//       Authorization: `Bearer ${auth.token}`,
//     };
//     return {
//       url,
//       options: { ...options, headers },
//     };
//   }

//   return {
//     url,
//     options: { ...options },
//   };
// });

// response interceptor, chagne response
request.interceptors.response.use((response: Response, options) => {
  // redirect to login page if unauthorized
  if (response.status === UNAUTHORIZED) {
    auth.logout();
  }

  const authorization = response.headers.get('authorization');

  if (authorization) {
    const result = authorization.match(/^Bearer (.+)/);

    if (result) {
      auth.setToken(result[1]);
    }
  }

  return response;
});

export const buildUrl = (url: string, query?: { [key: string]: any }) => {
  const baseUrl = `${API_URL || '/api'}/${url}`;
  let queryString = '';
  if (typeof query !== 'undefined') {
    queryString =
      (baseUrl.indexOf('?') !== -1 ? '&' : '?') +
      qs.stringify({
        ...query,
      });
  }
  return `${baseUrl}${queryString}`;
};

const parseResult = async (result: { [key: string]: any }) => {
  return result;
};

class Request {
  /* get 请求 */
  async get(url: string, options: any = {}): Promise<any> {
    return parseResult(
      await request.get(buildUrl(url), { ...toDefaultOptions(), ...options }),
    );
  }
  /* post 请求 */
  async post(url: string, options: any = {}): Promise<any> {
    return parseResult(
      await request.post(buildUrl(url), { ...toDefaultOptions(), ...options }),
    );
  }
  /* put 请求 */
  async put(url: string, options: any = {}): Promise<any> {
    return parseResult(
      await request.put(buildUrl(url), { ...toDefaultOptions(), ...options }),
    );
  }
  /* delete 请求 */
  async delete(url: string, options: any = {}): Promise<any> {
    return parseResult(
      await request.delete(buildUrl(url), {
        ...toDefaultOptions(),
        ...options,
      }),
    );
  }

  async patch(url: string, options: any = {}): Promise<any> {
    return parseResult(
      await request.patch(buildUrl(url), { ...toDefaultOptions(), ...options }),
    );
  }

  async download(url: string, options: any = {}): Promise<any> {
    const { data, response } = await request(buildUrl(url), {
      ...toDefaultOptions(),
      ...options,
      responseType: 'blob',
      getResponse: true,
    });

    const filename = response.headers
      ?.get('content-disposition')
      ?.split(';')
      ?.find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim()
      .replace(/^"/, '')
      .replace(/"$/, '');

    saveAs(data, filename);
  }

  async preview(url: string, options: any = {}): Promise<any> {
    const data = await request(buildUrl(url), {
      ...toDefaultOptions(),
      ...options,
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(data);
    window.open(fileURL, '_blank');
  }

  /* external request */
  async api(url: string, options: any = {}): Promise<any> {
    return parseResult(await request(url, options));
  }
}

export default new Request();
