import { CACHE_OCEAN_CARRIER_KEY } from '@/stores/cache';
import { TOceanCarrier } from '@/types';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

export const OceanCarrierSelect: React.FC<SyncCacheModelSelectProps> = ({
  ...props
}) => {
  return (
    <SyncCacheModelSelect
      {...props}
      cacheKey={CACHE_OCEAN_CARRIER_KEY}
      renderOption={(row: TOceanCarrier) => [row.code, row.name].join('|')}
    />
  );
};
