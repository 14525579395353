import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message, Row, Col, Divider, Tag } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { FC, useEffect } from 'react';
import BillForm from './BillForm';
import { serialize } from 'object-to-formdata';
import { get } from 'lodash';
import { TBill, TRate } from '@/types';
import { BuyRateTabs } from './BuyRateTabs';
import { DispatchTableDrawer } from '@/pages/dispatchs/components/Table/DispatchTableDrawer';
import { ApproveButton } from './ApproveButton';
import { BuyRateFormCard } from './BuyRateFormCard';
import { DISPUTE_TYPE_BILL, DisputeMemo } from '@/components/DisputeMemo';
import { BILL_TYPE_FTL, STATUS_MAP } from '../data';

import { BILL_TYPE_LTL, BILL_TYPE_ORDER } from '../data';
import * as LTLTable from '@/pages/truckload/ltl/shipments/ShipmentTable/ShipmentTableInstance';
import * as FTLTable from '@/pages/truckload/ftl/shipments/ShipmentTable/ShipmentTableInstance';

interface IBillDrawerProp {
  id: number;
  billableId?: number;
  billableType?: string;
  container?: any;
  visible: boolean;
  onClose: () => void;
  onSaved?: () => void;
  initialValues?: {
    invoice_number?: string;
    invoice_date?: string;
    due_date?: string;
    bill_from?: string;
    items?: Array<{
      name: string;
      description?: string;
      rate: number;
      quantity: number;
      amount: number;
    }>;
    new_files?: Array<{
      name: string;
      content: string | null;
      type: string;
    }>;
  };
}

interface TruckLoadBillFormProps {
  form: any;
  billableType: string;
  containers: any[];
  container: any;
  buyRates: TRate[];
  loading: boolean;
  onChangingContainerNumbers: (numbers: any) => void;
  initialFiles?: Array<{
    name: string;
    content: string;
    type: string;
  }>;
  children?: React.ReactNode;
}

const TruckLoadBillForm: FC<TruckLoadBillFormProps> = ({
  form,
  billableType,
  containers,
  container,
  buyRates,
  loading,
  onChangingContainerNumbers,
  initialFiles,
  children,
}) => {
  return (
    <>
      {children}
      <Divider />
      <BillForm
        form={form}
        containers={containers}
        containerNumber={get(container, 'number', undefined)}
        buyRate={get(container, 'buy_rate') || (buyRates && buyRates[0])}
        loading={loading}
        onChangingContainerNumbers={onChangingContainerNumbers}
        billableType={billableType || form.getFieldValue('billable_type')}
        initialFiles={initialFiles}
      />
    </>
  );
};

interface OrderBillFormProps {
  form: any;
  billableId: number | undefined;
  billableType: string;
  containers: any[];
  containerNumbers: any[];
  container: any;
  buyRates: TRate[];
  loading: boolean;
  fetchContainers: () => void;
  onChangingContainerNumbers: (numbers: any) => void;
  initialFiles?: Array<{
    name: string;
    content: string;
    type: string;
  }>;
}

const OrderBillForm: FC<OrderBillFormProps> = ({
  form,
  billableId,
  billableType,
  containers,
  containerNumbers,
  fetchContainers,
  onChangingContainerNumbers,
  buyRates,
  container,
  loading,
  initialFiles,
}) => {
  return (
    <>
      <div className="mb-md">
        <DispatchTableDrawer
          orderId={billableId || form.getFieldValue('billable_id')}
          scroll={{
            x: 500,
            y: 150,
          }}
          containers={containers}
          containerNumbers={containerNumbers}
          refreshContainers={fetchContainers}
        />
      </div>
      <div
        style={{
          maxHeight: '70vh',
          overflowY: 'scroll',
        }}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={11}>
            {(get(container, 'buy_rate', false) || buyRates.length == 0) && (
              <BuyRateFormCard
                orderId={billableId || form.getFieldValue('billable_id')}
                container={container}
              />
            )}

            {!get(container, 'buy_rate', false) && buyRates.length > 0 && (
              <BuyRateTabs
                buyRates={buyRates}
                orderId={billableId || form.getFieldValue('billable_id')}
              />
            )}
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col className="gutter-row" span={12}>
            <BillForm
              form={form}
              containers={containers}
              containerNumber={get(container, 'number', undefined)}
              buyRate={get(container, 'buy_rate') || (buyRates && buyRates[0])}
              loading={loading}
              onChangingContainerNumbers={onChangingContainerNumbers}
              billableType={billableType || form.getFieldValue('billable_type')}
              initialFiles={initialFiles}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export const BillDrawer: React.FC<IBillDrawerProp> = ({
  id,
  billableId,
  billableType = BILL_TYPE_ORDER,
  container,
  visible,
  onClose,
  onSaved,
  initialValues,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();
  const [containers, setContainers] = React.useState<Array<any>>([]);
  const [billOrderId, setBillOrderId] = React.useState(billableId || 0);
  const [bill, setBill] = React.useState<TBill>();
  const [buyRates, setBuyRates] = React.useState<TRate[]>([]);

  const [containerNumbers, setContainersNumbers] = React.useState<
    Array<string>
  >(container?.number ? [container.number] : []);

  const fetchContainers = async () => {
    if (
      !billOrderId ||
      billableType == BILL_TYPE_LTL ||
      billableType == BILL_TYPE_FTL
    ) {
      return;
    }

    const resp = await app.service.get(`orders/${billOrderId}/containers`);
    setContainers(resp.data);
  };

  const fetchData = async () => {
    const _id = form.getFieldValue('id') || id;
    if (!_id) {
      return;
    }

    setLoading(true);

    try {
      const resp = await app.service.get(`orderBills/${_id}`);

      setBillOrderId(resp.data.billable_id);

      setBill(resp.data);
      form.setFieldsValue(resp.data);
      if (billableType == BILL_TYPE_ORDER) {
        setContainersNumbers(
          (get(resp.data, 'charges') || []).map((c) => c.containerNumber),
        );
      }
    } catch (e: any) {
      console.log(e);
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const fetchBuyRates = async () => {
    if (
      !billOrderId ||
      billableType == BILL_TYPE_LTL ||
      billableType == BILL_TYPE_FTL
    ) {
      return;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(`orders/${billOrderId}/getBuyRates`);
      setBuyRates(resp.data);
    } catch (e: any) {
      message.error(
        get(e, 'data.message') || get(e, 'data.error') || 'System Error',
      );
    }

    setLoading(false);
  };

  const handleSave = async () => {
    const values = await form.validateFields();

    setLoading(true);

    if (form.getFieldValue('new_files')) {
      values.new_files = form.getFieldValue('new_files');
    }

    const config = {
      data: serialize(values, { indices: true, nullsAsUndefineds: true }),
      requestType: 'form',
    };

    if (values.id) {
      config.data.set('_method', 'put');
    } else {
      config.data.set('billable_id', billOrderId);
      config.data.set('billable_type', billableType);
    }

    try {
      const resp = !values.id
        ? await app.service.post('orderBills', config)
        : await app.service.post(`orderBills/${values.id}`, config);
      message.success('Saved');

      form.setFieldsValue(resp.data);

      // onSaved && onSaved();
      handleSaved();
    } catch (e: any) {
      message.error(
        get(e, 'data.message') || get(e, 'data.error') || 'System Error',
      );
    }

    setLoading(false);
  };

  // const handleApprovedOrUnApproved = (billId: number) => {
  //   fetchData();
  //   onSaved && onSaved();
  // };

  // const handleDisputeOrCancelDispute = (billId: number) => {
  //   fetchData();
  //   onSaved && onSaved();
  // };

  const handleSaved = () => {
    fetchData();
    onSaved && onSaved();
  };

  const onChangingContainerNumbers = (numbers: any) => {
    setContainersNumbers(numbers);
  };

  React.useEffect(() => {
    billOrderId && fetchContainers();
  }, [billOrderId]);

  React.useEffect(() => {
    if (id > 0) {
      fetchData();
    } else {
      fetchContainers();
    }
  }, []);

  React.useEffect(() => {
    if (!get(container, 'buy_rate') && billOrderId) {
      fetchBuyRates();
    }
  }, [container, billOrderId]);

  const rateCon = async (id: number) => {
    try {
      const resp = await app.service.get(`orderBills/${id}/rateCon`, {
        responseType: 'blob',
      });
      const file = new Blob([resp], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const w = window.open(fileURL, '_blank');
      if (!w) {
        throw new Error('Please allow popups for this website');
      }
      w.document.title = 'B' + id;
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        new_files: initialValues.new_files,
      });
    }
  }, [initialValues, form]);

  return (
    <>
      <Drawer
        title={
          <>
            {form.getFieldValue('uid')}{' '}
            {form.getFieldValue('is_approved') ? (
              <Tag color="processing">Approved</Tag>
            ) : (
              <Tag color="default">Unapproved</Tag>
            )}
            {bill?.dispute_status ? (
              <Tag color="error">
                {
                  STATUS_MAP[
                    (bill.dispute_status as unknown) as keyof typeof STATUS_MAP
                  ]
                }
              </Tag>
            ) : (
              ''
            )}
            {/* {form.getFieldValue('dispute_at') && (
              <DisputeButton
                form={form}
                billId={form.getFieldValue('id')}
                onDisputeOrCancelDispute={handleDisputeOrCancelDispute}
                onSetLoading={setLoading}>
                {(showModal: any) => {
                  return (
                    <Tag color="error">
                      In Dispute
                      <EditOutlined onClick={showModal} />
                    </Tag>
                  );
                }}
              </DisputeButton>
            )}
            {!form.getFieldValue('dispute_at') &&
              form.getFieldValue('dispute_memo') && (
              <Tag color="error">Dispute Closed</Tag>
            )} */}
          </>
        }
        placement="right"
        width={'95%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            {form.getFieldValue('id') && (
              <>
                <ApproveButton
                  form={form}
                  billId={form.getFieldValue('id')}
                  onApprovedOrUnApproved={handleSaved}
                  onSetLoading={setLoading}
                />
                <DisputeMemo
                  onSaved={handleSaved}
                  model={bill}
                  icon={<Button type="primary">Dispute Memo</Button>}
                  type={DISPUTE_TYPE_BILL}
                />
                {billableType == BILL_TYPE_ORDER && (
                  <Button
                    type="primary"
                    onClick={() => rateCon(form.getFieldValue('id'))}
                  >
                    Rate Con
                  </Button>
                )}
              </>
            )}
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        {billableType != BILL_TYPE_ORDER && (
          <TruckLoadBillForm
            form={form}
            billableType={billableType}
            containers={containers}
            container={container}
            buyRates={buyRates}
            loading={loading}
            onChangingContainerNumbers={onChangingContainerNumbers}
            initialFiles={initialValues?.new_files}
          >
            {billableType == BILL_TYPE_LTL && (
              <LTLTable.default
                ids={[billOrderId || form.getFieldValue('billable_id')]}
              />
            )}
            {billableType == BILL_TYPE_FTL && (
              <FTLTable.default
                ids={[billOrderId || form.getFieldValue('billable_id')]}
              />
            )}
          </TruckLoadBillForm>
        )}
        {billableType == BILL_TYPE_ORDER && (
          <OrderBillForm
            form={form}
            billableId={billableId}
            billableType={BILL_TYPE_ORDER}
            containers={containers}
            containerNumbers={containerNumbers}
            container={container}
            buyRates={buyRates}
            loading={loading}
            fetchContainers={fetchContainers}
            onChangingContainerNumbers={onChangingContainerNumbers}
            initialFiles={initialValues?.new_files}
          />
        )}
      </Drawer>
    </>
  );
};
