import { Tooltip } from 'antd';

const LocationCell = ({
  location,
  contact = '',
}: {
  location: string;
  contact?: string;
}) => {
  return (
    <Tooltip
      overlayInnerStyle={{ width: '450px' }}
      title={location + '\n' + contact}
    >
      <div
        style={{
          fontSize: '12px',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
        }}
      >
        <div>{location}</div>
        <div>{contact}</div>
      </div>
    </Tooltip>
  );
};

export default LocationCell;
