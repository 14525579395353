import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  DatePicker,
  message,
  Switch,
  Space,
  Select,
  Tooltip,
  Typography,
  Spin,
  Popover,
  TableProps,
  Table,
  Row,
  Col,
  Tag,
  Modal,
  Input,
} from 'antd';

import React, { useEffect, useMemo, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { MBLLink } from '@/components/MBLLInk';
import { TCollection, TContainer } from '@/types';
import {
  InfoCircleOutlined,
  WarningFilled,
  FireOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { EditableText, EditableSelect } from '@/components/Editable';
import moment from 'moment';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import { Memo } from '../Memo';
import { LogActivities } from '@/components/LogActivities';
import AutoResizeTable from '@/components/AutoResizeTable';
import { EditableContainerExtraCosts } from '../ExtraCosts';
import TroubleStatusesPopover from '../TroubleStatuses';
import {
  APPOINTMENT_BY_TYPE_MAP,
  DISPATCH_AP_STATUS_OPTIONS,
  DISPATCH_AR_STATUS_OPTIONS,
  DISPATCH_STATUS_OPTIONS,
  DISPATCH_ACTION_V2_OPTIONS,
  OCEAN_PORT,
  RAMP_PORT,
  LIVE_OR_DROP_MAP,
  CUSTOMER_STATUS_OPTIONS,
  DISPATCH_AR_STATUS_ALL_ISSUED,
  DISPATCH_AP_STATUS_ALL_ISSUED,
} from '../data';
import { OrderIdColumn } from '@/pages/orders/components/OrderIdColumn';
import { OrderIdConfirmedAt } from '@/pages/orders/components/OrderIdConfirmedAt';
import { Upload } from '@/pages/orders/components/Upload';
import { Trucking } from '@/pages/orders/components/Trucking/index';
import { TruckerColumn } from '../TruckerColumn';
import { BuyRateColumn } from '../BuyRateColumn';
import { WHSColumn } from '../WHS/WHSColumn';
import { WarehouseDrawer } from '@/pages/entity/warehouses/components/WarehouseDrawer';
import { CNTREasyTracking } from '../CNTREasyTracking';
import { RerserveTime } from '../RerserveTime';
import { CustomerColumn } from '../CustomerColumn';
import { get, uniq } from 'lodash';
import { ReleaseStatus } from '../ReleaseStatus';
import { AccountingModal } from '@/pages/orders/components/AccountingModal';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { IntermodalRegionOceanSelect } from '@/components/IntermodalRegionOceanSelect';
import { CopyButton } from '@/components/CopyButton';
import CheckActions from '../CheckActions';
import { Chassis } from '../Chassis';
import useDispatch from './useDispatch';
import { TodoTaskList } from '../ActionTasks/TodoTaskList';
import { CopyMultipleButton, toCopy } from '@/components/CopyMultipleButton';
import { TagBadge } from '@/pages/orders/components/OrderIdColumn';
import CntrAndMblColumnRender from '@/components/CntrAndMblColumnRender';
import { FileSearchOutlined } from '@ant-design/icons';

import {
  BuyRateDrawer,
  BUYRATE_UID_PREFIX,
} from '@/pages/rates/buyRates/components/buyRateDrawer';
import { isStringOfNumbers } from '@/pages/orders/components/utils';

import { ActionTasks } from '../ActionTasks';
import {
  TASK_TYPE_FILE,
  TASK_TYPE_VENDOR,
  TASK_TYPE_WAREHOUSE,
} from '@/pages/setting/containerTasks/components/data';

import {
  StatusSlaHistory,
  TYPE_DISPATCH,
  TYPE_CUSTOMER,
  TYPE_AR,
} from './StatusSlaHistory';
import Weather from '@/components/Weather';
import { Overdue } from './Overdue';
import { FaTrainSubway } from 'react-icons/fa6';
import { observer } from 'mobx-react-lite';
import ColorTags from './ColorTags';
import { Statement } from '../Statement';
import TimeText from '../RerserveTime/TimeText';
import OverflowText from '@/components/OverflowText';
import { OnTheWayTruckIcon } from '@/components/OnTheWayTruckIcon';
import { VendorCell } from '@/components/VendorCell';
import { VendorDrawer } from '@/pages/entity/vendors/components/VendorDrawer';

const SORT_MAP = {
  ascend: 'asc',
  descend: 'desc',
};

interface IDispatchTable<T = any> extends TableProps<T> {
  tab?: number;
  data: TCollection<any> | undefined;
  accountingMode?: boolean;
  columns?: ColumnsType<any>;
  enableSorter?: boolean;
  disabledResize?: boolean;
  setData: (data: any) => void;
  fetchData?: (_pagination?: TablePaginationConfig, params?: any) => void;
  handleSort?: (pagination: any, sortBy: string, sortValue: string) => void;
  onClickPagination: (pagination: TablePaginationConfig) => void;
  setDefaultColumns?: (defaultColumns: any) => void;
  onSetPagination?: (pagination: TablePaginationConfig) => void;
  onSetSelected?: (selectedRowKeys: any[], selectedRows: any[]) => void;
}

export const DispatchTable: React.FC<IDispatchTable> = observer(
  ({
    tab,
    data,
    accountingMode = false,
    columns,
    enableSorter = true,
    disabledResize = false,
    setData,
    fetchData,
    handleSort,
    onClickPagination,
    setDefaultColumns,
    onSetPagination,
    onSetSelected,
    ...props
  }) => {
    const app = useApp();
    const TableComponent = disabledResize ? Table : AutoResizeTable;

    const { resolveOrder, ...dispatch } = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [activeRowId, setActiveRowId] = useState(0);
    const [openWarehouseForm, setOpenWarehouseForm] = React.useState<boolean>(
      false,
    );
    const [openBuyRate, setOpenBuyRate] = React.useState<boolean>(false);
    const [buyRateId, setBuyRateId] = React.useState(0);

    const [warehouseId, setWarehouseId] = React.useState<any>(null);
    const [warehouseContainer, setWarehouseContainer] = React.useState(null);

    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

    const [vendor, setVendor] = useState<any>(null);
    const [visible, setVisible] = useState(false);

    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
        onSetSelected && onSetSelected(selectedRowKeys, selectedRows);
      },
      onSelectMultiple: (
        _selected: any,
        selectedRows: any,
        _changeRows: any,
      ) => {
        const selectedRowKeys = selectedRows.map((s: any) => s.id);
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);

        onSetSelected && onSetSelected(selectedRowKeys, selectedRows);
      },
    };

    useEffect(() => {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      onSetSelected && onSetSelected([], []);
    }, [tab]);

    const pagination = React.useMemo<TablePaginationConfig>(() => {
      return {
        showTotal: (total: number) => {
          return `Total (Selected): ${total} (${selectedRowKeys.length})`;
        },
        showSizeChanger: true,
        total: data?.meta?.total,
        pageSize: data?.meta?.per_page,
        current: data?.meta?.current_page,
        position: ['bottomCenter'],
      };
    }, [data, selectedRowKeys]);

    React.useEffect(() => {
      onSetPagination && onSetPagination(pagination);
    }, [pagination]);

    const refreshContainer = React.useCallback(
      async (id: number) => {
        if (!data || !id) {
          return;
        }
        const _container = await app.service.get(`dispatch/${id}`);

        resolveOrder(_container.data);

        const collection = data.data.map((item) => {
          if (item.id === _container.data.id) {
            return _container.data;
          }
          return item;
        });
        setData({ ...data, data: collection });
      },
      [data, setData],
    );

    const refreshOrder = React.useCallback(
      async (orderId: number) => {
        const newContainers = await dispatch.fetchData(
          { pageSize: 50, current: 1 },
          {
            order_id: orderId,
            page: 1,
            per_page: 50,
          },
        );

        resolveNewContainers(newContainers);
      },
      [data, setData],
    );

    const resolveNewContainers = React.useCallback(
      (newContainers: any) => {
        const collection = data.data.map((item) => {
          const _container = newContainers.data.find(
            (c: any) => c.id == item.id,
          );
          if (_container) {
            return _container;
          } else {
            return item;
          }
        });
        setData({ ...data, data: collection });
      },
      [data, setData],
    );

    const handleSavedWarehouse = async (warehouseId: number) => {
      if (!warehouseId) {
        return;
      }

      const newContainers = await dispatch.fetchData(
        { pageSize: 50, current: 1 },
        {
          warehouse_id: warehouseId,
          page: 1,
          per_page: 50,
        },
      );

      resolveNewContainers(newContainers);
    };

    // const checkIfArStatusToBeAllIssueWithMessage = (
    //   ids: any[],
    //   fn: () => any,
    // ) => {
    //   checkIfStatusToBeAllIssueWithMessage(
    //     'checkIfArStatusToBeAllIssueWithMessage',
    //     ids,
    //     fn,
    //   );
    // };

    // const checkIfApStatusToBeAllIssueWithMessage = (
    //   ids: any[],
    //   fn: () => any,
    // ) => {
    //   checkIfStatusToBeAllIssueWithMessage(
    //     'checkIfApStatusToBeAllIssueWithMessage',
    //     ids,
    //     fn,
    //   );
    // };

    // const checkIfStatusToBeAllIssueWithMessage = async (
    //   uri: string,
    //   ids: any[],
    //   fn: () => any,
    // ) => {
    //   setLoading(true);
    //   try {
    //     const resp = await app.service.put(`containers/${uri}`, {
    //       data: {
    //         container_ids: ids,
    //       },
    //     });

    //     if (resp.data && resp.data.length > 0) {
    //       Modal.confirm({
    //         title: 'Confirm',
    //         content:
    //           resp.data.join('\n') + '\n Are you sure you want to continue?',
    //         okText: 'Confirm',
    //         cancelText: 'Cancel',
    //         onOk: fn,
    //       });
    //     } else {
    //       return fn();
    //     }
    //   } catch (err: any) {
    //     message.error(err.data?.error || err.data?.message);
    //   }

    //   setLoading(false);
    // };

    const handlePatchUpdate = React.useCallback(
      async (containerId: number, id: number, model: string, values: any) => {
        setLoading(true);
        try {
          await app.service.patch(`${model}/${id}`, { data: values });

          refreshContainer(containerId);
        } catch (err: any) {
          message.error(err?.data?.message || err?.data?.error);
        } finally {
          setLoading(false);
        }
      },
      [pagination],
    );

    const updateInternalTaskAction = React.useCallback(
      async (
        containerId: number,
        type: string,
        data: { [key: string]: any },
      ) => {
        try {
          await app.service.put(
            `containers/${containerId}/updateInternalTaskAction`,
            { data: { type, data } },
          );
        } catch (err: any) {
          message.error(err.data.error || err.data.message);
          return;
        }
        refreshContainer(containerId);
      },
      [refreshContainer],
    );

    // const updateContainerTask = React.useCallback(
    //   async (containerId: number, taskType: string,data: { [key: string]: any }) => {
    //     try {
    //       await app.service.put(`containers/${containerId}/updateTask`, {
    //         data: { ...data },
    //       });
    //     } catch (err: any) {
    //       message.error(err.data.error || err.data.message);
    //       return;
    //     }
    //     refreshContainer(containerId);
    //     updateForceFetch(taskType, true);
    //   },
    //   [refreshContainer],
    // );

    const handleCopyMultipleMBL = React.useCallback(() => {
      if (!data) {
        return false;
      }

      let mblNumbers = [];
      let msg = '';

      if (selectedRowKeys?.length <= 0) {
        mblNumbers = uniq(
          data.data.map((c: any) => get(c, 'order.mbl_number')),
        );

        msg = 'Copy All MBL';
      } else {
        const containers = data.data.filter(
          (d: any) => selectedRowKeys.includes(d.id) && d.number,
        );

        mblNumbers = uniq(
          containers.map((c: any) => get(c, 'order.mbl_number')),
        );
        msg = `Copy  Selected ${mblNumbers.length} MBL`;
      }

      toCopy(mblNumbers.join('\n'), `${msg}`, `${msg} Failed`);
    }, [selectedRowKeys, data]);

    const handleOpenBuyRate = (buyRateId: string) => {
      if (isStringOfNumbers(buyRateId)) {
        setOpenBuyRate(true);
        setBuyRateId(Number(buyRateId));
      } else {
        const _rateId = Number(buyRateId.replace(BUYRATE_UID_PREFIX, ''));
        setBuyRateId(_rateId);
      }
    };

    const goToTrackingPage = async (id: number) => {
      setLoading(true);
      try {
        const resp = await app.service.get(
          `containers/${containerId}/easyTracking`,
          {},
        );
        const signedUrl = get(resp, 'url', '');
        if (signedUrl) {
          window.open(decodeURIComponent(signedUrl), '_target');
        }
      } catch (error: any) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    const handleCopyMultipleCNTR = React.useCallback(() => {
      if (!data) {
        return false;
      }

      let numbers = [];
      let msg = '';

      if (selectedRowKeys?.length <= 0) {
        numbers = data.data.map((c: any) => get(c, 'number'));
        msg = 'Copy All CNTR';
      } else {
        const containers = data.data.filter(
          (d: any) => selectedRowKeys.includes(d.id) && d.number,
        );

        numbers = uniq(containers.map((c: any) => get(c, 'number')));
        msg = `Copy  Selected ${numbers.length} CNTR`;
      }

      toCopy(numbers.join('\n'), `${msg}`, `${msg} Failed`);
    }, [selectedRowKeys, data]);

    const handleCommentSaved = (id: number) => {
      refreshContainer(id);
    };

    const handletagApply = (values: TContainer) => {
      const containers = data?.data || [];
      const container = containers.find((c) => c.id === values.id);

      if (container) {
        container.tags = values.tags;
        setData({
          ...data,
          data: [...containers],
        });
      }
    };

    const handleEdit = (id: number) => {
      setVisible(true);
      setVendor(id);
    };

    const handleSave = () => {
      fetchData();
      setVendor(null);
    };

    const handelCloseDrawer = () => {
      setVisible(false);
      setVendor(null);
    };

    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const containerId: any = params.get('containerId') || 0;

    const defaultColumns: ColumnsType<any> = React.useMemo(() => {
      return [
        {
          title: 'Order ID',
          dataIndex: 'order_id',
          key: 'order_id',
          width: 170,
          fixed: 'left',
          sorter: enableSorter,
          render: (text, record) => {
            const order = get(record, 'order');
            const sameWeather =
              record?.port_of_discharge?.city_id ===
                record?.final_port?.city_id &&
              record.port_of_discharge_eta === record.final_port_eta;
            return (
              <>
                <Space size={1}>
                  <OrderIdColumn
                    record={order}
                    container={record}
                    refreshOrder={refreshOrder}
                    showUrgent={record.urgent}
                  />
                  <OnTheWayTruckIcon
                    container={record}
                    onUpdated={() => refreshContainer(record.id)}
                  />
                  {get(record, 'is_arrival_on_time', false) && (
                    <Typography.Text
                      type="danger"
                      style={{ position: 'relative' }}
                    >
                      <FireOutlined />
                    </Typography.Text>
                  )}
                  {get(record, 'urgent', false) && (
                    <Tooltip
                      style={{ width: 500 }}
                      title="Urgent"
                      trigger="hover"
                    >
                      <FireOutlined style={{ color: '#cf1322' }} />
                    </Tooltip>
                  )}
                  {get(record, 'del_code_alerts', false) && (
                    <Popover
                      style={{ width: 500 }}
                      content={
                        <Space direction="vertical">
                          {record.del_code_alerts.map((alert: any) => {
                            return (
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={alert.link}
                                key={`alert-key${alert.code}`}
                              >
                                {alert.code}
                              </a>
                            );
                          })}
                        </Space>
                      }
                      trigger="hover"
                    >
                      <WarningFilled style={{ color: '#f4bb42' }} />
                    </Popover>
                  )}
                  <Overdue container={record} />
                  {record.warehouse?.is_residential && (
                    <Tooltip title={'Residential'}>
                      <span className="text-primary cursor-pointer">
                        <HomeOutlined />
                      </span>
                    </Tooltip>
                  )}
                  {sameWeather ? (
                    <Weather
                      city_id={record?.port_of_discharge?.city_id}
                      time={record.port_of_discharge_eta}
                      text={record?.port_of_discharge?.name}
                    />
                  ) : (
                    <>
                      <Weather
                        city_id={record?.port_of_discharge?.city_id}
                        time={record.port_of_discharge_eta}
                        text={record?.port_of_discharge?.name}
                      />
                      <Weather
                        city_id={record?.final_port?.city_id}
                        time={record.final_port_eta}
                        text={record?.final_port?.name}
                      />
                    </>
                  )}
                  {get(record, 'warehouse.is_special', false) && (
                    <Tooltip title={'Special Warehouse'}>
                      <Typography.Text
                        type="danger"
                        style={{ position: 'relative' }}
                      >
                        S
                      </Typography.Text>
                    </Tooltip>
                  )}
                  <ColorTags
                    tags={record.tags || []}
                    containerId={record.id}
                    onApply={(values) => handletagApply(values)}
                  />
                </Space>
                <OrderIdConfirmedAt order={record.order} />
              </>
            );
          },
        },
        {
          title: () => (
            <>
              <Space>
                CNTR#
                <CopyMultipleButton
                  onClick={handleCopyMultipleCNTR}
                ></CopyMultipleButton>
              </Space>{' '}
              <Space>
                MBL
                <CopyMultipleButton
                  onClick={handleCopyMultipleMBL}
                ></CopyMultipleButton>
              </Space>
            </>
          ),
          dataIndex: 'number',
          key: 'number',
          width: 260,
          fixed: 'left',
          render: (text, record) => (
            <CntrAndMblColumnRender
              container={record}
              order={record.order}
              onSaved={() => refreshContainer(record.id)}
            />
            // <>
            //   <CNTREasyTracking
            //     container={record}
            //     onSaved={() => refreshContainer(record.id)}
            //   />
            //   <br />
            //   <Space>
            //     <MBLLink mblNumber={record.order?.mbl_number || ''} />
            //     {get(record, 'order.mbl_number', false) && (
            //       <CopyButton value={get(record, 'order.mbl_number')} />
            //     )}
            //     {(record.number && record.order.trackeasy_shipment_id
            //       ? true
            //       : false) && (
            //       <span style={{ color: '#1890ff' }}>
            //         <FileSearchOutlined
            //           onClick={() => goToTrackingPage(containerId)}
            //         />
            //       </span>
            //     )}

            //     {record.type && <TagBadge text={record.type} />}
            //   </Space>
            // </>
          ),
        },
        {
          title: 'Dispatch Status',
          dataIndex: 'dispatch_status',
          key: 'dispatch_status',
          width: 240,
          fixed: 'left',
          align: 'center',
          render: (text, record) => (
            <Row>
              <Col span={20}>
                <EditableSelect
                  name="dispatch_status"
                  value={record.dispatch_status || 0}
                  options={
                    record.dispatch_action_v2
                      ? DISPATCH_ACTION_V2_OPTIONS
                      : DISPATCH_STATUS_OPTIONS
                  }
                  onChange={async (name: string, value: any) => {
                    await handlePatchUpdate(
                      record.id,
                      record.id,
                      'containers',
                      {
                        [name]: value,
                      },
                    );
                    handleSavedWarehouse(record.warehouse_id);
                  }}
                />
              </Col>
              <Col span={3}>
                <StatusSlaHistory container={record} type={TYPE_DISPATCH} />
              </Col>
            </Row>
          ),
        },
        ...(accountingMode
          ? [
              {
                title: 'AR Status',
                dataIndex: 'ar_status',
                key: 'ar_status',
                width: 170,
                fixed: 'left',
                render: (text: string, record: any) => (
                  <Row>
                    <Col span={20}>
                      <EditableSelect
                        name="ar_status"
                        value={record.ar_status}
                        options={DISPATCH_AR_STATUS_OPTIONS}
                        onChange={(name: string, value: any) => {
                          const callback = () =>
                            handlePatchUpdate(
                              record.id,
                              record.id,
                              'containers',
                              {
                                [name]: value,
                              },
                            );
                          if (value === DISPATCH_AR_STATUS_ALL_ISSUED) {
                            dispatch.checkIfArStatusToBeAllIssueWithMessage(
                              [record.id],
                              callback,
                            );
                          } else {
                            callback();
                          }
                        }}
                      />
                    </Col>
                    <Col span={3} className="ml-xs">
                      <StatusSlaHistory container={record} type={TYPE_AR} />
                    </Col>
                  </Row>
                ),
              },
              {
                title: 'AP Status',
                dataIndex: 'ap_status',
                key: 'ap_status',
                width: 150,
                fixed: 'left',
                render: (text: string, record: any) => (
                  <EditableSelect
                    name="ap_status"
                    value={record.ap_status || 0}
                    options={DISPATCH_AP_STATUS_OPTIONS}
                    onChange={(name: string, value: any) => {
                      const callback = () =>
                        handlePatchUpdate(record.id, record.id, 'containers', {
                          [name]: value,
                        });
                      if (value === DISPATCH_AP_STATUS_ALL_ISSUED) {
                        dispatch.checkIfApStatusToBeAllIssueWithMessage(
                          [record.id],
                          callback,
                        );
                      } else {
                        callback();
                      }
                    }}
                  />
                ),
              },
              {
                title: 'Profit',
                dataIndex: 'profit_amount',
                key: 'profit_amount',
                width: 130,
                sorter: enableSorter,
                align: 'center',
                render: (text: string, record: any) => {
                  if (record.profit_amount < 0) {
                    return (
                      <Typography.Text type="danger">
                        {record.profit_amount}
                      </Typography.Text>
                    );
                  }

                  return record.profit_amount ?? '-';
                },
              },
              {
                title: 'Statement#',
                dataIndex: 'localstatement',
                key: 'localstatement',
                width: 130,
                render: (text: string, record: any) => (
                  <Statement container={record} />
                ),
              },
            ]
          : []),
        {
          title: 'Storage',
          dataIndex: 'is_storage',
          key: 'is_storage',
          width: 80,
          render: (text, record) => (
            <Switch
              checked={!!record.is_storage}
              onChange={(checked) =>
                handlePatchUpdate(record.id, record.id, 'containers', {
                  is_storage: checked,
                })
              }
            />
          ),
        },
        {
          title: 'Verified',
          dataIndex: 'is_verified',
          key: 'is_verified',
          width: 80,
          render: (text, record) => (
            <Switch
              checked={!!record.verified_at}
              onChange={(checked) =>
                handlePatchUpdate(record.id, record.id, 'containers', {
                  verified_at: checked ? new Date() : null,
                })
              }
            />
          ),
        },
        {
          title: 'Dispatch Action V2',
          dataIndex: 'dispatch_action_v2',
          key: 'dispatch_action_v2',
          width: 150,
          render: (text, record) => (
            <Switch
              checked={!!record.dispatch_action_v2}
              onChange={(checked) =>
                handlePatchUpdate(record.id, record.id, 'containers', {
                  dispatch_action_v2: checked,
                })
              }
            />
          ),
        },
        {
          title: 'Customer Status',
          dataIndex: 'customer_status',
          key: 'customer_status',
          align: 'center',
          width: 230,
          render: (text, record) => (
            <Row>
              <Col span={21}>
                <EditableSelect
                  name="customer_status"
                  value={record.customer_status || 0}
                  options={CUSTOMER_STATUS_OPTIONS}
                  onChange={async (name: string, value: any) => {
                    await handlePatchUpdate(
                      record.id,
                      record.id,
                      'containers',
                      {
                        [name]: value,
                      },
                    );
                  }}
                />
              </Col>

              <Col span={3}>
                <StatusSlaHistory container={record} type={TYPE_CUSTOMER} />
              </Col>
            </Row>
          ),
        },

        {
          title: 'Release Status',
          dataIndex: 'release_status',
          key: 'release_status',
          width: 120,
          render: (text, record) => (
            <ReleaseStatus
              container={record}
              refreshContainer={refreshContainer}
            />
          ),
        },
        // {
        //   align: 'center',
        //   title: 'Exam',
        //   dataIndex: 'is_exam',
        //   key: 'is_exam',
        //   width: 80,
        //   render: (text, record) => {
        //     return (
        //       <>
        //         <Switch
        //           onChange={(v) =>
        //             handlePatchUpdate(record.id, 'containers', { is_exam: v })
        //           }
        //           checked={record.is_exam}
        //         />
        //       </>
        //     );
        //   },
        // },
        {
          align: 'center',
          title: 'Urgent',
          dataIndex: 'urgent',
          key: 'urgent',
          width: 80,
          render: (text, record) => {
            return (
              <>
                <Switch
                  onChange={(v) =>
                    handlePatchUpdate(record.id, record.id, 'containers', {
                      urgent: v,
                    })
                  }
                  checked={record.urgent}
                />
              </>
            );
          },
        },
        {
          title: 'OP Lead',
          dataIndex: 'operator_lead_id',
          key: 'operator_lead_id',
          width: 150,
          render: (text, record) => (
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={record.order?.operator_lead_id || ''}
              allowClear
              onSelect={(user: any) =>
                handlePatchUpdate(record.id, record.order.id, 'orders', {
                  operator_lead_id: user?.id || 0,
                })
              }
            />
          ),
        },
        {
          title: 'OP',
          dataIndex: 'operator_id',
          key: 'operator_id',
          width: 150,
          render: (text, record) => (
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={record.order?.operator_id || ''}
              allowClear
              onSelect={(user: any) =>
                handlePatchUpdate(record.id, record.order.id, 'orders', {
                  operator_id: user?.id || 0,
                })
              }
            />
          ),
        },
        {
          title: 'OP Assiant',
          dataIndex: 'operator_assistant_id',
          key: 'operator_assistant_id',
          width: 150,
          render: (text, record) => (
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={record.order?.operator_assistant_id || ''}
              allowClear
              onSelect={(user: any) =>
                handlePatchUpdate(record.id, record.order.id, 'orders', {
                  operator_assistant_id: user?.id || 0,
                })
              }
            />
          ),
        },
        {
          title: 'BD',
          dataIndex: 'business_development_id',
          key: 'business_development_id',
          width: 150,
          render: (text, record) => (
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={record.order?.business_development_id || ''}
              allowClear
              onSelect={(user: any) =>
                handlePatchUpdate(record.id, record.order.id, 'orders', {
                  business_development_id: user?.id || 0,
                })
              }
            />
          ),
        },
        {
          title: 'Sales',
          dataIndex: 'sales_id',
          key: 'sales_id',
          width: 150,
          render: (text, record) => (
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={record.order?.sales_id || ''}
              allowClear
              onSelect={(user: any) =>
                handlePatchUpdate(record.id, record.order.id, 'orders', {
                  sales_id: user?.id || 0,
                })
              }
            />
          ),
        },
        {
          title: 'Sales Support',
          dataIndex: 'sales_support_id',
          key: 'sales_support_id',
          width: 150,
          render: (text, record) => (
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={record.order?.sales_support_id || ''}
              allowClear
              onSelect={(user: any) =>
                handlePatchUpdate(record.id, record.order.id, 'orders', {
                  sales_support_id: user?.id || 0,
                })
              }
            />
          ),
        },
        {
          title: 'Customer',
          dataIndex: 'user_id',
          key: 'user_id',
          width: 200,
          render: (text, record) => (
            <CustomerColumn
              container={record}
              refreshOrder={() => fetchData && fetchData()}
            />
          ),
        },
        {
          title: 'Weight',
          dataIndex: 'weight',
          key: 'weight',
          width: 180,
          render: (text) => {
            return (
              <Input
                disabled
                name="weight"
                value={text ? (+text * 2.20462).toFixed(2) : 0}
                type={'number'}
                addonAfter="LBS"
              />
            );
          },
        },
        {
          title: 'Commodity',
          dataIndex: 'commodity',
          key: 'commodity',
          width: 150,
          render: (text, record) => {
            const commodity = (record.commodity || []).join(', ');
            return <OverflowText>{commodity}</OverflowText>;
          },
        },

        {
          title: 'REF#',
          dataIndex: ['order', 'customer_reference_number'],
          key: 'customer_reference_number',
          width: 150,
          render: (_, record) => (
            <OverflowText>
              {record.order.customer_reference_number}
            </OverflowText>
          ),
          // render: (text, record) => {
          //   return (
          //     <EditableText
          //       name="customer_reference_number"
          //       value={record.order.customer_reference_number || ''}
          //       onChange={(name: string, value: any) =>
          //         handlePatchUpdate(record.id, record.order.id, 'orders', {
          //           [name]: value,
          //         })
          //       }
          //     />
          //   );
          // },
        },
        // {
        //   align: 'center',
        //   title: 'Pierpass',
        //   dataIndex: 'pierpass',
        //   key: 'pierpass',
        //   sorter: true,
        //   width: 80,
        //   render: (text, record) => {
        //     return (
        //       <Switch
        //         checked={record.pierpass}
        //         onClick={(v) =>
        //           handlePatchUpdate(record.id, 'containers', { pierpass: v })
        //         }
        //       />
        //     );
        //   },
        // },
        {
          title: 'Destination Type',
          dataIndex: 'destination_type',
          key: 'destination_type',
          width: 150,
          render: (text, record) => {
            return (
              <Select
                style={{
                  width: '100%',
                }}
                value={record.destination_type}
                onChange={(v) =>
                  handlePatchUpdate(record.id, record.id, 'containers', {
                    destination_type: v,
                  })
                }
              >
                <Select.Option value={OCEAN_PORT}>Ocean Port</Select.Option>
                <Select.Option value={RAMP_PORT}>Ramp Port</Select.Option>
              </Select>
            );
          },
        },
        {
          title: 'POD',
          dataIndex: 'port_of_discharge',
          key: 'port_of_discharge',
          width: 200,
          render: (text, record) => {
            const destinationType = record?.destination_type || '';
            return (
              <IntermodalRegionOceanSelect
                className="w100"
                value={record.port_of_discharge_id}
                onChange={(id) =>
                  handlePatchUpdate(record.id, record.id, 'containers', {
                    port_of_discharge_id: id || 0,
                    final_port_id:
                      destinationType === OCEAN_PORT
                        ? id
                        : record.final_port_id,
                  })
                }
              />
            );
          },
        },
        {
          title: () => (
            <Tooltip
              placement="topLeft"
              title={
                <>When Destination Type is Ocean Port, IR cannot be changed.</>
              }
              arrowPointAtCenter
            >
              IR <QuestionCircleOutlined />
            </Tooltip>
          ),
          label: 'IR',
          dataIndex: 'final_port',
          key: 'final_port',
          width: 200,
          render: (text, record) => {
            const destinationType = record?.destination_type || '';
            return (
              <IntermodalRegionSelect
                className="w100"
                disabled={destinationType === OCEAN_PORT}
                value={record.final_port_id}
                onChange={(id: any) =>
                  handlePatchUpdate(record.id, record.id, 'containers', {
                    final_port_id: id || 0,
                  })
                }
              />
            );
          },
        },
        {
          title: 'Terminal',
          dataIndex: 'terminal',
          key: 'terminal',
          width: 350,
          render: (text, record) => {
            return (
              <TerminalSelect
                selected={record.terminal}
                redirectable={true}
                onSelect={(terminal: any) =>
                  handlePatchUpdate(record.id, record.id, 'containers', {
                    terminal,
                    terminal_id: terminal?.id || 0,
                  })
                }
              />
            );
          },
        },
        {
          title: 'POL ETD',
          dataIndex: 'port_of_loading_etd',
          key: 'port_of_loading_etd',
          width: 150,
          sorter: enableSorter,
          render: (text, record) => {
            return (
              <DatePicker
                style={{ width: '100%' }}
                value={
                  record.port_of_loading_etd
                    ? moment(record.port_of_loading_etd)
                    : null
                }
                onChange={(date, dateString) =>
                  handlePatchUpdate(record.id, record.id, 'containers', {
                    port_of_loading_etd: dateString,
                  })
                }
              />
            );
          },
        },
        {
          title: 'POD ETA',
          dataIndex: 'port_of_discharge_eta',
          key: 'port_of_discharge_eta',
          width: 150,
          sorter: enableSorter,
          render: (text, record) => {
            const destinationType = record?.destination_type || '';
            return (
              <DatePicker
                style={{ width: '100%' }}
                value={
                  record.port_of_discharge_eta
                    ? moment(record.port_of_discharge_eta)
                    : null
                }
                onChange={(date, dateString) =>
                  handlePatchUpdate(record.id, record.id, 'containers', {
                    port_of_discharge_eta: dateString,
                    final_port_eta:
                      destinationType === OCEAN_PORT
                        ? dateString
                        : record.final_port_eta,
                  })
                }
              />
            );
          },
        },
        {
          title: 'IR ETA',
          dataIndex: 'final_port_eta',
          key: 'final_port_eta',
          width: 180,
          sorter: enableSorter,
          render: (text, record) => {
            const destinationType = record?.destination_type || '';
            return (
              <Space>
                <DatePicker
                  disabled={destinationType === OCEAN_PORT}
                  style={{ width: '100%' }}
                  value={
                    record.final_port_eta ? moment(record.final_port_eta) : null
                  }
                  onChange={(date, dateString) =>
                    handlePatchUpdate(record.id, record.id, 'containers', {
                      final_port_eta: dateString,
                    })
                  }
                />
                {record?.rail_departure_date && (
                  <Tooltip title="Depatured">
                    <FaTrainSubway
                      style={{
                        marginBottom: '-2px',
                      }}
                      color="#1890ff"
                    />
                  </Tooltip>
                )}
              </Space>
            );
          },
        },
        {
          title: 'LFD',
          dataIndex: 'lfd',
          key: 'lfd',
          width: 150,
          sorter: enableSorter,
          // render: (text, record) => {
          //   return (
          //     <DatePicker
          //       disabled
          //       style={{ width: '100%' }}
          //       value={record.lfd ? moment(record.lfd) : null}
          //       onChange={(date, dateString) =>
          //         handlePatchUpdate(record.id, record.id, 'containers', {
          //           lfd: dateString,
          //         })
          //       }
          //     />
          //   );
          // },
        },
        {
          title: 'WHS',
          dataIndex: 'warehouse_id',
          key: 'warehouse_id',
          width: 350,
          sorter: enableSorter,
          render: (text, record) => {
            record?.final_port?.city_id &&
              app.store.bufferCache.debounceFetchWeather('weather', {
                city_id: record.final_port.city_id,
                time: record.final_port_eta,
              });

            record?.port_of_discharge?.city_id &&
              app.store.bufferCache.debounceFetchWeather('weather', {
                city_id: record.port_of_discharge.city_id,
                time: record.port_of_discharge_eta,
              });

            return (
              <WHSColumn
                container={record}
                onSaved={() => handleSavedWarehouse(record.warehouse_id)}
              />
            );
          },
        },
        // {
        //   title: 'BR',
        //   dataIndex: 'br',
        //   key: 'br',
        //   align: 'center',
        //   width: 100,
        //   render: (text, record) => {
        //     return (
        //       <Button
        //         type="link"
        //         onClick={() => handleOpenBuyRate(record.buy_rate?.id)}>
        //         {record.buy_rate?.uid}
        //       </Button>
        //     );
        //   },
        // },
        {
          title: 'Who To SKD',
          dataIndex: 'appointment_by',
          key: 'appointment_by',
          align: 'center',
          width: 150,
          render: (text, record) => {
            return record.warehouse
              ? APPOINTMENT_BY_TYPE_MAP[
                  (record.warehouse
                    .appointment_by as unknown) as keyof typeof APPOINTMENT_BY_TYPE_MAP
                ]
              : '-';
          },
        },
        {
          title: 'Live or Drop',
          dataIndex: 'live_or_drop',
          key: 'live_or_drop',
          width: 150,
          render: (text, record) => {
            return (
              <Select
                style={{
                  width: '100%',
                }}
                value={record.live_or_drop}
                onChange={(v) =>
                  handlePatchUpdate(record.id, record.id, 'containers', {
                    live_or_drop: v,
                  })
                }
              >
                {Object.keys(LIVE_OR_DROP_MAP).map((key: string) => {
                  return (
                    <Select.Option key={key} value={Number(key)}>
                      {
                        LIVE_OR_DROP_MAP[
                          (key as unknown) as keyof typeof LIVE_OR_DROP_MAP
                        ]
                      }
                    </Select.Option>
                  );
                })}
              </Select>
            );
          },
        },
        {
          title: 'Ready',
          dataIndex: 'ready_to_pickup_at',
          key: 'ready_to_pickup_at',
          width: 160,
          sorter: enableSorter,
          // render: (text, record) => {
          //   return (
          //     <Space>
          //       <DatePicker
          //         style={{ width: '100%' }}
          //         value={
          //           record.ready_to_pickup_at
          //             ? moment(record.ready_to_pickup_at)
          //             : null
          //         }
          //         onChange={(date, dateString) =>
          //           handlePatchUpdate(record.id, record.id, 'containers', {
          //             ready_to_pickup_at: dateString,
          //           })
          //         }
          //       />
          //     </Space>
          //   );
          // },
        },
        {
          title: 'Apt',
          dataIndex: 'schedule_terminal_pickup_at',
          key: 'schedule_terminal_pickup_at',
          width: 160,
          sorter: enableSorter,
          render: (text, record) => {
            return (
              <div>
                <div>{text}</div>
                <div>
                  <TimeText
                    time={record.schedule_terminal_pickup_at_reserve_time?.time}
                  />
                </div>
              </div>
            );
          },
          // render: (text, record) => {
          //   return (
          //     <Space>
          //       <DatePicker
          //         style={{ width: '100%' }}
          //         value={
          //           record.schedule_terminal_pickup_at
          //             ? moment(record.schedule_terminal_pickup_at)
          //             : null
          //         }
          //         onChange={(date, dateString) =>
          //           handlePatchUpdate(record.id, record.id, 'containers', {
          //             schedule_terminal_pickup_at: dateString,
          //           })
          //         }
          //       />
          //       <RerserveTime
          //         name="schedule_terminal_pickup_at_reserve_time"
          //         is_append_to_default={false}
          //         record={record}
          //         refreshContainer={refreshContainer}
          //       />
          //     </Space>
          //   );
          // },
        },
        {
          title: 'SKD',
          dataIndex: 'schedule_delivery_at',
          key: 'schedule_delivery_at',
          width: 160,
          sorter: enableSorter,
          render: (text, record) => {
            return (
              <div>
                <div>{text}</div>
                <div>
                  <TimeText
                    time={record.schedule_delivery_at_reserve_time?.time}
                  />
                </div>
              </div>
            );
          },
          // render: (text, record) => {
          //   return (
          //     <Space>
          //       <DatePicker
          //         style={{ width: '100%' }}
          //         value={
          //           record.schedule_delivery_at
          //             ? moment(record.schedule_delivery_at)
          //             : null
          //         }
          //         onChange={(date, dateString) =>
          //           handlePatchUpdate(record.id, record.id, 'containers', {
          //             schedule_delivery_at: dateString,
          //           })
          //         }
          //       />
          //       <RerserveTime
          //         name="schedule_delivery_at_reserve_time"
          //         is_append_to_default={false}
          //         record={record}
          //         refreshContainer={refreshContainer}
          //       />
          //     </Space>
          //   );
          // },
        },
        {
          title: 'Out Gate',
          dataIndex: 'actual_terminal_pickuped_at',
          key: 'actual_terminal_pickuped_at',
          width: 150,
          sorter: enableSorter,
          // render: (text, record) => {
          //   return (
          //     <>
          //       <DatePicker
          //         style={{ width: '100%' }}
          //         value={
          //           record.actual_terminal_pickuped_at
          //             ? moment(record.actual_terminal_pickuped_at)
          //             : null
          //         }
          //         onChange={(date, dateString) =>
          //           handlePatchUpdate(record.id, record.id, 'containers', {
          //             actual_terminal_pickuped_at: dateString,
          //           })
          //         }
          //       />
          //     </>
          //   );
          // },
        },
        {
          title: 'Outgate Days',
          dataIndex: 'outgate_days',
          key: 'outgate_days',
          width: 150,
          sorter: enableSorter,
        },
        {
          title: 'DEL',
          dataIndex: 'actual_delivered_at',
          key: 'actual_delivered_at',
          width: 150,
          sorter: enableSorter,
          // render: (text, record) => {
          //   return (
          //     <>
          //       <DatePicker
          //         style={{ width: '100%' }}
          //         value={
          //           record.actual_delivered_at
          //             ? moment(record.actual_delivered_at)
          //             : null
          //         }
          //         onChange={(date, dateString) =>
          //           handlePatchUpdate(record.id, record.id, 'containers', {
          //             actual_delivered_at: dateString,
          //           })
          //         }
          //       />
          //     </>
          //   );
          // },
        },
        {
          title: 'Empty',
          dataIndex: 'actual_empty_at',
          key: 'actual_empty_at',
          width: 150,
          sorter: enableSorter,
          // render: (text, record) => {
          //   return (
          //     <>
          //       <DatePicker
          //         style={{ width: '100%' }}
          //         value={
          //           record.actual_empty_at
          //             ? moment(record.actual_empty_at)
          //             : null
          //         }
          //         onChange={(date, dateString) =>
          //           handlePatchUpdate(record.id, record.id, 'containers', {
          //             actual_empty_at: dateString,
          //           })
          //         }
          //       />
          //     </>
          //   );
          // },
        },

        {
          title: 'Empty Days',
          dataIndex: 'empty_days',
          key: 'empty_days',
          width: 150,
          sorter: enableSorter,
        },

        {
          title: 'In Gate LFD',
          dataIndex: 'empty_return_lfd',
          key: 'empty_return_lfd',
          width: 150,
          sorter: enableSorter,
          // render: (text, record) => {
          //   return (
          //     <DatePicker
          //       style={{ width: '100%' }}
          //       value={
          //         record.empty_return_lfd
          //           ? moment(record.empty_return_lfd)
          //           : null
          //       }
          //       onChange={(date, dateString) =>
          //         handlePatchUpdate(record.id, record.id, 'containers', {
          //           empty_return_lfd: dateString,
          //         })
          //       }
          //     />
          //   );
          // },
        },
        {
          title: 'In Gate',
          dataIndex: 'actual_empty_returned_at',
          key: 'actual_empty_returned_at',
          width: 150,
          sorter: enableSorter,
          // render: (text, record) => {
          //   return (
          //     <>
          //       <DatePicker
          //         style={{ width: '100%' }}
          //         value={
          //           record.actual_empty_returned_at
          //             ? moment(record.actual_empty_returned_at)
          //             : null
          //         }
          //         onChange={(date, dateString) =>
          //           handlePatchUpdate(record.id, record.id, 'containers', {
          //             actual_empty_returned_at: dateString,
          //           })
          //         }
          //       />
          //     </>
          //   );
          // },
        },
        {
          align: 'center',
          title: () => (
            <Tooltip
              placement="topLeft"
              title={<>Business Day/Calendar Day</>}
              arrowPointAtCenter
            >
              Chassis <QuestionCircleOutlined />
            </Tooltip>
          ),
          label: 'Chassis',
          dataIndex: 'chassis_usage',
          key: 'chassis_usage',
          width: 100,
          render: (text, record: any) => (
            <Chassis container={record} refreshContainer={refreshContainer} />
          ),
        },
        {
          title: 'Buy Rate',
          dataIndex: 'buy_rate_id',
          key: 'buy_rate_id',
          width: 300,
          render: (text, record: any) => (
            <BuyRateColumn
              container={record}
              refreshContainer={refreshContainer}
            />
          ),
        },
        {
          title: 'Trucker',
          dataIndex: 'trucker',
          key: 'trucker',
          width: 300,
          render: (text, record: any) => {
            if (get(record, 'confirmed_delivery_order', null)) {
              return (
                <VendorCell
                  vendor={record.vendor}
                  container={record}
                  onSaved={() => refreshOrder(record.order_id)}
                  showVendorDetail={() => handleEdit(record.vendor_id)}
                  score
                />
              );
            }
            return <></>;
          },
        },
        {
          title: 'Trouble Status',
          dataIndex: 'trouble_status',
          key: 'trouble_status',
          align: 'center',
          width: 150,
          render: (text, record) => {
            return (
              <TroubleStatusesPopover
                container={record}
                onUpdated={(id, troubleStatus) => {
                  refreshContainer(id);
                }}
              />
            );
          },
        },
        {
          title: 'Extra Costs',
          dataIndex: 'extra_cost',
          key: 'extra_cost',
          align: 'center',
          width: 100,
          render: (text, record) => {
            return (
              <EditableContainerExtraCosts
                container={record}
                onUpdated={(id, extraCosts) => refreshContainer(record.id)}
              />
            );
          },
        },
        // {
        //   title: 'Confirm At',
        //   dataIndex: 'confirmed_at',
        //   key: 'confirmed_at',
        //   align: 'center',
        //   width: 100,
        //   render: (text, record) => {
        //     return <OrderIdConfirmedAt order={record.order} />;
        //   },
        // },
        {
          title: 'Actions',
          align: 'center',
          dataIndex: 'btns',
          key: 'btns',
          width: 280,
          fixed: 'right',
          render: (text, record) => {
            app.store.bufferCache.debounceFetch('unreadByContainerId', {
              id: record.id,
            });
            return (
              <Space>
                {get(record, 'dispatch_action_v2', false) ? (
                  <>
                    <Upload
                      container={record}
                      showActionTasks
                      TActionTasksProps={{
                        container: record,
                        taskType: TASK_TYPE_FILE,
                        onDone: () => {
                          refreshContainer(record.id);
                        },
                        color: get(record, 'task_icon_colors.file', 'gray'),
                      }}
                      order={get(record, 'order')}
                      containerId={record.id}
                      onClosedWithUpdate={() => refreshContainer(record.id)}
                    />
                    <Trucking
                      showActionTasks
                      TActionTasksProps={{
                        taskType: TASK_TYPE_VENDOR,
                        container: record,
                        onDone: () => {
                          refreshOrder(record.order_id);
                        },
                        // onCheck: (data) => {
                        //   updateContainerTask(record.id,TASK_TYPE_VENDOR, data);
                        // },
                        color: get(record, 'task_icon_colors.vendor', 'gray'),
                        // list: get(record, 'actions.vendor.tasks', []),
                      }}
                      order={get(record, 'order')}
                      showContainers={false}
                      selectedContainers={[record]}
                      refreshContainer={refreshContainer}
                      refreshOrder={refreshOrder}
                      container={record}
                    />
                    <ActionTasks
                      container={record}
                      taskType={TASK_TYPE_WAREHOUSE}
                      onDone={() => {
                        refreshOrder(record.order_id);
                      }}
                      // onCheck={(data) => updateContainerTask(record.id,TASK_TYPE_WAREHOUSE, data)}
                      color={get(record, 'task_icon_colors.warehouse', 'gray')}
                    >
                      <span onClick={() => handleOpenWarehouseForm(record)}>
                        <HomeOutlined />
                      </span>
                    </ActionTasks>
                    <TodoTaskList
                      container={record}
                      // onCheck={(data) => updateContainerTask(record.id, TASK_TYPE_TODOLIST,data)}
                      color={get(record, 'task_icon_colors.todoList', 'gray')}
                      onDone={() => {
                        refreshOrder(record.order_id);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Upload
                      container={record}
                      showFileActionTips
                      fileActionTipsProps={{
                        container: record,
                        type: 'file',
                        onDone: () => {
                          refreshContainer(record.id);
                        },
                        onCheck: (data) =>
                          updateInternalTaskAction(record.id, 'file', data),
                        color: get(
                          record,
                          'internal_task_tips.file_task_icon_color',
                          'gray',
                        ),
                        list: get(record, 'internal_task_tips.file_tasks', []),
                      }}
                      order={get(record, 'order')}
                      containerId={record.id}
                      onClosedWithUpdate={() => refreshContainer(record.id)}
                    />
                    <Trucking
                      showCheckActionTips
                      checkActionTipsProps={{
                        container: record,
                        onCheck: (data) => {
                          updateInternalTaskAction(record.id, 'vendor', data);
                        },
                        color: get(
                          record,
                          'internal_task_tips.vendor_task_icon_color',
                          'gray',
                        ),
                        list: get(
                          record,
                          'internal_task_tips.vendor_tasks',
                          [],
                        ),
                      }}
                      order={get(record, 'order')}
                      showContainers={false}
                      selectedContainers={[record]}
                      refreshContainer={refreshContainer}
                      refreshOrder={refreshOrder}
                      container={record}
                    />
                    <CheckActions
                      container={record}
                      onCheck={(data) =>
                        updateInternalTaskAction(record.id, 'warehouse', data)
                      }
                      color={get(
                        record,
                        'internal_task_tips.warehouse_task_icon_color',
                        'gray',
                      )}
                      list={get(
                        record,
                        'internal_task_tips.warehouse_tasks',
                        [],
                      )}
                    >
                      <span onClick={() => handleOpenWarehouseForm(record)}>
                        <HomeOutlined />
                      </span>
                    </CheckActions>
                  </>
                )}

                <AccountingModal
                  showAccountingTips
                  accountingActionTipsProps={{
                    color: get(
                      record,
                      'internal_task_tips.accounting_task_icon_color',
                      'gray',
                    ),
                    list: get(
                      record,
                      'internal_task_tips.accounting_tasks',
                      [],
                    ),
                  }}
                  orderId={record.order_id}
                  containerNumber={record.number}
                  onChanged={() => refreshContainer(record.id)}
                />
                <Button type="link" size="small">
                  <Memo
                    initialValue={record.internal_memo}
                    onSaved={handleCommentSaved}
                    container={record}
                    open={containerId == record.id}
                  />
                </Button>
                <Button type="link" size="small">
                  <LogActivities
                    id={record.id}
                    icon={
                      <div className="text-gray">
                        <InfoCircleOutlined />
                      </div>
                    }
                    type="containers"
                  />
                </Button>
              </Space>
            );
          },
        },
      ];
    }, [
      data,
      accountingMode,
      selectedRowKeys,
      handlePatchUpdate,
      refreshContainer,
      updateInternalTaskAction,
      // updateContainerTask,
      handleCopyMultipleMBL,
      handleCopyMultipleCNTR,
    ]);

    const handleOpenWarehouseForm = (container: any) => {
      if (!container.warehouse_id) {
        return;
      }
      setOpenWarehouseForm(true);
      setWarehouseId(container.warehouse_id);
      setWarehouseContainer(container);
    };

    const handleWarehouseFormSaved = (data: any) => {
      setOpenWarehouseForm(false);
      setWarehouseId(null);
      setWarehouseContainer(null);
      fetchData();
    };

    const handleWarehouseFormClosed = () => {
      setOpenWarehouseForm(false);
      setWarehouseId(null);
      setWarehouseContainer(null);
    };

    React.useEffect(() => {
      setDefaultColumns && setDefaultColumns(defaultColumns);
    }, [setDefaultColumns, data, defaultColumns]);

    const handleCloseBuyRate = () => {
      setOpenBuyRate(false);
      setBuyRateId(0);
    };

    const handleSaveBuyRate = () => {
      handleCloseBuyRate();
    };

    return (
      <>
        <div className={styles.main}>
          <Spin spinning={loading}>
            <div>
              <TableComponent
                pagination={pagination}
                scroll={{
                  x: 1500,
                }}
                {...props}
                // loading={loading}
                bordered
                size="small"
                rowKey="id"
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                rowClassName={(record: any) => [
                  record.id == activeRowId && 'rowActive',
                ]}
                onRow={(record: any, rowIndex: any) => {
                  return {
                    onClick: (event) => {
                      setActiveRowId(record?.id || 0);
                    },
                  };
                }}
                columns={columns || defaultColumns}
                dataSource={data?.data}
                onChange={(_pagination, _2, sorter: any) => {
                  if (
                    _pagination.current !== pagination.current ||
                    _pagination.pageSize !== pagination.pageSize
                  ) {
                    onClickPagination(_pagination);
                  } else {
                    const sort_value =
                      SORT_MAP[sorter.order as keyof typeof SORT_MAP] || '';
                    handleSort &&
                      handleSort(_pagination, sorter.field, sort_value);
                  }
                }}
                sticky
              />
            </div>
          </Spin>
        </div>
        {openWarehouseForm && warehouseId && warehouseContainer && (
          <WarehouseDrawer
            id={warehouseId}
            container={warehouseContainer}
            forbidToModfiySpecifyField={true}
            onSaved={handleWarehouseFormSaved}
            onClosed={handleWarehouseFormClosed}
            onSent={() => handleSavedWarehouse(warehouseId)}
            visible={openWarehouseForm}
          />
        )}

        {openBuyRate && buyRateId && (
          <BuyRateDrawer
            isNew={false}
            // form={buyRateForm}
            id={buyRateId}
            open={openBuyRate}
            onSaved={handleSaveBuyRate}
            onClose={handleCloseBuyRate}
          />
        )}

        <VendorDrawer
          id={vendor}
          onSaved={handleSave}
          onClose={handelCloseDrawer}
          visible={visible}
        />
      </>
    );
  },
);
