import { TBill, TContainer } from '@/types';
import {
  Button,
  Space,
  Table,
  TableColumnsType,
  Tag,
  Tooltip,
  message,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useMemo, useState } from 'react';
import EditBill from '@/pages/accounting/bills/components/EditBill';
import { useApp } from '@/utils/useapp';
import NewBill from '@/pages/accounting/bills/components/NewBill';
import { uniq, truncate } from 'lodash';
// import { ALL_NUMBER } from '.';
import {
  BILL_TYPE_LTL,
  STATUS_MAP,
} from '@/pages/accounting/bills/components/data';

interface IBillsTab {
  id: number;
  bills: TBill[];
  container?: any;
  setBillsTotal: (total: number) => void;
  onSaved: () => void;
}

export const BillTable: React.FC<IBillsTab> = ({
  id,
  bills,
  // container,
  setBillsTotal,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState<boolean>(false);

  const billsTotal = React.useMemo(() => {
    const total = bills?.reduce(
      (accumulator: number, bill: TBill) => accumulator + +bill.amount_total,
      0,
    );
    setBillsTotal(total);

    return total;
  }, [bills]);

  const columns: ColumnsType<TBill> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (value, record, index) => {
        // console.log(record);
        return (
          <EditBill
            // container={container?.number == ALL_NUMBER ? '' : container}
            billableId={record.billable_id}
            billableType={record.billable_type}
            id={record.id}
            onSaved={onSaved}
          >
            {record.dispute_at ? (
              <Tooltip
                placement="topLeft"
                title={<>In Dispute</>}
                arrowPointAtCenter
              >
                <a className="text-danger">{record.id}</a>
              </Tooltip>
            ) : (
              <a>{record.id}</a>
            )}
          </EditBill>
        );
      },
    },
    {
      title: 'Bill From',
      dataIndex: ['bill_to', 'name'],
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: 'CNTR#',
      dataIndex: 'container_numbers',
      key: 'container_numbers',
      width: 200,
      render: (text, record) => {
        return uniq(
          (record.charges || []).map((c) => c.containerNumber).filter((i) => i),
        ).join(',');
      },
    },
    {
      title: 'Sales Support',
      dataIndex: ['order', 'sales_support', 'name'],
      ellipsis: {
        showTitle: false,
      },
      width: 150,
    },
    {
      title: 'Customer',
      dataIndex: ['order', 'user', 'name'],
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (text, record) => (
        <Tooltip placement="topLeft" title={text}>
          {truncate(text, { length: 20 })}{' '}
          {record?.order?.user?.name
            ? `(${record?.order?.user?.bill_to?.name})`
            : ''}
        </Tooltip>
      ),
    },
    {
      title: 'Approve',
      key: 'is_approved',
      width: 100,
      render: (record: TBill) =>
        record.is_approved ? (
          <Tag color="processing">Approved</Tag>
        ) : (
          <Tag color="default">Unapproved</Tag>
        ),
    },
    {
      title: 'Dispute Status',
      key: 'dispute_status',
      width: 130,
      render: (record: TBill) =>
        record?.dispute_status ? (
          <Tag color="error">
            {
              STATUS_MAP[
                (record.dispute_status as unknown) as keyof typeof STATUS_MAP
              ]
            }
          </Tag>
        ) : (
          ''
        ),
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      width: 100,
    },
    {
      title: 'AmountTotal',
      dataIndex: 'amount_total',
      width: 180,
      key: 'amount_total',
    },
    {
      title: 'AmountDue',
      dataIndex: 'amount_due',
      width: 180,
      key: 'amount_due',
    },
    {
      title: 'AmountPaid',
      dataIndex: 'amount_paid',
      width: 180,
      key: 'amount_paid',
    },
    {
      title: 'Invoice At',
      dataIndex: 'billed_at',
      width: 150,
      key: 'billed_at',
    },
    {
      title: 'Due At',
      dataIndex: 'due_at',
      width: 150,
      key: 'due_at',
    },
    {
      title: 'Paid At',
      dataIndex: 'paid_at',
      width: 150,
      key: 'paid_at',
    },
    {
      title: 'Cleared At',
      dataIndex: 'cleared_at',
      width: 150,
      key: 'cleared_at',
    },
  ];

  return (
    <>
      <Space align="center">
        <h3>Bills</h3>
        <div>
          <NewBill
            // container={container?.number == ALL_NUMBER ? '' : container}
            billableId={id}
            billableType={BILL_TYPE_LTL}
            onSaved={onSaved}
          >
            <Button>New Bill</Button>
          </NewBill>
        </div>
      </Space>

      <Table
        size="small"
        loading={loading}
        pagination={false}
        rowKey="id"
        scroll={{
          x: 1500,
          y: 200,
        }}
        columns={columns}
        dataSource={bills}
        footer={() => (
          <Space className="w100" direction="vertical" align="end">
            <h4>Bills total {billsTotal.toFixed(2)}</h4>
          </Space>
        )}
      />
    </>
  );
};
