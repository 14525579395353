import { Button, Popover, Checkbox, Divider, Table, Row, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { ColumnsType } from 'antd/es/table';
import {
  OnDoneInterface,
  onSaveOperationInterface,
  TaskInterface,
} from '../Interfaces/TaskInterface';
import { TTarget } from '../Interfaces/ActionInterface';
import ActionColumn from './ActionColumn';
import TaskTitilePopover from './TaskTitilePopover';
import TaskColumn from './TaskColumn';
import TaskTypeColumn from './TaskTypeColumn';
import useAction from '../Actions/useAction';
import taskTypeUtil from '../Interfaces/TaskType';

export const TodoTable: React.FC<
{
  data: Array<TaskInterface>;
  loading: boolean;
} & TTarget &
OnDoneInterface
> = ({ data, loading, target, targetModel, onDone }) => {
  const { saveOperation } = useAction({ target, targetModel });

  const colors: { [key: string]: string } = useMemo(() => {
    const _colors: { [key: string]: string } = {};

    const taskTypes = taskTypeUtil.getCheckedTaskTypeList(target);

    for (const i in taskTypes) {
      _colors[taskTypes[i]] =
        targetModel?.task_icon_colors &&
        targetModel?.task_icon_colors[taskTypes[i]]
          ? targetModel?.task_icon_colors[taskTypes[i]]
          : 'gray';
    }

    return _colors;
  }, [targetModel]);

  const columns: ColumnsType<any> = useMemo(() => {
    const baseColumns: ColumnsType<any> = [
      {
        title: <TaskTitilePopover target={target} targetModel={targetModel} />,
        dataIndex: 'task',
        render: (_, record: any) => {
          return (
            <TaskColumn
              target={target}
              targetModel={targetModel}
              task={record}
            />
          );
        },
      },
      // {
      //     title: <TitleSlaTooltip />,
      //     dataIndex: 'sla',
      //     width: 180,
      //     render: (_, record: any) => {
      //         return (
      //             <RenderSla
      //                 item={record}
      //                 container={container}
      //                 showReset={true}
      //                 onSaved={afterUpdated}
      //             />
      //         );
      //     },
      // },
      {
        title: 'Task Type',
        dataIndex: 'task_type',
        width: 180,
        render: (_, record: any) => {
          return (
            <TaskTypeColumn
              target={target}
              targetModel={targetModel}
              task={record}
              colors={colors}
            />
          );
        },
      },
      {
        title: 'Action',
        width: 350,
        render: (_, record: any, index: number) => {
          return (
            <ActionColumn
              target={target}
              targetModel={targetModel}
              task={record}
              onDone={onDone}
              onSaveOperation={saveOperation}
            />
          );
        },
      },
    ];

    return baseColumns;
  }, [data, target, targetModel]);

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data || []}
        bordered
        size="small"
        pagination={false}
        scroll={{ x: '100%', y: 700 }}
        // rowKey="task"
      />
    </>
  );
};
