import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { get, truncate } from 'lodash';
import { Tooltip, Space, message, Modal, Button, Form, Input } from 'antd';
import { DrawerForm as WarehouseForm } from '@/pages/entity/warehouses/components/DrawerForm';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/es/form/Form';
import { UserMemoModal } from '@/pages/setting/users/components/UserMemoModal';

export const CustomerColumn: React.FC<{
  container: any;
  refreshOrder?: (orderId: number) => void;
}> = ({ container, refreshOrder }) => {
  const app = useApp();

  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);

  const handleSaved = () => {
    setOpen(false);
    refreshOrder && refreshOrder(container.order_id);
  };

  const handleClosed = () => {
    setOpen(false);
  };

  const handlePatchUpdate = async (userId: number) => {
    if (!container.order.id) {
      return;
    }
    setLoading(true);
    try {
      await app.service.patch(`orders/${container.order.id}`, {
        data: { user_id: userId },
      });

      refreshOrder && refreshOrder(container.order.id);
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Space>
        <UserSyncSelect
          style={{ width: '10rem' }}
          value={container.user_id || ''}
          onSelect={(user) => handlePatchUpdate(user?.id || 0)}
        />
        {container.user && (
          <div
            className={`${
              container.user.memo ? 'text-primary' : 'text-gray'
            } mr-sm cursor-pointer`}
            onClick={() => setOpen(!open)}>
            <InfoCircleOutlined />
          </div>
        )}
      </Space>

      {open && container.user && (
        <UserMemoModal
          open={open}
          user={container.user}
          onClose={handleClosed}
          onSaved={handleSaved}
        />
      )}
    </>
  );
};
