import {
  Card,
  Checkbox,
  Image,
  Input,
  message,
  Select,
  Tag,
  Tooltip,
} from 'antd';
import { Col, Form, Modal, Radio, Row, Typography } from 'antd';
import { Space } from 'antd';
import { Button } from 'antd';
import { Gap } from '../Gap';
import { useApp } from '@/utils/useapp';
import { useEffect, useMemo, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import { debounce } from 'lodash';
import {
  GooleVerificationLevel,
  GoogleVerification,
  ShiplifyVerification,
  AddressVerifyData,
  VerifyModalProps,
  AddressVerifyStatus,
  GoogleAddressComponents,
} from './types';
import { TCity } from '@/types';
import { UsedTable } from './UsedTable';
import { LTLAddressType } from '@/pages/truckload/ltl/constants';
import { InfoCircleOutlined } from '@ant-design/icons';

function getTagColor(level: GooleVerificationLevel): string | undefined {
  switch (level) {
    case 'CONFIRMED':
      return 'success';
    default:
      return 'error';
  }
}

function getTagText(level: GooleVerificationLevel): string {
  switch (level) {
    case 'CONFIRMED':
      return 'CONFIRMED';
    default:
      return 'UNCONFIRMED';
  }
}

function transformGoogleResult(
  response: AddressVerifyData,
): GoogleVerification {
  return {
    addressComponents: response.data?.google_result?.result?.address?.addressComponents?.reduce<GoogleAddressComponents>(
      (acc, item) => {
        acc[item.componentType] = {
          value: item.componentName.text,
          level: item.confirmationLevel,
        };
        return acc;
      },
      {} as GoogleAddressComponents,
    ),
    dpvConfirmation:
      response.data?.google_result?.result?.uspsData?.dpvConfirmation ?? 'N',
  };
}

const CodeAccuracy = () => {
  return (
    <div>
      Code Accuracy
      <br />
      A1 99.5%
      <br />
      A2 75%
      <br />
      A3 70%
      <br />
      A4 65%
      <br />
      U1 75%
      <br />
      U2 50%
      <br />
      U3 40%
      <br />
      U4 30%
    </div>
  );
};

const getLocality = (googleVerification: GoogleVerification | null) => {
  if (!googleVerification) {
    return '';
  }
  return [
    googleVerification.addressComponents?.['locality']?.value,
    googleVerification.addressComponents?.['sublocality']?.value,
    googleVerification.addressComponents?.['sublocality_level_1']?.value,
    googleVerification.addressComponents?.['sublocality_level_2']?.value,
    googleVerification.addressComponents?.['sublocality_level_3']?.value,
    googleVerification.addressComponents?.['sublocality_level_4']?.value,
    googleVerification.addressComponents?.['sublocality_level_5']?.value,
  ]
    .filter(Boolean)
    .join(' ');
};

const getState = (googleVerification: GoogleVerification | null) => {
  if (!googleVerification) {
    return '';
  }
  return [
    googleVerification.addressComponents?.['administrative_area_level_1']
      ?.value,
    googleVerification.addressComponents?.['administrative_area_level_2']
      ?.value,
    googleVerification.addressComponents?.['administrative_area_level_3']
      ?.value,
    googleVerification.addressComponents?.['administrative_area_level_4']
      ?.value,
    googleVerification.addressComponents?.['administrative_area_level_5']
      ?.value,
    googleVerification.addressComponents?.['administrative_area_level_6']
      ?.value,
    googleVerification.addressComponents?.['administrative_area_level_7']
      ?.value,
  ]
    .filter(Boolean)
    .join(' ');
};

const getAddress = (googleVerification: GoogleVerification | null) => {
  if (!googleVerification) {
    return '';
  }
  return (
    `${googleVerification.addressComponents?.['street_number']?.value} ${googleVerification.addressComponents?.['route']?.value}` +
    (googleVerification.addressComponents?.['subpremise']?.value
      ? `, ${googleVerification.addressComponents?.['subpremise']?.value}`
      : '')
  );
};

export const VerifyModal = <T extends object>({
  status,
  setStatus,
  showModal,
  setShowModal,
  address,
  addressType,
  accessorials,
  referenceId,
  referenceType,
  tag,
  usedReferences,
}: VerifyModalProps<T>) => {
  const app = useApp();

  const [
    googleVerification,
    setGoogleVerification,
  ] = useState<GoogleVerification | null>(null);

  const [
    shiplifyVerification,
    setShiplifyVerification,
  ] = useState<ShiplifyVerification | null>(null);

  const [form] = Form.useForm();
  const getShipmentAddressVerifyData = useMemoizedFn(async () => {
    const resp: AddressVerifyData = await app.service.get('address-verify', {
      params: {
        reference_id: referenceId,
        reference_type: referenceType,
        tag,
      },
    });
    if (!resp?.data) {
      return;
    }

    setGoogleVerification(transformGoogleResult(resp));
    setShiplifyVerification(resp.data.shiplify_result);
    setStatus(resp.data.status);

    if (resp.data?.address_line1) {
      form.setFieldValue('addressLine1', resp.data.address_line1);
    }
    if (resp.data?.address_line2) {
      form.setFieldValue('addressLine2', resp.data.address_line2);
    }
    if (resp.data?.city_id) {
      form.setFieldValue('cityId', resp.data.city_id);
    }
    if (resp.data?.zipcode) {
      form.setFieldValue('zipcode', resp.data.zipcode);
    }
  });

  useEffect(() => {
    getShipmentAddressVerifyData();
  }, [referenceId, referenceType, tag]);

  const [cityList, setCityList] = useState<TCity[]>([]);
  const currentCityId = Form.useWatch('cityId', form);
  const currentCity = cityList?.find((item) => item.id === currentCityId);

  const fetchCityList = useMemoizedFn(async () => {
    if (!form.getFieldValue('zipcode')) {
      return;
    }
    try {
      const resp = await app.service.get(
        `tl/tools/citylist/${form.getFieldValue('zipcode')}`,
      );
      setCityList(resp.data);
      if (resp.data.length > 0) {
        form.setFieldValue('cityId', resp.data[0].id);
      }
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  });

  const [googleVerificationLoading, setGoogleVerificationLoading] = useState(
    false,
  );
  const [googleError, setGoogleError] = useState<string | null>(null);
  const handleGoogleAddressVerification = useMemoizedFn(async () => {
    try {
      setGoogleError(null);
      setGoogleVerificationLoading(true);
      const content = {
        address_line1: form.getFieldValue('addressLine1'),
        address_line2: form.getFieldValue('selectAddr2')
          ? form.getFieldValue('addressLine2')
          : undefined,
        city_id: form.getFieldValue('cityId'),
        zipcode: form.getFieldValue('zipcode'),
      };
      const response: AddressVerifyData = await app.service.post(
        'address-verify/google-address',
        {
          method: 'post',
          params: {
            reference_id: referenceId,
            reference_type: referenceType,
            tag,
          },
          data: content,
        },
      );
      setGoogleVerification(transformGoogleResult(response));
    } catch (error) {
      console.error('Google address verification failed:', error);
      setGoogleError(JSON.stringify(error));
    } finally {
      setGoogleVerificationLoading(false);
    }
  });

  const [
    shiplifyVerificationLoading,
    setShiplifyVerificationLoading,
  ] = useState(false);
  const [shiplifyError, setShiplifyError] = useState<string | null>(null);
  const [shiplifyLocationTypeError, setShiplifyLocationTypeError] = useState<
    string | null
  >(null);
  const handleShiplifyAddressVerification = useMemoizedFn(async () => {
    try {
      setShiplifyError(null);
      setShiplifyLocationTypeError(null);
      setShiplifyVerificationLoading(true);

      const response: {
        data: { shiplify_result: ShiplifyVerification };
      } = await app.service.post('address-verify/shiplify-address', {
        method: 'post',
        params: {
          reference_id: referenceId,
          reference_type: referenceType,
          tag,
        },
        data: {
          address: getAddress(googleVerification),
          city_name: getLocality(googleVerification),
          state_name: getState(googleVerification),
          zipcode:
            googleVerification?.addressComponents?.['postal_code']?.value,
        },
      });
      const shiplifyResult = response.data.shiplify_result;
      setShiplifyVerification(shiplifyResult);
      if (
        (shiplifyResult.location_types.values.includes('commercial') &&
          addressType !== LTLAddressType.BUSINESS &&
          addressType !== 'bussiness') || // for history error data
        (shiplifyResult.location_types.values.includes('residential') &&
          addressType !== LTLAddressType.RESIDENTIAL &&
          addressType !== 'residential')
      ) {
        setShiplifyLocationTypeError('Please Check Shipment Location Type');
      }
    } catch (error) {
      console.error('Shiplify address verification failed:', error);
      setShiplifyError(JSON.stringify(error));
    } finally {
      setShiplifyVerificationLoading(false);
    }
  });

  const handleCopy = useMemoizedFn(() => {
    const addressParts: string[] = [];

    const addr1 = form.getFieldValue('addressLine1');
    if (addr1) {
      addressParts.push(addr1);
    }

    const addr2 = form.getFieldValue('addressLine2');
    if (addr2) {
      addressParts.push(addr2);
    }

    if (currentCity) {
      addressParts.push(currentCity.name);
      addressParts.push(currentCity.state);
    }

    const zipcode = form.getFieldValue('zipcode');
    if (zipcode) {
      addressParts.push(zipcode);
    }

    addressParts.push('US');

    const fullAddress = addressParts.join(', ');

    navigator.clipboard.writeText(fullAddress);
    message.success(fullAddress);
  });

  const debounceFetchCityList = useMemo(() => {
    return debounce(fetchCityList, 300);
  }, []);

  const onValuesChange = useMemoizedFn((changedFields) => {
    console.log(changedFields);
    if (changedFields.zipcode) {
      form.setFieldValue('cityId', null);
      debounceFetchCityList();
    }
  });

  useEffect(() => {
    form.setFieldValue('zipcode', address.zipcode);
    fetchCityList();
  }, [address.zipcode]);

  const handleStatusChange = useMemoizedFn(async (e) => {
    try {
      await app.service.patch('address-verify', {
        params: {
          reference_id: referenceId,
          reference_type: referenceType,
          tag,
        },
        data: {
          status: e.target.value,
        },
      });
      setStatus(e.target.value);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Modal
      title={
        <Space>
          <div>Address Verify</div>
          <Radio.Group
            value={status}
            onChange={handleStatusChange}
            disabled={false}
            buttonStyle="solid"
          >
            <Radio.Button value={AddressVerifyStatus.Pass}>Pass</Radio.Button>
            <Radio.Button value={AddressVerifyStatus.Fail}>Failed</Radio.Button>
            <Radio.Button value={AddressVerifyStatus.Hold}>Hold</Radio.Button>
          </Radio.Group>
        </Space>
      }
      width={'95%'}
      centered
      open={showModal}
      closable={true}
      okButtonProps={{ disabled: false }}
      cancelButtonProps={{ disabled: false }}
      onOk={() => {
        setShowModal(false);
      }}
      onCancel={() => {
        setShowModal(false);
      }}
    >
      <Card>
        <Row align={'bottom'}>
          <Col span={1}>
            <Typography.Title level={5}>Origin</Typography.Title>
          </Col>
          <Col flex={'auto'}>
            <Row gutter={8}>
              <Col span={6}>
                <Typography.Text strong>Address Line 1</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text strong>Address Line 2</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text strong>City</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text strong>State</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text strong>Zipcode</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text strong>Type</Typography.Text>
              </Col>
              <Col span={3}>
                <Typography.Text strong>Accessorials</Typography.Text>
              </Col>
            </Row>
            <Gap height="8px" />
            <Row gutter={8}>
              <Col span={6}>{address.addressLine1}</Col>
              <Col span={3}>{address.addressLine2}</Col>
              <Col span={3}>{currentCity?.name}</Col>
              <Col span={3}>{currentCity?.state}</Col>
              <Col span={3}>{address.zipcode}</Col>
              <Col span={3}>{addressType}</Col>
              <Col span={3}>{accessorials?.join(', ') || 'None'}</Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Gap height="24px" />
      <Card>
        <Row align={'stretch'}>
          <Col span={1}>
            <Typography.Title level={5}>Input</Typography.Title>
          </Col>
          <Col flex={'auto'}>
            <Form
              form={form}
              onValuesChange={onValuesChange}
              disabled={googleVerificationLoading}
            >
              <Row gutter={8}>
                <Col span={6}>
                  <Form.Item
                    name="addressLine1"
                    initialValue={address.addressLine1}
                  >
                    <Input placeholder="Address Line 1" disabled={false} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Space>
                    <Form.Item name="selectAddr2" valuePropName="checked">
                      <Checkbox disabled={false} />
                    </Form.Item>
                    <Form.Item
                      name="addressLine2"
                      initialValue={address.addressLine2}
                    >
                      <Input placeholder="Address Line 2" disabled={false} />
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="cityId"
                    initialValue={address.cityId}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      placeholder="Please select city"
                      options={cityList.map((item) => ({
                        value: item.id,
                        label: `${item.name}, ${item.state}`,
                      }))}
                      disabled={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="zipcode" initialValue={address.zipcode}>
                    <Input placeholder="Zipcode" disabled={false} />
                  </Form.Item>
                </Col>
                <Col flex={'auto'}>
                  <Space>
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={!currentCityId}
                      loading={googleVerificationLoading}
                      onClick={handleGoogleAddressVerification}
                    >
                      Google Address Verification
                    </Button>
                    <Button
                      type="primary"
                      disabled={false}
                      onClick={handleCopy}
                    >
                      Copy
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
      {googleVerification && (
        <Card loading={googleVerificationLoading}>
          <Row align={'stretch'} justify={'start'}>
            <Col span={1}>
              <Typography.Title level={5}>Output</Typography.Title>
            </Col>
            <Col flex={'auto'}>
              {googleError ? (
                <Typography.Text type="danger">{googleError}</Typography.Text>
              ) : (
                <Row gutter={64}>
                  <Col>
                    <Space direction="vertical">
                      <span>street_number</span>
                      <span>
                        {googleVerification?.addressComponents?.[
                          'street_number'
                        ]?.value ?? 'N/A'}
                      </span>
                      <Tag
                        color={getTagColor(
                          googleVerification?.addressComponents?.[
                            'street_number'
                          ]?.level,
                        )}
                      >
                        {getTagText(
                          googleVerification?.addressComponents?.[
                            'street_number'
                          ]?.level,
                        )}
                      </Tag>
                    </Space>
                  </Col>
                  <Col>
                    <Space direction="vertical">
                      <span>route</span>
                      <span>
                        {googleVerification?.addressComponents?.['route']
                          ?.value ?? 'N/A'}
                      </span>
                      <Tag
                        color={getTagColor(
                          googleVerification?.addressComponents?.['route']
                            ?.level,
                        )}
                      >
                        {getTagText(
                          googleVerification?.addressComponents?.['route']
                            ?.level,
                        )}
                      </Tag>
                    </Space>
                  </Col>
                  {form.getFieldValue('selectAddr2') && (
                    <Col>
                      <Space direction="vertical">
                        <span>subpremise</span>
                        <span>
                          {googleVerification?.addressComponents?.['subpremise']
                            ?.value ?? 'N/A'}
                        </span>
                        <Tag
                          color={getTagColor(
                            googleVerification?.addressComponents?.[
                              'subpremise'
                            ]?.level,
                          )}
                        >
                          {getTagText(
                            googleVerification?.addressComponents?.[
                              'subpremise'
                            ]?.level,
                          )}
                        </Tag>
                      </Space>
                    </Col>
                  )}
                  <Col>
                    <Space direction="vertical">
                      <span>locality</span>
                      <span>
                        {
                          googleVerification?.addressComponents?.['locality']
                            ?.value
                        }
                      </span>
                      <Tag
                        color={getTagColor(
                          googleVerification?.addressComponents?.['locality']
                            ?.level,
                        )}
                      >
                        {getTagText(
                          googleVerification?.addressComponents?.['locality']
                            ?.level,
                        )}
                      </Tag>
                    </Space>
                  </Col>
                  <Col>
                    <Space direction="vertical">
                      <span>administrative_area_level_1</span>
                      <span>
                        {googleVerification?.addressComponents?.[
                          'administrative_area_level_1'
                        ]?.value ?? 'N/A'}
                      </span>
                      <Tag
                        color={getTagColor(
                          googleVerification?.addressComponents?.[
                            'administrative_area_level_1'
                          ]?.level,
                        )}
                      >
                        {getTagText(
                          googleVerification?.addressComponents?.[
                            'administrative_area_level_1'
                          ]?.level,
                        )}
                      </Tag>
                    </Space>
                  </Col>
                  <Col>
                    <Space direction="vertical">
                      <span>postal_code</span>
                      <span>
                        {googleVerification?.addressComponents?.['postal_code']
                          ?.value ?? 'N/A'}
                      </span>
                      <Tag
                        color={getTagColor(
                          googleVerification?.addressComponents?.['postal_code']
                            ?.level,
                        )}
                      >
                        {getTagText(
                          googleVerification?.addressComponents?.['postal_code']
                            ?.level,
                        )}
                      </Tag>
                    </Space>
                  </Col>
                  <Col>
                    <Space direction="vertical">
                      <span>dpv_confirmation</span>
                      <Space>
                        {googleVerification?.dpvConfirmation ?? 'N/A'}
                        <Tooltip
                          title={
                            <Image
                              src={require('@/assets/image/google-dpv.jpg')}
                            />
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Space>
                    </Space>
                  </Col>
                  <Col>
                    <Space>
                      <Button
                        type="primary"
                        disabled={!currentCityId}
                        onClick={handleShiplifyAddressVerification}
                      >
                        Shiplify Verification
                      </Button>
                    </Space>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Card>
      )}

      <Gap height="24px" />

      {shiplifyVerification && (
        <Card
          title={
            <Space>
              <Typography.Title level={5}>
                Shiplify Verification
              </Typography.Title>
              {shiplifyError ? <Tag color="error">{shiplifyError}</Tag> : <></>}
            </Space>
          }
          loading={shiplifyVerificationLoading}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Card title="Location Type" loading={shiplifyVerificationLoading}>
                {shiplifyVerification?.location_types?.values.map((value) => (
                  <Row key={value} justify={'space-between'}>
                    <Col>
                      {
                        <Space>
                          <span>{value}</span>
                          {shiplifyLocationTypeError && (
                            <Tag color="error">{shiplifyLocationTypeError}</Tag>
                          )}
                        </Space>
                      }
                    </Col>
                    <Col>
                      <Space>
                        <span>
                          {shiplifyVerification?.location_types?.confidence}
                        </span>
                        <Tooltip title={<CodeAccuracy />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>
                ))}
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Attributes" loading={shiplifyVerificationLoading}>
                <Row justify={'space-between'}>
                  <Col>Dock Access</Col>
                  <Col>
                    <Space>
                      {shiplifyVerification?.dock_access?.value ?? 'no'}
                      {shiplifyVerification?.dock_access?.confidence ?? '-'}
                      <Tooltip title={<CodeAccuracy />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
                <Row justify={'space-between'}>
                  <Col>Fork Lift</Col>
                  <Col>
                    <Space>
                      {shiplifyVerification?.forklift?.value ?? 'no'}
                      {shiplifyVerification?.forklift?.confidence ?? '-'}
                      <Tooltip title={<CodeAccuracy />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
      )}

      <Gap height="64px" />
      <UsedTable<T>
        address={address}
        getUsedReferences={usedReferences.getUsedReferences}
        columns={usedReferences.columns}
      />
    </Modal>
  );
};
