import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Drawer,
  message,
  Space,
  Popconfirm,
  Table,
  Tag,
  Form,
  Row,
  Col,
  Tooltip,
  Select,
  Input,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DrawerForm';
import { useForm } from 'antd/lib/form/Form';
import { TAdmin, TCollection } from '@/types';
import { get, isEmpty } from 'lodash';
import usePagination from '@/components/usePagination';
import { TablePaginationConfig } from 'antd';
const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [title, setTitle] = React.useState('Create Admin');
  const [data, setData] = React.useState<TCollection<TAdmin>>();
  const [form] = useForm();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (_pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('admins', {
          params: {
            ...filter.getFieldsValue(),
            withTrashed: true,
            page: _pagination?.current || pagination?.current || 1,
            per_page: _pagination?.pageSize || pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.error || 'System Error');
      }

      setLoading(false);
    },
    [pagination],
  );

  const addNew = () => {
    form.resetFields();
    form.setFieldsValue({
      roles: ['admin'],
    });
    setTitle('Create Admin');
    setVisible(true);
  };

  const handleClearAll = async () => {
    filter.resetFields();
    await fetchData();
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    setLoading(true);
    try {
      !values.id
        ? await app.service.post('admins', { data: values })
        : await app.service.put(`admins/${values.id}`, { data: values });
      message.success('Saved');
      fetchData();
      setVisible(false);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleDisable = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`admins/${id}`);

      message.success('Disable');

      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleEnable = async (id: number) => {
    setLoading(true);

    try {
      await app.service.patch(`admins/${id}/restore`);

      message.success('Enable');

      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleClearTwoFaActive = async (row: TAdmin) => {
    setLoading(true);

    try {
      await app.service.patch(`admins/${row.id}`, {});

      message.success('Saved');

      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleEdit = async (consignee: TAdmin) => {
    setLoading(true);

    try {
      setTitle('Edit Admin');
      form.resetFields();
      setVisible(true);
      form.setFieldsValue(consignee);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 60,
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        width: 150,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200,
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 200,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        width: 200,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: 350,
        render: (text, record) => <>{get(record, 'roles', []).join(',')}</>,
      },
      {
        title: '2FA Status',
        dataIndex: 'status',
        key: 'status',
        width: 200,
        render: (checked: boolean, record: TAdmin) => {
          const two_fa_active = get(record, 'two_fa_active');
          if (false === two_fa_active) {
            return <Tag color="red">{'Disabled'}</Tag>;
          } else {
            return (
              <Tooltip
                color="#fff"
                title={
                  <Button
                    type={'default'}
                    shape={'round'}
                    onClick={() => handleClearTwoFaActive(record)}>
                    Clear
                  </Button>
                }>
                <Tag color="success">{'Enabled'}</Tag>
              </Tooltip>
            );
          }
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 200,
        render: (text, record) => {
          if (isEmpty(get(record, 'deleted_at'))) {
            return <Tag color="success">{'Enabled'}</Tag>;
          } else {
            return <Tag color="red">{'Disabled'}</Tag>;
          }
        },
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            {isEmpty(get(record, 'deleted_at')) ? (
              <Popconfirm
                placement="left"
                title="Sure to disable?"
                okText="Confirm"
                cancelText="Cancel"
                onConfirm={() => handleDisable(record.id)}>
                <a>Disable</a>
              </Popconfirm>
            ) : (
              <Popconfirm
                placement="left"
                title="Sure to Enable?"
                okText="Confirm"
                cancelText="Cancel"
                onConfirm={() => handleEnable(record.id)}>
                <a>Enable</a>
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Admins"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Admin
            </Button>
          </div>
        }></Header>

      <div className={styles.filter}>
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={22}>
            <Col span={8}>
              <Form.Item name="name" label="Name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="status" label="Status">
                <Select>
                  <Select.Option value={true}>ENABLED</Select.Option>
                  <Select.Option value={false}>DISABLED</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="two_fa_active" label="2FA Status">
                <Select>
                  <Select.Option value={true}>ENABLED</Select.Option>
                  <Select.Option value={false}>DISABLED</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={handleClearAll}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Table
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title={title}
        placement="right"
        width="60%"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm form={form} onSubmit={handleSave} />
      </Drawer>
    </div>
  );
};

export default Index;
