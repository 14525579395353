import actionUtil from './ActionInterface';
export interface TaskOperationLogInterface {
  id: number;
  action: string;
  value: string;
  action_at: string;
}

export const ACTION_LABELS: { [key: string]: string } = {
  // [ACTION_RESET_SLA]: 'update due at',
  // [ACTION_STREET_TURN]: 'update street turn at',
};
