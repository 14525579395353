import { useApp } from '@/utils/useapp';
import { message, Space, Tooltip, Typography, Row } from 'antd';

import React, { FC } from 'react';
import { DOCUMENT_FILE_TYPE_BOL } from './data';
import { FTLShipmentInterface } from '../../shipments/Interfaces/ShipmentInterface';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';

const RenderDefaultBol: FC<{ shipment: FTLShipmentInterface }> = ({
  shipment,
}) => {
  const app = useApp();

  const handleOpenFirstBol = async () => {
    if (!shipment?.id) {
      return;
    }

    try {
      const resp = await app.service.get(`ftl/shipments/${shipment.id}/bol`, {
        responseType: 'blob',
      });

      const file = new Blob([resp], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const w = window.open(fileURL, '_blank');
      if (!w) {
        throw new Error('Please allow popups for this website');
      }
      w.document.title = shipment.first_bol_name;
    } catch (err: any) {
      message.error(err.data.message);
    }
  };

  const handleDownload = async () => {
    try {
      await app.service.download(`ftl/shipments/${shipment.id}/bol`, {
        method: 'get',
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  return (
    <Row className="flex justify-between align-center mt-md mb-md">
      <div>
        <Space>
          <Typography.Text>[{DOCUMENT_FILE_TYPE_BOL}]</Typography.Text>
          <Typography.Link onClick={handleOpenFirstBol}>
            {shipment.first_bol_name}
          </Typography.Link>
        </Space>
      </div>
      <div>
        <Space>
          <Typography.Text>created by System</Typography.Text>
          <Tooltip title={<span>Customer Visible</span>}>
            <span className="disable-cursor">
              <EyeOutlined />
            </span>
          </Tooltip>
          <Typography.Link onClick={handleDownload}>
            <DownloadOutlined />
          </Typography.Link>
        </Space>
      </div>
    </Row>
  );
};

export default RenderDefaultBol;
