import { useApp } from '@/utils/useapp';
import { useObserver } from 'mobx-react';
import React, { useState } from 'react';
import { Input } from 'antd';
import { history } from 'umi';
import { upperFirst, startsWith } from 'lodash';

const BUYRATE_PATHNAME = '/rates/buyRates';
const SELLRATE_PATNANME = '/rates/sellRates';

const BUY_PREFIX = 'B';
const SELL_PREFIX = 'S';

export const HeaderSearch: React.FC = () => {
  const app = useApp();
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = async (value: string) => {
    if (!value) {
      return false;
    }

    await app.store.search.setRateId(value);

    let pathname = BUYRATE_PATHNAME;

    if (upperFirst(value).startsWith(SELL_PREFIX)) {
      pathname = SELLRATE_PATNANME;
    }

    if (history.location.pathname == pathname) {
      return history.push({
        pathname: pathname,
      });
    } else {
      return history.push({
        pathname: pathname,
      });
    }
  };

  const observeRateId = () => {
    React.useEffect(() => {
      if (!app.store.search.rateId) {
        setSearchValue('');
      }
    }, [app.store.search.rateId]);
  };

  return useObserver(() => {
    observeRateId();

    return (
      <Input.Search
        placeholder="Rate ID"
        allowClear
        onSearch={handleSearch}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        style={{ width: 300 }}
      />
    );
  });
};
