import React, { useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Tag,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableColumnsType,
  Radio,
  Popover,
  message,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { TInvoiceCharge } from '@/types';
import { BillingCodeSelect } from '@/components/BillingCodeSelect';
import {
  MinusCircleOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { FormInstance } from 'antd/es/form';
import { CopyButton } from '@/components/CopyButton';
import { InvoiceReminds } from './InvoiceReminds';
import { EstimatePreviewDrawer } from './EstimatePreviewDrawer';
import {
  INVOICE_TYPE_FTL,
  INVOICE_TYPE_MAP,
  INVOICE_TYPE_PRIMARY,
  STATE_ESTIMATE,
} from '../data';
import { ChargeExplanation } from './ChargeExplanation';
import { ExtraCostTable } from './ExtraCostTable';
import { useWatch } from 'antd/lib/form/Form';
import Decimal from 'decimal.js';
import { advancedTermsRule } from '@/utils/advancedTerms';
import { INVOICE_TYPE_LTL, INVOICE_TYPE_ORDER } from '../data';
import { uniq } from 'lodash';

interface Props {
  form: FormInstance;
  containers?: Array<any>;
  bills: any;
  loading?: boolean;
  containerNumber?: string;
  hasTerminalFees?: boolean;
  handleDateRelatedChange: (field: string, value: any) => void;
  onSaved?: () => void;
  onChangingContainerNumbers: (numbers: any) => void;
  invoiceType?: string;
}

export const TypeLabelPopover: React.FC = () => {
  return (
    <Popover
      content={
        <p>
          <strong> Primary:</strong>
          <ul>
            <li>
              Primary Invoice includes Truck Rate and Chassis Fee, prepull and
              so on. Usually, there is only one primary invoice for one
              container. Please note that the primary will be rewritten by Auto
              Estimate AR.
            </li>
          </ul>
          <strong>Additional</strong>
          <ul>
            <li>Additional invoice usually refers to demurrage, perdiem.</li>
          </ul>
        </p>
      }
      title="Instruction"
    >
      Type <InfoCircleOutlined />
    </Popover>
  );
};

const InvoiceForm: React.FC<Props> = ({
  form,
  containers = [],
  bills,
  loading = false,
  containerNumber,
  hasTerminalFees = true,
  handleDateRelatedChange,
  onSaved,
  onChangingContainerNumbers,
  invoiceType = INVOICE_TYPE_ORDER,
}) => {
  const app = useApp();
  const isController = app.store.auth.hasRole(['controller', 'super-admin']);

  const BILLCODE_CATEGORY_MAP = {
    [INVOICE_TYPE_ORDER]: 'DRAY',
    [INVOICE_TYPE_LTL]: 'LTL',
    [INVOICE_TYPE_FTL]: 'FTL',
  };

  const [loadingEstimate, setLoadingEstimate] = useState(false);
  const [showEstimate, setShowEstimate] = useState(false);
  const [chosenReminds, setChosendReminds] = useState([]);
  const [showExtraCost, setShowExtraCost] = useState(false);
  const [hasMissingCharges, setHasMissingCharges] = useState(false);

  const confirmedAt = useWatch('confirmed_at', form);

  const isEstimateStatus = useWatch('state', form) == STATE_ESTIMATE;

  const _containers = React.useMemo(() => {
    return containers.filter(
      (c) => !containerNumber || c.number == containerNumber,
    );
  }, [containers, containerNumber]);

  const handleShowEstimate = () => {
    setShowEstimate(true);
  };

  const handleCloseEstimate = () => {
    setShowEstimate(false);
  };

  const handleImportEstimate = (charges: any, reminds: any) => {
    const _charges = [];
    const oldCharges = form.getFieldValue('charges');

    let oldCharge;

    for (const index in charges) {
      const { remind, ...charge } = charges[index];

      if (
        oldCharges &&
        (oldCharge = oldCharges?.find((c) => c.code == charge.code))
      ) {
        charge.reason = oldCharge.reason;
        charge.locked = oldCharge.locked;
      }

      _charges.push(charge);

      if (remind) {
        if (undefined == reminds[charges[index].containerNumber]) {
          reminds[charges[index].containerNumber] = [];
        }
      }
    }

    form.setFieldValue('charges', _charges);

    setChosendReminds(reminds);

    handleCloseEstimate();
  };

  const checkDuplicateCharge = (
    newCharge: any,
    charges: any,
    index: any,
    setFieldsValue: (data: any) => void,
  ) => {
    if (
      newCharge?.code &&
      newCharge.containerNumber &&
      charges.find(
        (_c, _i) =>
          _c.code == newCharge.code &&
          _c.containerNumber == newCharge.containerNumber &&
          _i != index,
      )
    ) {
      setFieldsValue({
        charges: charges.map((charge, i) =>
          i == index ? { ...charge } : charge,
        ),
      });
      return message.error('This container has such charge');
    }

    setFieldsValue({
      charges: charges.map((charge, i) => (i == index ? newCharge : charge)),
    });
  };

  const checkHasMissingCharges = (charges: any) => {
    if (!bills) {
      return false;
    }

    const billCharges = bills.flatMap((bill) => bill.charges);

    const codes = charges?.map((c) => c.code) || [];

    if (billCharges.every((charge) => codes.includes(charge.code))) {
      setHasMissingCharges(false);
    } else {
      setHasMissingCharges(true);
    }
  };

  useEffect(() => {
    bills && checkHasMissingCharges(form.getFieldValue('charges'));
  }, [bills]);

  const columns: TableColumnsType<any> = React.useMemo(() => {
    const cols: TableColumnsType<any> = [
      {
        title: 'Code',
        width: 120,
        render: (_, row: TInvoiceCharge, index) => (
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name={['charges', index, 'code']}
                noStyle
                hasFeedback
                rules={[{ required: true, message: '' }]}
              >
                <BillingCodeSelect
                  style={{ width: '100%' }}
                  value={getFieldValue(['charges', index, 'code'])}
                  filterModel={(row, value) => {
                    return row.category == BILLCODE_CATEGORY_MAP[invoiceType];
                  }}
                  onSelect={(c) => {
                    checkDuplicateCharge(
                      { ...row, code: c.code, name: c.name },
                      form.getFieldValue('charges'),
                      index,
                      setFieldsValue,
                    );

                    // const charges = form.getFieldValue('charges');

                    // if (
                    //   c?.code &&
                    //   charges.find(
                    //     (_c) =>
                    //       _c.code == c.code &&
                    //       _c.containerNumber == row.containerNumber,
                    //   )
                    // ) {
                    //   setFieldsValue({
                    //     charges: getFieldValue('charges').map((charge, i) =>
                    //       i == index
                    //         ? { ...charge, code: undefined, name: '' }
                    //         : charge,
                    //     ),
                    //   });

                    //   return message.error('This container has such charge');
                    // } else {
                    //   setFieldsValue({
                    //     charges: getFieldValue('charges').map((charge, i) =>
                    //       i == index
                    //         ? { ...charge, code: c?.code, name: c?.name }
                    //         : charge,
                    //     ),
                    //   });
                    // }
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        ),
      },
      {
        title: 'Name',
        width: 120,
        render: (_, row: TInvoiceCharge, index) => (
          <Form.Item
            name={['charges', index, 'name']}
            noStyle
            hasFeedback
            rules={[{ required: true, message: '' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        ),
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        width: 120,
        render: (_, row: TInvoiceCharge, index) => (
          <Form.Item
            name={['charges', index, 'rate']}
            noStyle
            hasFeedback
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        ),
      },
      {
        title: 'Qty',
        dataIndex: 'qty',
        width: 80,
        render: (_, row: TInvoiceCharge, index) => (
          <Form.Item
            name={['charges', index, 'qty']}
            noStyle
            hasFeedback
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        ),
      },
      {
        title: () => (
          <>
            <span>Amount</span>
          </>
        ),
        width: 120,
        render: (_, row: TInvoiceCharge, index) => {
          return (
            <Input
              style={{ width: '100%' }}
              value={new Decimal(row.qty || 0)
                .times(new Decimal(row.rate || 0))
                .toFixed(2)}
              disabled
            />
          );
        },
      },

      {
        title: '',
        width: 100,
        fixed: 'right',
        render: (_, row: TInvoiceCharge, index) => (
          <Space>
            {(invoiceType || form.getFieldValue('invoiceable_type')) ==
              INVOICE_TYPE_ORDER && (
              <Button
                type="text"
                size="small"
                onClick={() => {
                  form.setFieldValue(
                    ['charges', index, 'locked'],
                    row.locked ? false : true,
                  );
                }}
              >
                <Form.Item
                  name={['charges', index, 'locked']}
                  initialValue={false}
                  noStyle
                />
                <span className={row.locked ? 'text-primary' : 'text-gray'}>
                  <Tooltip
                    placement="right"
                    title="Auto estimate invoice AR will not update the charge if you locked it."
                  >
                    <LockOutlined />
                  </Tooltip>
                </span>
              </Button>
            )}
            <Button
              type="text"
              size="small"
              onClick={() => {
                const list = [...form.getFieldValue('charges')];
                list.splice(index, 1);
                form.setFieldsValue({ charges: list });
                toChangeContainerNumbers(list);
                checkHasMissingCharges(list);
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Space>
        ),
      },
    ];

    if (invoiceType == INVOICE_TYPE_ORDER) {
      cols.unshift({
        title: () => (
          <Space>
            <span>CNTR#</span>
          </Space>
        ),
        width: 200,
        render: (_, row: TInvoiceCharge, index) => (
          <Space>
            <Form.Item name={['charges', index, 'container_id']} hidden />
            <Form.Item
              name={['charges', index, 'containerNumber']}
              noStyle
              hasFeedback
              rules={[{ required: true, message: '' }]}
            >
              <Select
                style={{ width: 155 }}
                onChange={(val, option: any) => {
                  // form.setFieldValue(
                  //   ['charges', index, 'container_id'],
                  //   option.container_id,
                  // );
                  checkDuplicateCharge(
                    {
                      ...row,
                      container_id: option.container_id,
                      containerNumber: option.value,
                    },
                    form.getFieldValue('charges'),
                    index,
                    form.setFieldsValue,
                  );
                }}
                options={_containers.map((cntr) => ({
                  container_id: cntr.id,
                  value: cntr.number,
                  text: cntr.number,
                }))}
              />
            </Form.Item>
            {form.getFieldValue(['charges', index, 'containerNumber']) && (
              <CopyButton
                value={form.getFieldValue([
                  'charges',
                  index,
                  'containerNumber',
                ])}
              />
            )}
          </Space>
        ),
      });
      // insert cols to the second last position
      cols.splice(cols.length - 2, 0, {
        title: 'Explanation',
        width: 100,
        render: (_, row: TInvoiceCharge, index) => (
          <ChargeExplanation
            form={form}
            row={row}
            index={index}
            disabled={confirmedAt ? true : false}
            containerId={
              row.container_id ||
              _containers.find((c) => c.number == row.containerNumber)?.id
            }
          />
        ),
      });
    }
    return cols;
  }, [_containers, containerNumber]);

  const toChangeContainerNumbers = (allCharges: any) => {
    onChangingContainerNumbers(
      uniq(allCharges.map((c: any) => c.containerNumber)),
    );
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.charges) {
      toChangeContainerNumbers(allValues.charges);
      checkHasMissingCharges(allValues.charges);
      return;
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          onValuesChange={handleValuesChange}
          form={form}
          initialValues={{ charges: [], type: INVOICE_TYPE_PRIMARY }}
          disabled={!isController && !!confirmedAt}
        >
          <Form.Item name="id" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="state" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="order_id" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="invoiceable_id" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="invoiceable_type" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name={'confirmed_at'} hidden />
          <Form.Item
            label={<TypeLabelPopover />}
            name="type"
            rules={[{ required: true, message: 'Type is required.' }]}
          >
            <Radio.Group>
              {Object.keys(INVOICE_TYPE_MAP).map((key) => (
                <Radio key={key} value={+key}>
                  {
                    INVOICE_TYPE_MAP[
                      (key as unknown) as keyof typeof INVOICE_TYPE_MAP
                    ]
                  }
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Customer"
            name="user_id"
            rules={[{ required: true, message: 'Customer is required.' }]}
          >
            <UserSyncSelect
              onSelect={(user) => {
                form.setFieldsValue({
                  billing_to: user?.billing_address,
                  // terms: user?.company?.terms || 0,
                });
                handleDateRelatedChange('terms', user?.terms || 0);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Billing To"
            name="billing_to"
            rules={[{ required: true, message: 'Billing To is required.' }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Row gutter={24}>
            <Col md={6}>
              <Form.Item
                label="Invoice Date"
                name="invoiced_at"
                rules={[
                  {
                    required: !isEstimateStatus,
                    message: 'Invoice Date is required',
                  },
                ]}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.invoiced_at !== curValues.invoiced_at
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('invoiced_at');
                    return (
                      <DatePicker
                        style={{ width: '100%' }}
                        value={value ? moment(value) : null}
                        onChange={
                          (v) =>
                            handleDateRelatedChange(
                              'invoiced_at',
                              v?.format('YYYY-MM-DD'),
                            )
                          // setFieldsValue({
                          //   invoiced_at: v?.format('YYYY-MM-DD'),
                          // })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Terms"
                name="terms"
                rules={[
                  {
                    required: !isEstimateStatus,
                    message: 'Terms is required',
                  },
                  advancedTermsRule,
                ]}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.terms !== curValues.terms
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('terms');
                    return (
                      <Input
                        value={value}
                        onChange={(e) =>
                          handleDateRelatedChange('terms', e.target.value)
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Due Date"
                name="due_at"
                rules={[
                  {
                    required: !isEstimateStatus,
                    message: 'Due Date is required',
                  },
                ]}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.due_at !== curValues.due_at
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('due_at');
                    return (
                      <DatePicker
                        style={{ width: '100%' }}
                        value={value ? moment(value) : null}
                        onChange={(v) =>
                          setFieldsValue({
                            due_at: v?.format('YYYY-MM-DD'),
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Penalty Date"
                name="penalty_at"
                rules={[
                  {
                    required: !isEstimateStatus,
                    message: 'Penalty Date is required',
                  },
                ]}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.penalty_at !== curValues.penalty_at
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('penalty_at');
                    return (
                      <DatePicker
                        style={{ width: '100%' }}
                        value={value ? moment(value) : null}
                        onChange={(v) =>
                          setFieldsValue({
                            penalty_at: v?.format('YYYY-MM-DD'),
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col md={8}>
              <Form.Item name="amount_total" label="Amount Total">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="amount_due" label="Amount Due">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="amount_paid" label="Amount Paid">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {invoiceType == INVOICE_TYPE_ORDER && (
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <div style={{ maxWidth: '45vw' }}>
                  {!hasTerminalFees && (
                    <Row gutter={24} className="mb-md">
                      <Col span={24}>
                        <Space>
                          <Tag color="red">
                            Please double confirm if the customer or vendor has
                            paid the terminal fees
                          </Tag>
                        </Space>
                      </Col>
                    </Row>
                  )}

                  {hasMissingCharges && (
                    <Row gutter={24} className="mb-md">
                      <Col span={24}>
                        <Space>
                          <Tag color="red">
                            Please double-check as the charges do not match
                            those on the bill.
                          </Tag>
                        </Space>
                      </Col>
                    </Row>
                  )}

                  <InvoiceReminds
                    containers={containers}
                    reminds={chosenReminds}
                    charges={getFieldValue('charges') || []}
                    onUpdated={onSaved}
                  />
                </div>
              )}
            </Form.Item>
          )}

          {showExtraCost && invoiceType == INVOICE_TYPE_ORDER && (
            <ExtraCostTable show={showExtraCost} containers={_containers} />
          )}

          <Divider orientation="left">
            <Space>
              Charges
              {invoiceType == INVOICE_TYPE_ORDER && (
                <>
                  <Button
                    type="primary"
                    size="small"
                    disabled={loadingEstimate}
                    onClick={handleShowEstimate}
                  >
                    Estimate AR
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setShowExtraCost(!showExtraCost)}
                  >
                    {`${showExtraCost ? 'Hide' : 'View'} Extra cost`}
                  </Button>
                </>
              )}
            </Space>
          </Divider>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Table
                bordered
                size="small"
                columns={columns}
                dataSource={getFieldValue('charges')}
                scroll={{ y: 300 }}
                pagination={false}
                summary={(pageData) => {
                  let total = new Decimal(0);

                  pageData.forEach(({ rate, qty }) => {
                    total = total.add(
                      new Decimal(rate || 0).times(new Decimal(qty || 0)),
                    );
                  });

                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={1} align="left">
                          <Button
                            size="small"
                            onClick={() => {
                              form.setFieldsValue({
                                charges: [
                                  ...(form.getFieldValue('charges') || []),
                                  {
                                    containerNumber: containerNumber
                                      ? containerNumber
                                      : '',
                                    qty: 1,
                                  },
                                ],
                              });
                            }}
                          >
                            Add Charge
                          </Button>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={1}
                          colSpan={columns.length - 3}
                          align="right"
                        >
                          <strong>Total</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <strong>{total.toFixed(2)}</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} colSpan={100} />
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            )}
          </Form.Item>

          <Form.Item name="note" label="Note">
            <Input.TextArea rows={5} />
          </Form.Item>

          <Form.Item name="remark" label="Remark">
            <Input.TextArea rows={5} />
          </Form.Item>
        </Form>
      </Spin>

      {showEstimate && (
        <EstimatePreviewDrawer
          containers={_containers}
          open={showEstimate}
          disabled={!isController && !!confirmedAt}
          charges={form.getFieldValue('charges')}
          onClose={handleCloseEstimate}
          onImport={handleImportEstimate}
        />
      )}
    </>
  );
};

export default InvoiceForm;
