import { tag } from '@turf/turf';

export enum Status {
  MET_EXAM = 'met_exam',
  CET_EXAM = 'cet_exam',
  CUSTOMER_HOLD = 'customer_hold',
  CLOSED_AREA = 'closed_area',
  DEMURRAGE = 'demurrage',
  CUSTOMER_CANCELED_ORDER = 'customer_canceled_order',
  X_RAY_EXAM = 'x_ray_exam',
  DIVERSION = 'diversion',
}

export const StatusLabelMap = {
  [Status.MET_EXAM]: 'Met Exam',
  [Status.CET_EXAM]: 'CET Exam',
  [Status.CUSTOMER_HOLD]: 'Customer Hold',
  [Status.CLOSED_AREA]: 'Closed Area',
  [Status.DEMURRAGE]: 'Demurrage',
  [Status.CUSTOMER_CANCELED_ORDER]: 'Customer canceled order',
  [Status.X_RAY_EXAM]: 'X-Ray Exam',
  [Status.DIVERSION]: 'Diversion',
};

export const getStatusMapByTarget = (target: string) => {
  switch (target) {
    case TARGET_LTL:
      return {
        [Status.CUSTOMER_HOLD]: 'Customer Hold',
      };
    default:
      return {};
  }
};

export const TARGET_LTL = 'ltl_shipment';
