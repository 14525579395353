import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message, Tabs, Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get, has } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { AccountingTab } from './AccountingTab';
import { ContactsTab } from './ContactsTab';
import { PossibleCharges } from '@/components/PossibleCharges';
import { InfoTab } from './InfoTab';
import { DocumentsTab } from './DocumentsTab';
import { PossibleChargesTab } from './PossibleChargesTab';
import { PortalSetupTab } from './PortalSetupTab';
import {
  TIMEZONE,
  WEEKDAYS,
  HAZMAT_OPTIONS_MAP,
  OVERWEIGHT_CODE,
  HAZMAT_CODE,
  REEFER_CODE,
  RESIDENTIAL_CODE,
  FBA_DELIVERY_CODE,
} from '../data';
import { Weight } from '@/components/ContainerTable';
import { WarningFilled } from '@ant-design/icons';
import { IsSyncCreditOrInsuranceModal } from './IsSyncCreditOrInsuranceModal';
import { debounce, update } from 'lodash';
import { SpecialTermsTab } from './SpecialTermsTab';
import { SyncTab } from './SyncTab';

const POSSIBLE_CHARGES_RELATED_FIELDS = ['code'];

export const VendorDrawer: React.FC<{
  id: number | null;
  onSaved?: (data?: any) => void;
  onClose?: () => void;
  visible: boolean;
}> = ({ id, visible, onClose, onSaved }) => {
  const [activeKey, setActiveKey] = React.useState(0);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [vendorCredit, setVendorCredit] = useState<any>(null);
  const [vendorInsurance, setVendorInsurance] = useState<any>(null);
  const [vendorFormW9, setVendorFormW9] = useState<any>(null);
  const [vendorContract, setVendorContract] = useState<any>(null);

  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();
  const [
    currentIntermodalRegionName,
    setCurrentIntermodalRegionName,
  ] = useState('');
  const [currentShortName, setCurrentShortName] = useState('');
  const [currentVendorDivisionName, setCurrentVendorDivisionName] = useState(
    '',
  );

  const app = useApp();

  const fetchDate = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(`vendors/${id}`);

      setCurrentIntermodalRegionName(
        get(resp, 'data.intermodal_region.name', ''),
      );

      setCurrentShortName(resp.data.short_name);
      setCurrentVendorDivisionName(get(resp, 'data.vendor_division.name', ''));

      form.setFieldsValue({
        ...resp.data,
        hazmat_range:
          resp.data.hazmat_range == null ? [] : resp.data.hazmat_range,
        overweight_cntr_size: resp.data.overweight_cntr_size
          ? resp.data.overweight_cntr_size
          : [],
      });

      handleValuesChange(resp.data, resp.data);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    const data = await form.validateFields();
    setLoading(true);
    const formValues = Object.assign({}, data);

    formValues.city_id = get(formValues, 'city.id', 0);
    formValues.vendor_division_id = get(formValues, 'vendor_division.id', 0);
    formValues.vendor_contract_id = get(formValues, 'vendor_contract.id', 0);
    formValues.vendor_credit_id = get(formValues, 'vendor_credit.id', 0);
    formValues.vendor_form_w9_id = get(formValues, 'vendor_form_w9.id', 0);
    formValues.vendor_insurance_id = get(formValues, 'vendor_insurance.id', 0);

    delete formValues.city;
    delete formValues.vendor_division;
    delete formValues.vendor_contract;
    delete formValues.vendor_credit;
    delete formValues.vendor_form_w9;
    delete formValues.vendor_insurance;

    try {
      const resp = !id
        ? await app.service.post('vendors', { data: formValues })
        : await app.service.put(`vendors/${id}`, { data: formValues });
      onSaved && onSaved(resp.data);
      onClose && onClose();
      form.resetFields();

      message.success('Saved');
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const handleClose = () => {
    onClose && onClose();
    form.resetFields();
  };

  const handleChangeTab = async (key: string) => {
    setActiveKey(key);
  };

  const checkExistCreditOrInsurance = useMemo(() => {
    const handleCheckExistCreditOrInsurance = async (truckerMC: string) => {
      if (truckerMC.length < 3) {
        return;
      }

      try {
        const resp = await app.service.get(
          'vendors/checkExistCreditOrInsurance',
          {
            params: { trucker_mc: truckerMC },
          },
        );

        const insurance = form.getFieldValue('vendor_insurance');
        const credit = form.getFieldValue('vendor_credit');
        const formW9 = form.getFieldValue('vendor_form_w9');
        const contract = form.getFieldValue('vendor_contract');

        if (!form.getFieldValue('id')) {
          let update = {};

          if (resp.data.vendor_credit) {
            update = {
              ...update,
              established_credit: true,
              vendor_credit: resp.data.vendor_credit,
              vendor_credit_id: resp.data.vendor_credit.id,
              terms: resp.data.vendor_credit.terms,
            };
          }

          if (resp.data.vendor_insurance) {
            update = {
              ...update,
              has_insurance: true,
              vendor_insurance: resp.data.vendor_insurance,
              vendor_insurance_id: resp.data.vendor_insurance.id,
            };
          }

          if (resp.data.vendor_form_w9) {
            update = {
              ...update,
              vendor_form_w9: resp.data.vendor_form_w9,
              vendor_form_w9_id: resp.data.vendor_form_w9.id,
            };
          }

          if (resp.data.vendor_contract) {
            update = {
              ...update,
              vendor_contract: resp.data.vendor_contract,
              vendor_contract_id: resp.data.vendor_contract.id,
            };
          }

          form.setFieldsValue({ ...update });
        } else {
          let open = false;
          if (
            resp.data.vendor_credit &&
            (!credit || credit.mc_number != truckerMC)
          ) {
            open = true;
            setVendorCredit(resp.data.vendor_credit);
          }
          if (
            resp.data.vendor_insurance &&
            (!insurance || insurance.mc_number != truckerMC)
          ) {
            open = true;
            setVendorInsurance(resp.data.vendor_insurance);
          }

          if (
            resp.data.vendor_form_w9 &&
            (!formW9 || formW9.mc_number != truckerMC)
          ) {
            open = true;
            setVendorFormW9(resp.data.vendor_form_w9);
          }

          if (
            resp.data.vendor_contract &&
            (!contract || contract.mc_number != truckerMC)
          ) {
            open = true;

            setVendorContract(resp.data.vendor_contract);
          }

          if (open) {
            setIsOpenConfirm(true);
          }
        }
      } catch (err: any) {
        // console.log('err', err);
        message.error(err?.data?.message || err.data?.error);
      }
    };

    return debounce(handleCheckExistCreditOrInsurance, 500);
  }, []);

  const onVerifiedUpdated = () => {
    if (!id) {
      return;
    }
    onSaved && onSaved();
  };

  const handleCloseSyncConfirm = () => {
    setIsOpenConfirm(false);
    setVendorInsurance(null);
    setVendorCredit(null);
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.possible_charges) {
      allValues.possible_charges.map((possibleCharge: any, index: number) => {
        if (possibleCharge) {
          for (const i in POSSIBLE_CHARGES_RELATED_FIELDS) {
            if (has(possibleCharge, POSSIBLE_CHARGES_RELATED_FIELDS[i])) {
              const { code } = allValues.possible_charges[index];

              if (
                ![
                  OVERWEIGHT_CODE,
                  HAZMAT_CODE,
                  FBA_DELIVERY_CODE,
                  RESIDENTIAL_CODE,
                  REEFER_CODE,
                ].includes(code)
              ) {
                return;
              }

              switch (code) {
                case OVERWEIGHT_CODE:
                  form.setFieldValue('overweight_ability', true);
                  return;
                case HAZMAT_CODE:
                  form.setFieldValue('hazmat_ability', true);
                  return;
                case FBA_DELIVERY_CODE:
                  form.setFieldValue('fba_delivery', true);
                  return;
                case RESIDENTIAL_CODE:
                  form.setFieldValue('residential_ability', true);
                  return;
                case REEFER_CODE:
                  form.setFieldValue('reefer_ability', true);
                  return;
                default:
                  return;
              }
            }
          }
        }
      });
    }

    if (changedValues.trucker_mc) {
      const insurance = get(allValues, 'vendor_insurance');
      const credit = get(allValues, 'vendor_credit');
      const formW9 = get(allValues, 'vendor_form_w9');
      const contract = get(allValues, 'vendor_contract');
      const alreadHas =
        insurance?.mc_number == changedValues.trucker_mc &&
        credit?.mc_number == changedValues.trucker_mc &&
        formW9?.mc_number == changedValues.trucker_mc &&
        contract?.mc_number == changedValues.trucker_mc;

      if (alreadHas) {
        return;
      }

      checkExistCreditOrInsurance(changedValues.trucker_mc);
    }
  };

  const items: any = useMemo(() => {
    const _items = [
      {
        label: 'Info',
        key: '0',
        children: (
          <InfoTab
            form={form}
            handleChange={handleChange}
            currentVendorDivisionName={currentVendorDivisionName}
            currentIntermodalRegionName={currentIntermodalRegionName}
            currentShortName={currentShortName}
            onVerifiedUpdated={onVerifiedUpdated}
            setCurrentVendorDivisionName={setCurrentVendorDivisionName}
            setCurrentIntermodalRegionName={setCurrentIntermodalRegionName}
            setCurrentShortName={setCurrentShortName}
          ></InfoTab>
        ),
      },
      {
        label: 'Accounting',
        key: '1',
        children: (
          <AccountingTab
            form={form}
            handleChange={handleChange}
          ></AccountingTab>
        ),
      },
      {
        label: 'Possible Charges',
        key: '2',
        children: (
          <PossibleChargesTab form={form} handleChange={handleChange} />
        ),
      },
      {
        label: 'Special Terms',
        key: '6',
        children: <SpecialTermsTab form={form} handleChange={handleChange} />,
      },
      {
        label: 'Portal Setup',
        key: '5',
        children: <PortalSetupTab form={form} handleChange={handleChange} />,
      },
    ];
    id &&
      _items.splice(
        -1,
        0,
        {
          label: 'Contacts',
          key: '3',
          children: (
            <ContactsTab
              id={id}
              form={form}
              handleChange={handleChange}
            ></ContactsTab>
          ),
        },
        {
          label: 'Documents',
          key: '4',
          children: (
            <DocumentsTab
              id={id}
              form={form}
              handleChange={handleChange}
            ></DocumentsTab>
          ),
        },
      );

    // if user is supper admin, show sync tab
    if (app.store.auth.superAdmin) {
      _items.push({
        label: 'Sync',
        key: '7',
        children: <SyncTab id={id} />,
      });
    }
    return _items;
  }, [
    id,
    currentShortName,
    currentVendorDivisionName,
    currentIntermodalRegionName,
    setCurrentIntermodalRegionName,
    setCurrentShortName,
    setCurrentVendorDivisionName,
  ]);

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchDate(id);
  }, [id]);

  return (
    <>
      <Drawer
        title=""
        placement={'right'}
        width="95%"
        onClose={handleClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          onValuesChange={handleValuesChange}
          initialValues={{
            has_insurance: true,
          }}
        >
          <Tabs type="card" items={items} onChange={handleChangeTab} />
        </Form>
      </Drawer>

      <IsSyncCreditOrInsuranceModal
        open={isOpenConfirm}
        form={form}
        vendorCredit={vendorCredit}
        vendorInsurance={vendorInsurance}
        vendorFormW9={vendorFormW9}
        vendorContract={vendorContract}
        onClose={handleCloseSyncConfirm}
      />
    </>
  );
};
