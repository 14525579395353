const CUSTOMER = 'user_id';
const OPERATOR = 'operator_id';
const SALES = 'sales_id';
const SALES_SUPPORT = 'sales_support_id';
const BUSINESS_STATUS = 'business_status';
const VENDOR = 'vendor_id';
const CARRIER = 'carrier';
const BUSINESS_DEVELOPMENT = 'business_development_id';

export default {
  CUSTOMER,
  OPERATOR,
  BUSINESS_DEVELOPMENT,
  SALES,
  SALES_SUPPORT,
  BUSINESS_STATUS,
  VENDOR,
  CARRIER,
} as const;
