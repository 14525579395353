import { Space } from 'antd';
import ColorTags from '../../components/ColorTags';
import { FTLShipmentInterface } from '../Interfaces/ShipmentInterface';
// import { CopyButton } from '@/components/CopyButton';
import InsuranceIcon from '@/components/Insurance/InsuranceIcon';

const ShipmentIdColumn: React.FC<{
  shipment: FTLShipmentInterface;
  handleShow: (record: any) => void;
  fetchData?: () => void;
}> = ({ shipment, handleShow, fetchData }) => {
  return (
    <div>
      <Space>
        <a onClick={() => handleShow(shipment)}>{shipment.uid}</a>
        {/* {<CopyButton value={shipment.uid} />} */}
        <ColorTags
          tlId={shipment.id}
          tags={shipment.tags || []}
          onApply={() => fetchData && fetchData()}
        />
      </Space>
      <div>
        <Space>
          {shipment.insurance && (
            <InsuranceIcon insurance={shipment.insurance} />
          )}
          {/* {shipment.vendor_shipment_id && (
            <>
              <span>{shipment.vendor_shipment_id}</span>
              {<CopyButton value={shipment.vendor_shipment_id} />}
            </>
          )} */}
        </Space>
      </div>
    </div>
  );
};

export default ShipmentIdColumn;
