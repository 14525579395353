import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { TRateLog, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import moment from 'moment';
import { WarehouseSelect } from '@/components/WarehouseSelect';
import AutoResizeTable from '@/components/AutoResizeTable';
import { CitySelect } from '@/components/CitySelect';
import { get, join, truncate } from 'lodash';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';

const Index = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TCollection<TRateLog>>();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = useCallback(async (pagination?: TablePaginationConfig) => {
    setLoading(true);

    try {
      const result = await app.service.get('searchRateLogs', {
        params: {
          ...filter.getFieldsValue(),
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
        },
      });

      setData(result);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 50,
      },
      {
        title: 'Intermodal Region',
        dataIndex: ['intermodal_region', 'name'],
        key: 'intermodal_region',
        width: 200,
      },
      {
        title: 'Warehouse City',
        dataIndex: ['to_city', 'full_name'],
        key: 'warehouse_city',
        width: 200,
      },
      {
        title: 'Response Rate IDs',
        dataIndex: 'response_rate_ids',
        key: 'response_rate_ids',
        width: 200,
        ellipsis: true,
        render: (text: string, record) => (
          <Tooltip
            placement="topLeft"
            title={join(get(record, 'response_rate_ids', ','))}
            arrowPointAtCenter>
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
              {truncate(join(get(record, 'response_rate_ids', ',')), {
                length: 40,
              })}
            </div>
          </Tooltip>
        ),
      },
      {
        title: 'User',
        dataIndex: ['user', 'name'],
        key: ['user', 'name'],
        width: 160,
      },

      {
        title: 'Company',
        dataIndex: ['user', 'company', 'name'],
        key: ['user', 'company', 'name'],
        width: 200,
      },
      {
        title: 'Created Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 200,
      },
    ],
    [],
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="Search Rate Log"></Header>

      <div className={styles.filter}>
        <Form
          layout="vertical"
          form={filter}
          onFinish={fetchData}
          style={{ width: '100%' }}>
          <Row gutter={8}>
            <Col span={4}>
              <Form.Item
                name="intermodal_region_id"
                label="Intermodal Region"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <IntermodalRegionSelect />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="to_city_id"
                    label="Warehouse City"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <CitySelect
                      selected={getFieldValue('to_city')}
                      onSelect={(city: any) =>
                        filter.setFieldsValue({
                          to_city_id: city.id,
                          to_city: city,
                        })
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="start_date" style={{ display: 'none' }} />
              <Form.Item name="end_date" style={{ display: 'none' }} />
              <Form.Item label="Date" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const startDate = getFieldValue('start_date');
                  const endDate = getFieldValue('end_date');
                  return (
                    <DatePicker.RangePicker
                      style={{ width: '100%' }}
                      value={
                        startDate && endDate
                          ? [moment(startDate), moment(endDate)]
                          : undefined
                      }
                      onChange={(value) =>
                        setFieldsValue({
                          start_date: value
                            ? value[0]?.format('YYYY-MM-DD')
                            : null,
                          end_date: value
                            ? value[1]?.format('YYYY-MM-DD')
                            : null,
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="user_id" className="mb0" label="User">
                <UserSyncSelect allowClear />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="company_id" className="mb0" label="Company">
                <CompanySyncSelect allowClear />
              </Form.Item>
            </Col>
            <Col flex="none">
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      filter.resetFields();
                    }}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}></Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
