import { calculateKGByLBS } from '@/components/ContainerTable/components/Weight';

export const ORDER_SALES_TYPE_COMPANY_SALE = 1;
export const ORDER_SALES_TYPE_OWN_SALE = 2;

export const ORDER_SOURCE_ADMIN = 0;
export const ORDER_SOURCE_CUSTOMER = 1;
export const ORDER_SOURCE_GOOGLESHEET_SYNC = 2;

export const ORDER_SALES_TYPE_MAP = {
  [ORDER_SALES_TYPE_COMPANY_SALE]: 'Company Sale',
  [ORDER_SALES_TYPE_OWN_SALE]: 'Own Sale',
};

export const ORDER_STATE_DRAFT = 'draft';
export const ORDER_STATE_OPEN = 'open';
export const ORDER_STATE_ON_HOLD = 'on hold';
export const ORDER_STATE_IN_REVIEW = 'in review';
export const ORDER_STATE_CONFIRMED = 'confirmed';
export const ORDER_STATE_DISPATCHED = 'dispatched';
export const ORDER_STATE_IN_PROGRESS = 'in progress';
export const ORDER_STATE_DELIVERED = 'delivered';
export const ORDER_STATE_EMPTY_RETURNED = 'empty returned';
export const ORDER_STATE_OP_CLOSED = 'op closed';
export const ORDER_STATE_AUDITING = 'auditing';
export const ORDER_STATE_ACT_CLOSED = 'act closed';
export const ORDER_STATE_ORDER_CLOSED = 'order closed';
export const ORDER_STATE_CANCELED = 'canceled';

export const ORDER_STATES = [
  ORDER_STATE_DRAFT,
  ORDER_STATE_OPEN,
  // ORDER_STATE_ON_HOLD,
  // ORDER_STATE_IN_REVIEW,
  ORDER_STATE_CONFIRMED,
  ORDER_STATE_DISPATCHED,
  ORDER_STATE_IN_PROGRESS,
  ORDER_STATE_DELIVERED,
  ORDER_STATE_EMPTY_RETURNED,
  ORDER_STATE_OP_CLOSED,
  ORDER_STATE_AUDITING,
  ORDER_STATE_ACT_CLOSED,
  ORDER_STATE_ORDER_CLOSED,
  ORDER_STATE_CANCELED,
];

export const ORDER_STATE_DESCRIPTION = {
  [ORDER_STATE_DRAFT]:
    '[Manual] The order is currently in draft and awaiting the customer submission',
  [ORDER_STATE_OPEN]: '[Auto] open order',
  // [ORDER_STATE_ON_HOLD]: '[Manual] on hold',
  // [ORDER_STATE_IN_REVIEW]: '[Manual] in review',
  [ORDER_STATE_CONFIRMED]: '[Auto] trigger when All Containers Sent',
  [ORDER_STATE_DISPATCHED]: '[Auto] trigger when All Containers DO Confirmed',
  [ORDER_STATE_IN_PROGRESS]:
    '[Auto] trigger when All Containers between PU RECEIVED and CNTR EMPTY',
  [ORDER_STATE_DELIVERED]: '[Auto] trigger when All Containers Delivered',
  [ORDER_STATE_EMPTY_RETURNED]:
    '[Auto] trigger when All Container Empty Returned',
  [ORDER_STATE_OP_CLOSED]: '[Auto] trigger when All OP closed',
  [ORDER_STATE_AUDITING]: '[Auto] trigger when All Containers AR AP Issued',
  [ORDER_STATE_ACT_CLOSED]:
    '[Auto] trigger when All Containers All AR Deposited and All AP Cleared',
  [ORDER_STATE_ORDER_CLOSED]: '[Manual] order closed',
  [ORDER_STATE_CANCELED]: '[Manual] canceled',
};

export const CONTACT_TYPES = ['APT', 'SALES', 'DISPATCH'];

export const CHASSIS_OWNER_NO_IDEA = 1;
export const CHASSIS_OWNER_SSL_CHASSIS = 2;
export const CHASSIS_OWNER_TRUCKER_CHASSIS = 3;

export const CHASSIS_OWNER_MAP = {
  [CHASSIS_OWNER_NO_IDEA]: 'No Idea',
  [CHASSIS_OWNER_SSL_CHASSIS]: 'SSL Chassis',
  [CHASSIS_OWNER_TRUCKER_CHASSIS]: 'Trucker Chassis',
};

export const WHO_WILL_PAY_TERMINAL_FEES_TRUCKER = 1;
export const WHO_WILL_PAY_TERMINAL_FEES_CUSTOMER = 2;
export const WHO_WILL_PAY_TERMINAL_FEES_DRAYEASY = 3;
export const WHO_WILL_PAY_TERMINAL_FEES_MAP = {
  [WHO_WILL_PAY_TERMINAL_FEES_TRUCKER]: 'Trucker',
  [WHO_WILL_PAY_TERMINAL_FEES_CUSTOMER]: 'Customer',
  [WHO_WILL_PAY_TERMINAL_FEES_DRAYEASY]: 'DrayEasy',
};

export const OW_TYPE_20_FEET = 20;
export const OW_TYPE_40_FEET = 40;
export const OW_TYPES = [OW_TYPE_20_FEET, OW_TYPE_40_FEET];

export const OW_20_FEET_STANDARD_LBS = 38000;
export const OW_20_FEET_REEFER_LBS = 34000;
export const OW_40_FEET_STANDARD_LBS = 44000;
export const OW_40_FEET_REEFER_LBS = 40000;

export const getOWof20FeetStandardKGByLBS = () =>
  Number(calculateKGByLBS(OW_20_FEET_STANDARD_LBS));

export const getOWof20FeetReeferKGByLBS = () =>
  Number(calculateKGByLBS(OW_20_FEET_REEFER_LBS));

export const getOWof40FeetStandardKGByLBS = () =>
  Number(calculateKGByLBS(OW_40_FEET_STANDARD_LBS));

export const getOWof40FeetReeferKGByLBS = () =>
  Number(calculateKGByLBS(OW_40_FEET_REEFER_LBS));
