export const STATUS_IN_DISPUTE = 1;
export const STATUS_CHARGES_VALID = 2;
export const STATUS_REQUESTED = 3;
export const STATUS_ACCEPTED = 4;
export const STATUS_INVOICE_RECEIVED = 5;
export const STATUS_CLOSE = 6;

export const STATUS_MAP = {
  [STATUS_IN_DISPUTE]: 'In dispute',
  [STATUS_CHARGES_VALID]: 'Charges valid',
  [STATUS_REQUESTED]: 'Dispute requested',
  [STATUS_ACCEPTED]: 'Dispute accepted',
  [STATUS_INVOICE_RECEIVED]: 'Invoice received',
  [STATUS_CLOSE]: 'Dispute closed',
};

export enum BILLABLE_TYPE {
  ORDER = 'App\\Models\\Order',
  LTL = 'App\\Domains\\TL\\Models\\LTLShipment',
  FTL = 'App\\Domains\\FTL\\Models\\FTLShipment',
}

export const BILL_TYPE_ORDER = BILLABLE_TYPE.ORDER;
export const BILL_TYPE_LTL = BILLABLE_TYPE.LTL;
export const BILL_TYPE_FTL = BILLABLE_TYPE.FTL;
