import {
  DimensionUnit,
  imperialFreightClass,
  QuoteItem,
  WeightUnit,
} from '@/utils/freight';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';

export interface AggregatorProps {
  items: QuoteItem[];
}

const Aggregator = ({ items }: AggregatorProps) => {
  const totalUnits = _.sum(items?.map((item: QuoteItem) => item?.units));

  const totalImperialWeight = _.sum(
    items?.map((item: QuoteItem) => {
      if (item?.weight_unit === WeightUnit.KG) {
        return item?.total_weight * 2.20462;
      }
      return item?.total_weight;
    }),
  );
  const totalMetricWeight = totalImperialWeight / 2.20462;

  const totalImperialVolume = _.sum(
    items?.map((item: QuoteItem) => {
      if (item?.dimension_unit === DimensionUnit.CM) {
        return (
          ((item?.length * item?.width * item?.height * item?.units) /
            1000000) *
          35.315
        );
      }
      return (item?.length * item?.width * item?.height * item?.units) / 1728;
    }),
  );
  const totalMetricVolume = totalImperialVolume * 0.0283168;

  const totalCartonQty = _.sum(items?.map((item: QuoteItem) => item?.pieces));

  const totalDensity =
    totalImperialVolume === 0 ? 0 : totalImperialWeight / totalImperialVolume;

  const classId = imperialFreightClass(
    totalImperialWeight,
    totalImperialVolume,
  );

  return (
    <>
      <Row>
        <Col span={8}>
          <Text>Handling Unit Count {totalUnits}</Text>
        </Col>
        <Col span={8}>
          <Text>
            Weight {totalImperialWeight?.toFixed(2) ?? '-'} lbs /{' '}
            {totalMetricWeight?.toFixed(2) ?? '-'} kg
          </Text>
        </Col>
        <Col span={8}>
          <Text>
            Volume {totalImperialVolume?.toFixed(2) ?? '-'} ft<sup>3</sup> /{' '}
            {totalMetricVolume?.toFixed(2) ?? '-'} m<sup>3</sup>
          </Text>
        </Col>

        <Col span={8}>
          <Text>Total carton Qty {totalCartonQty ?? '-'}</Text>
        </Col>
        <Col span={8}>Density {totalDensity?.toFixed(2) ?? '-'} PCF</Col>
        <Col span={8}>Class ID {classId ?? '-'}</Col>
      </Row>
    </>
  );
};

export default Aggregator;
