import { useCallback } from 'react';
import type { DocumentType, InvoiceType } from '../interface/types';
import { useApp } from '@/utils/useapp';
import { generateAuthData } from '@/utils/drayaiAuth';

interface ClassifyResult {
  file_type: DocumentType;
  invoice_type?: InvoiceType;
}

export function useDocumentClassify() {
  const app = useApp();

  const getBase64 = useCallback(
    (file: File, isShow: boolean): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () =>
          resolve(
            isShow
              ? (reader.result as string)
              : (reader.result as string).split(',')[1],
          );
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    [],
  );

  const classifyDocument = async (file: File): Promise<ClassifyResult> => {
    const base64Content = await getBase64(file, false);

    const result = await app.service.api(
      `${DRAYAI_BASE_URL}/ai/file/classify`,
      {
        method: 'post',
        data: {
          file: { filename: file.name, content: base64Content },
          ...generateAuthData(''),
        },
      },
    );

    return {
      file_type: result.file_type as DocumentType,
      invoice_type:
        result.file_type === 'invoice'
          ? (result.invoice_type?.toUpperCase() as InvoiceType)
          : undefined,
    };
  };

  return {
    classifyDocument,
    getBase64,
  };
}
