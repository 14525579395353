import { Space, Typography } from 'antd';
import ColorTags from '../../components/ColorTags';
import { ShipmentInterface } from '../Interfaces/ShipmentInterface';
import { CopyButton } from '@/components/CopyButton';
import InsuranceIcon from '@/components/Insurance/InsuranceIcon';
import TroubleStatusIcon from '@/components/TroubleStatuses/TroubleStatusIcon';
import { useApp } from '@/utils/useapp';

const ShipmentIdColumn: React.FC<{
  shipment: ShipmentInterface;
  handleShow: (record: any) => void;
  fetchData?: () => void;
}> = ({ shipment, handleShow, fetchData }) => {
  const app = useApp();

  const handleVendorShipmentClick = async () => {
    if (!shipment.vendor_id || !shipment.vendor_shipment_id) {
      return;
    }

    try {
      const resp = await app.service.get(
        `tl/ltl/vendors/${shipment.vendor_id}`,
      );
      const vendor = resp.data;
      if (!vendor.quick_link) {
        return;
      }

      const url =
        vendor.quick_link.includes('$') && vendor.use_shipment_id
          ? vendor.quick_link.replace('$', shipment.vendor_shipment_id)
          : vendor.quick_link;

      window.open(url, '_blank');
    } catch (e) {
      console.error('Failed to open vendor tracking:', e);
    }
  };

  return (
    <div>
      <Space>
        <a onClick={() => handleShow(shipment)}>{shipment.uid}</a>
        {<CopyButton value={shipment.uid} />}
        <ColorTags
          tlId={shipment.id}
          tags={shipment.tags || []}
          onApply={() => fetchData && fetchData()}
        />
        <TroubleStatusIcon targetModel={shipment} />
      </Space>
      <div>
        <Space>
          {shipment.insurance && (
            <InsuranceIcon insurance={shipment.insurance} />
          )}
          {shipment.vendor_shipment_id && (
            <>
              <Typography.Link onClick={handleVendorShipmentClick}>
                {shipment.vendor_shipment_id}
              </Typography.Link>
              {<CopyButton value={shipment.vendor_shipment_id} />}
            </>
          )}
        </Space>
      </div>
    </div>
  );
};

export default ShipmentIdColumn;
