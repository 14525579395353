import React, { ReactElement, useEffect, useState } from 'react';
import { Space, Tooltip, Divider, Button, Popover } from 'antd';
import { useApp } from '@/utils/useapp';
import { TAction } from '../Interfaces/ActionInterface';

import useAction from './useAction';
import {
  OnDoneInterface,
  onSaveOperationInterface,
} from '../Interfaces/TaskInterface';

export const ConfirmTimeBasedUpdate: React.FC<
{
  toSave: () => void;
  updatingData: any;
} & TAction &
OnDoneInterface &
onSaveOperationInterface
> = ({ updatingData, target, targetModel, task, onDone, toSave, ...props }) => {
  const app = useApp();

  const { checkTimeValid } = useAction({ target, targetModel });
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [errors, setErrors] = useState<Array<string>>([]);

  const checkValidUpdatingData = async () => {
    if (!targetModel?.id) {
      return;
    }

    const errors = await checkTimeValid(updatingData);
    if (errors) {
      setErrors(errors);
      setIsOpenConfirm(true);
    } else {
      toSave();
    }
  };

  const handleCancel = () => {
    init();
  };

  const init = () => {
    setErrors([]);
    setIsOpenConfirm(false);
  };

  useEffect(() => {
    if (updatingData && Object.keys(updatingData).length > 0) {
      checkValidUpdatingData();
    }
  }, [updatingData]);

  return (
    <>
      {props.children}
      {isOpenConfirm && (
        <Popover
          placement="topRight"
          open={isOpenConfirm}
          content={
            <div style={{ width: '400px' }}>
              {errors.map((e, i) => (
                <div key={i}>{e}</div>
              ))}
              Are your sure to update?
              <Divider />
              <div className="flex justify-end">
                <Button onClick={handleCancel} size="small">
                  Cancel
                </Button>
                <Button type="primary" onClick={toSave} size="small">
                  Confirm
                </Button>
              </div>
            </div>
          }
        >
          <></>
        </Popover>
      )}
    </>
  );
};
