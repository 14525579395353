import Auth from '@/utils/auth';
import { useApp, withApp } from '@/utils/useapp';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Dropdown,
  Image,
  MenuProps,
  Radio,
  Space,
  message,
} from 'antd';
import { Link, Switch, useLocation } from 'umi';
import menuitems, { NAVBAR, TMenuItem } from '@/menuitems';
import { history } from 'umi';
import { Layout, Menu } from 'antd';
import { MessageOutlined, SettingOutlined } from '@ant-design/icons';
import { HeaderSearch } from './HeaderSearch';
import Notification from './Notification';

const { Header, Content, Sider } = Layout;

type SettingMenusItem = {
  key: string;
  title: string;
  link: string;
  permissions?: string;
};

const SideMenu = ({ menuType = 0, activeKey = NAVBAR[0] }) => {
  const app = useApp();

  const menu: MenuProps['items'] | any = useMemo(() => {
    const _menu = menuitems[activeKey].map((item: TMenuItem, index) => {
      if (
        item.permissions &&
        false === app.store.auth.access(item.permissions, 'or')
      ) {
        return false;
      }

      const key = item.key ? item.key : String(index);
      if (item.items) {
        return {
          key: key,
          icon: item.icon,
          label: item.title,
          url: item.url,
          children: item.items.map((subItem: TMenuItem, subIndex) => {
            if (
              subItem.permissions &&
              false === app.store.auth.access(subItem.permissions)
            ) {
              return false;
            }

            const subKey = subItem.key ? subItem.key : `${key}-${subIndex}`;
            return {
              key: subKey,
              icon: subItem.icon ? subItem.icon : '',
              label: subItem.title,
              url: subItem.url,
            };
          }),
        };
      } else {
        return {
          key: key,
          icon: item.icon,
          label: item.title,
          url: item.url,
        };
      }
    });
    return _menu;
  }, [activeKey]);

  const findSelectedKey = () => {
    const path = globalThis.location.pathname;

    for (const item of menu) {
      if (item?.url && item?.url == path) {
        return { key: item.key, open: null };
      } else if (item.children) {
        for (const subitem of item.children) {
          if (subitem?.url && subitem?.url == path) {
            return { key: subitem.key, open: [item?.key as string] };
          }
        }
      }
    }
    return {
      key: '-1',
      open: [],
    };
  };

  const [selectedKey, setSelectedKey] = useState(findSelectedKey());

  React.useEffect(() => {
    const k = findSelectedKey();
    if (selectedKey.open) {
      k.open = selectedKey.open;
    }
    setSelectedKey(k);
  }, [globalThis.location.pathname, activeKey]);

  const updateKey = (openKeys: string[]) => {
    setSelectedKey({
      key: openKeys[0],
      open: openKeys,
    });
  };

  const showPage: ({ key }: any) => void = ({ key }) => {
    if (!key.includes('-')) {
      history.push(menuitems[activeKey][key].url);
    } else {
      const [_key, subKey] = key.split('-');
      history.push(menuitems[activeKey][_key]?.items[subKey].url || '/');
    }
  };
  return (
    <Sider collapsible width={200} className="site-layout-background">
      <Menu
        selectedKeys={[selectedKey.key]}
        openKeys={selectedKey.open ? selectedKey.open : []}
        onSelect={showPage}
        mode="inline"
        onOpenChange={updateKey}
        style={{ height: '100%', borderRight: 0, overflow: 'auto' }}
        items={menu}
      />
    </Sider>
  );
};

const HeaderMenu: FC<{
  activeKey: string;
  onNavBarChange: (key: string) => void;
}> = ({ onNavBarChange, activeKey }) => {
  const app = useApp();

  const handleLogout = async () => {
    try {
      await app.service.get('logout');
      app.store.auth.logout();
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const style = {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logo: {
      flex: '0 0 auto',
      color: 'white',
      fontWeight: 'border',
      margin: '0',
    },
    navbar: {
      flex: '1 1 auto',
      marginLeft: '24px',
    },
    menu: {
      display: 'flex',
      justifyContent: 'center',
    },
    menuItem: {
      color: 'white',
      margin: '0 15px',
    },
    searchInput: {
      position: 'relative',
      top: '1.5em',
      color: 'white',
      fontWeight: 'border',
    },
  };

  const menus = React.useMemo(() => {
    const settingMenuItems: Array<SettingMenusItem> = [
      {
        key: '1',
        title: 'Configurations',
        link: '/setting/configurations',
        permissions: 'manage.setting',
      },
      {
        key: '2',
        title: 'Plans',
        link: '/setting/plans',
        permissions: 'read.plan',
      },
      {
        key: '3',
        title: 'User',
        link: '/setting/users',
        permissions: 'read.user',
      },
      {
        key: '4',
        title: 'Consignee',
        link: '/setting/consignees',
        permissions: 'read.consignee',
      },
      {
        key: '5',
        title: 'Admin',
        link: '/setting/admin',
        permissions: 'manage.admin',
      },
      {
        key: '7',
        title: 'SmartTracking',
        link: '/setting/smartTracking',
        permissions: 'manage.tracking',
      },
      {
        key: '8',
        title: 'EmailTemplate',
        link: '/setting/emailTemplate',
        permissions: 'read.emailTemplate',
      },
      {
        key: '9',
        title: 'Company',
        link: '/setting/companies',
        permissions: 'read.user',
      },
      {
        key: '10',
        title: 'Role Permission',
        link: '/user/role',
        permissions: 'manage.core',
      },
      {
        key: '11',
        title: 'Quesionnaire',
        link: '/questionnaires',
        permissions: 'manage.core',
      },
      {
        key: '12',
        title: 'Phpinfo',
        link: '/setting/phpinfo',
        permissions: 'manage.core',
      },
      {
        key: '13',
        title: 'Holidays',
        link: '/setting/holidays',
        permissions: 'manage.core',
      },
    ];
    const menus: MenuProps['items'] | any = settingMenuItems
      .filter(
        (item: SettingMenusItem) =>
          !item.permissions || true === app.store.auth.access(item.permissions),
      )
      .map((item: SettingMenusItem) => {
        return {
          key: item.key,
          label: <Link to={item.link}>{item.title}</Link>,
        };
      });

    return menus;
  }, []);

  const userMenu: MenuProps['items'] = [
    {
      key: '1',
      label: 'Log out',
      onClick: handleLogout,
    },
    {
      key: '2',
      label: <Link to="/setting/2fa">2FA</Link>,
    },
  ];

  const navbarItems: MenuProps['items'] = NAVBAR.map((key) => ({
    key,
    label: `${key}`,
  }));

  return (
    <div style={style.root}>
      <h1 style={style.logo}>DrayEasy</h1>
      <Menu
        style={style.navbar}
        selectedKeys={[activeKey]}
        onClick={({ key }) => onNavBarChange(key)}
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={['Dray']}
        items={navbarItems}
      />
      <div style={style.menu}>
        <div style={style.searchInput}>
          <HeaderSearch />
        </div>
        <div style={style.menuItem}>
          <Notification />
        </div>
        <div style={style.menuItem}>
          <MessageOutlined />
        </div>
        {menus && menus.length > 0 && (
          <div style={style.menuItem}>
            <Dropdown menu={{ items: menus }} placement="bottomLeft">
              <SettingOutlined />
            </Dropdown>
          </div>
        )}
        <div style={style.menuItem}>
          {/* <Avatar src={<Image src="https://joeschmoe.io/api/v1/random" />} /> */}
          <Dropdown
            menu={{ items: userMenu }}
            placement="bottomLeft"
            className="ml-sm"
          >
            <a color="white">{app.store.auth.account?.name}</a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const UILayout: FC = (props) => {
  const [activeKey, setActiveKey] = useState(NAVBAR[0]);

  useEffect(() => {
    const path = globalThis.location.pathname;

    for (const key of Object.keys(menuitems)) {
      if (menuitems[key].find((item: TMenuItem) => item.url == path)) {
        setActiveKey(key);
      }
    }
  }, [globalThis.location.pathname]);

  const handleNavBarChange = (key: string) => {
    setActiveKey(key);
    menuitems[key][0].url && history.push(menuitems[key][0].url);
  };

  return (
    <Auth>
      <Layout
        style={{
          height: '100vh',
        }}
      >
        <Header className="header">
          <HeaderMenu
            activeKey={activeKey}
            onNavBarChange={handleNavBarChange}
          />
        </Header>
        <Layout>
          <SideMenu activeKey={activeKey} />
          <Layout style={{ padding: '0 24px 24px' }}>
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                marginTop: 24,
                minHeight: 280,
              }}
            >
              <Switch children={props.children} />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Auth>
  );
};

export default withApp(UILayout);
