import ClickWrapper from '@/components/ClickWrapper';
import React from 'react';
import { BillDrawer } from './BillDrawer';

interface Props {
  billableId: number;
  billableType: string;
  container?: any;
  children: React.ReactNode;
  onSaved?: () => void;
  initialValues?: {
    invoice_number?: string;
    invoice_date?: string;
    due_date?: string;
    bill_from?: string;
    items?: Array<{
      name: string;
      description?: string;
      rate: number;
      quantity: number;
      amount: number;
    }>;
    new_files?: Array<{
      name: string;
      content: string | null;
      type: string;
    }>;
  };
}

const NewBill: React.FC<Props> = ({
  billableId,
  billableType,
  container,
  children,
  onSaved,
  initialValues,
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleClick = () => {
    setVisible(true);
  };

  return (
    <>
      <ClickWrapper onClick={handleClick}>{children}</ClickWrapper>
      {visible && (
        <BillDrawer
          id={0}
          billableId={billableId}
          billableType={billableType}
          container={container}
          visible={visible}
          onClose={() => setVisible(false)}
          onSaved={onSaved}
          initialValues={initialValues}
        />
      )}
    </>
  );
};

export default NewBill;
