import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import DocumentUpload from './components/DocumentUpload';
import FilePreview from './components/FilePreview';
import RecognitionResults from './components/RecognitionResults';
import { useRecognition } from '@/pages/accounting/bill-ai-import/hooks/useRecognition';
import { FileStatus } from './interface/types';
import './styles.css';
import { useDocumentClassify } from './hooks/useDocumentClassify';
import { useShipmentData } from './hooks/useShipmentData';
import { TLType } from '@/components/constants';

interface FileInfo {
  url: string;
  type: string;
  content?: string;
}

const Index = () => {
  const [, setFileList] = useState<UploadFile[]>([]);
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
  const [previewFiles, setPreviewFiles] = useState<Record<string, FileInfo>>(
    {},
  );

  const { classifyDocument } = useDocumentClassify();
  const {
    recognitionResults,
    startRecognition,
    updateRecognitionResult,
  } = useRecognition();

  const selectedFile: FileStatus | null = selectedFileId
    ? fileStatuses.find((f) => f.fileId === selectedFileId) || null
    : null;

  const referenceNumber =
    selectedFileId && recognitionResults[selectedFileId]
      ? selectedFile?.invoiceType === TLType.LTL.toUpperCase() ||
        selectedFile?.invoiceType === TLType.FTL.toUpperCase()
        ? recognitionResults[selectedFileId].reference_number
        : recognitionResults[selectedFileId].container_number
      : undefined;

  const shipmentData = useShipmentData(
    selectedFile?.invoiceType,
    referenceNumber,
  );

  const handleUpload = async (file: File) => {
    const fileId = Date.now().toString();
    const previewUrl = URL.createObjectURL(file);

    // 读取文件内容
    const fileContent = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });

    setPreviewFiles((prev) => ({
      ...prev,
      [fileId]: {
        url: previewUrl,
        type: file.type,
        content: fileContent as string,
      },
    }));

    const newFileStatus: FileStatus = {
      fileId,
      fileName: file.name,
      uploadTime: new Date().toLocaleString(),
      status: 'processing',
    };

    setFileStatuses((prev) => [...prev, newFileStatus]);
    setSelectedFileId(fileId);

    try {
      const {
        file_type: documentType,
        invoice_type: invoiceType,
      } = await classifyDocument(file);

      setFileStatuses((prev) =>
        prev.map((fs) =>
          fs.fileId === fileId
            ? {
                ...fs,
                documentType,
                invoiceType,
                status: documentType === 'invoice' ? 'processing' : 'completed',
              }
            : fs,
        ),
      );

      if (documentType == 'invoice' && invoiceType) {
        const result = await startRecognition(file, fileId, invoiceType);
        setFileStatuses((prev) =>
          prev.map((fs) =>
            fs.fileId === fileId
              ? {
                  ...fs,
                  status: 'completed',
                  result: result,
                }
              : fs,
          ),
        );
      }
    } catch (error) {
      setFileStatuses((prev) =>
        prev.map((fs) =>
          fs.fileId === fileId
            ? {
                ...fs,
                status: 'error',
                errorMessage: (error as Error)?.message ?? 'Unknown error',
              }
            : fs,
        ),
      );
    }

    return false;
  };

  const handleFileDelete = (fileId: string) => {
    setPreviewFiles((prev) => {
      const newFiles = { ...prev };
      URL.revokeObjectURL(newFiles[fileId].url);
      delete newFiles[fileId];
      return newFiles;
    });

    setFileStatuses((prev) => prev.filter((fs) => fs.fileId !== fileId));

    if (selectedFileId === fileId) {
      const remainingFiles = fileStatuses.filter((fs) => fs.fileId !== fileId);
      setSelectedFileId(
        remainingFiles.length > 0 ? remainingFiles[0].fileId : null,
      );
    }

    setFileList((prev) => prev.filter((file) => file.uid !== fileId));
  };

  const handleResultChange = (
    fileId: string,
    key: string,
    value: string | any[] | null,
  ) => {
    updateRecognitionResult(fileId, key, value);
  };

  const updateFileStatus = (fileId: string) => {
    setFileStatuses((prev) =>
      prev.map((fs) =>
        fs.fileId === fileId
          ? {
              ...fs,
              status: 'completed',
              billCreated: true,
            }
          : fs,
      ),
    );
  };

  useEffect(() => {
    return () => {
      Object.values(previewFiles).forEach((file) => {
        URL.revokeObjectURL(file.url);
      });
    };
  }, []);

  return (
    <div className="bill-import-container">
      <Row gutter={12}>
        <Col span={5}>
          <DocumentUpload
            fileStatuses={fileStatuses}
            selectedFileId={selectedFileId}
            onUpload={handleUpload}
            onFileSelect={setSelectedFileId}
            onFileDelete={handleFileDelete}
          />
        </Col>
        <Col span={11}>
          <FilePreview
            file={
              selectedFileId
                ? fileStatuses.find((f) => f.fileId === selectedFileId) || null
                : null
            }
            previewUrl={
              selectedFileId ? previewFiles[selectedFileId]?.url : undefined
            }
          />
        </Col>
        <Col span={8}>
          <RecognitionResults
            result={selectedFileId ? recognitionResults[selectedFileId] : null}
            onResultChange={(key, value) =>
              selectedFileId && handleResultChange(selectedFileId, key, value)
            }
            file={selectedFile}
            shipmentData={shipmentData}
            fileContent={
              selectedFileId ? previewFiles[selectedFileId]?.content : undefined
            }
            onBillCreated={() =>
              selectedFileId && updateFileStatus(selectedFileId)
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default Index;
