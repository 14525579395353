import { ShipmentInterface } from '@/pages/truckload/ltl/shipments/Interfaces/ShipmentInterface';
import { ContainerInterface } from './ContainerInterface';
import {
  OnDoneInterface,
  TaskInterface,
  onSaveOperationInterface,
} from './TaskInterface';
import { FTLShipmentInterface } from '@/pages/truckload/ftl/shipments/Interfaces/ShipmentInterface';

export const TARGET_TYPE_LTL_SHIPMENT = 'ltl_shipment';
export const TARGET_TYPE_FTL_SHIPMENT = 'ftl_shipment';
export const TARGET_TYPE_LTL_CONTAINERS = 'containers';

export const DOC_TARGET_MAP: {
  [TARGET_TYPE_LTL_SHIPMENT]: string;
  [TARGET_TYPE_FTL_SHIPMENT]: string;
} = {
  [TARGET_TYPE_LTL_SHIPMENT]: 'ltl_doc',
  [TARGET_TYPE_FTL_SHIPMENT]: 'ftl_doc',
};

export type TLtlShipmentAction = TAction & onSaveOperationInterface;

export type TFtlShipmentAction = TAction & onSaveOperationInterface;

export type TShipmentAction = TAction & onSaveOperationInterface;

export type TTarget = {
  target: 'ltl_shipment' | 'ftl_shipment' | 'containers'; // |
  targetModel: ShipmentInterface | FTLShipmentInterface | ContainerInterface;
};

export type TSendEmailModal = {
  open: boolean;
  admin: any;
  onClose: () => void;
};

export type TAction = {
  task: TaskInterface;
} & TTarget &
  OnDoneInterface;

const DONE = 'DONE';
const INPUT_SR = 'Input SR';
const INPUT_DATE = 'Input Date';
const INPUT = 'Input';
const YES = 'YES';
const NO = 'NO';
const UPLOAD = 'UPLOAD';
const RESCHEDULE = 'Reschedule';
const SEND_POD = 'SEND POD';
const SEND_BOL = 'SEND BOL';
const UNDO = 'UNDO';

const ACTION_COLORS = {
  YES: 'green',
  NO: 'red',
};

const ALWAY_SHOW_ACTIONS = [INPUT_SR, INPUT_DATE, INPUT, RESCHEDULE];

export default {
  DONE,
  INPUT_SR,
  INPUT_DATE,
  INPUT,
  YES,
  NO,
  UPLOAD,
  RESCHEDULE,
  SEND_POD,
  SEND_BOL,
  UNDO,
  ACTION_COLORS,
  ALWAY_SHOW_ACTIONS,
} as const;
