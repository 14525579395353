import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Space,
  Table,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import {
  TStatisticsInternalRegion,
  TStatisticsInternalRegionArrayItem,
} from '@/types';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import moment from 'moment';
import { PortSelect } from '@/components/PortSelect';
import { WarehouseSelect } from '@/components/WarehouseSelect';
import { CitySelect } from '@/components/CitySelect';
import AutoResizeTable from '@/components/AutoResizeTable';

const Index = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TStatisticsInternalRegionArrayItem[]>();
  const [filter] = useForm();

  const generateStatisticsInternalRegionArray = (
    data: TStatisticsInternalRegion,
  ): TStatisticsInternalRegionArrayItem[] => {
    return Object.entries(data).map(([k, v]) => {
      return {
        name: k,
        ...v,
      };
    });
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const result = await app.service.get('statistics/intermodalRegions', {
        params: {
          ...filter.getFieldsValue(),
        },
      });

      setData(generateStatisticsInternalRegionArray(result?.data));
    } catch (e: any) {
      message.error(e.error || 'network error');
    }

    setLoading(false);
  }, []);

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: 'Total Distance',
        dataIndex: 'total_distance',
        key: 'total_distance',
        width: 200,
      },
      {
        title: 'Total Truck Rate',
        dataIndex: 'total_truck_rate',
        key: 'total_truck_rate',
        width: 200,
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        width: 200,
      },
      {
        title: 'Average Distance',
        dataIndex: 'average_distance',
        key: 'average_distance',
        width: 200,
      },
      {
        title: 'Truck Rate Per Mile',
        dataIndex: 'truck_rate_per_mile',
        key: 'truck_rate_per_mile',
        width: 200,
      },
    ],
    [],
  );

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await filter.setFieldsValue({ [name]: value });
  };

  return (
    <div className={styles.main}>
      <Header title="Statistics Intermodal Regions"></Header>

      <div className={styles.filter}>
        <Form
          layout="vertical"
          form={filter}
          onFinish={fetchData}
          style={{ width: '100%' }}>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="intermodal_region_id"
                    label="Port of discharge / Rail destination"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <IntermodalRegionSelect
                      onSelect={(id: string) =>
                        handleChange({
                          target: { name: 'intermodal_region_id', value: id },
                        })
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="to_city_id"
                    label="Warehouse City"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <CitySelect
                      selected={getFieldValue('to_city_id')}
                      onSelect={(id: string) =>
                        handleChange({
                          target: { name: 'to_city_id', value: id },
                        })
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="from_facility_type"
                    label="From Facility Type"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <FacilityTypeSelect
                      onSelect={(id: string) =>
                        handleChange({
                          target: { name: 'from_facility_type', value: id },
                        })
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="to_facility_type"
                    label="To Facility Type"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <FacilityTypeSelect
                      onSelect={(id: string) =>
                        handleChange({
                          target: { name: 'to_facility_type', value: id },
                        })
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} align="middle">
            <Col span={6} flex="1 1 auto">
              <Form.Item
                name="distance_start"
                label="Distance between IR and City"
                wrapperCol={{ span: 24 }}>
                <InputNumber style={{ width: '100%' }} controls />
              </Form.Item>
            </Col>
            {'->'}
            <Col span={6}>
              <Form.Item
                name="distance_end"
                label=" "
                wrapperCol={{ span: 24 }}>
                <InputNumber style={{ width: '100%' }} controls />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label=" ">
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      filter.resetFields();
                    }}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={false}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data ?? []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
