import { FC, Key, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { Quote } from '../quotes';
import { history } from 'umi';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import ShipmentDrawer from './ShipmentDrawer';
import { QuoteBusinessStatus } from '../constants';

import { Gap } from '@/components/Gap';
import { RateTable } from './RateTable';
import { TCity } from '@/types';

const { Text } = Typography;

const UNKNOW_STATUS = '-/-';
const FAST_INTERVAL = 1000;
const SLOW_INTERVAL = 3000;

interface Props {
  model?: Quote;
  onCreated: () => void;
  onClose: () => void;
}

interface UpdatedRate {
  rateId: number;
  newRate: number;
}

export interface Rate {
  id: number;
  quote_rate: number;
  rate: number;
  quote_id: number;
  vendor_rate_id: string;
  transit_days: number;
  expiration_date: string;
  estimated_pickup_date: string;
  estimated_delivery_date: string;
  services: { name: string; description: string }[];
  errors: string[];
  selected: boolean;
  vendor: {
    id: number;
    name: string;
    key: string;
  };
}

export interface QuoteDetail {
  id: number;
  pickup_date: string;
  pickup_zipcode: string;
  destination_zipcode: string;
}

export interface ShipmentParams {
  rate: Rate;
  quoteFields: any;
}

const QuoteForm: FC<Props> = ({ model, onCreated, onClose }) => {
  const app = useApp();
  const [form] = useForm();

  const quoteIdRef = useRef(model?.id);
  const timerIdRef = useRef<NodeJS.Timer | null>();

  const [quoteChanged, setQuoteChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ratesLoading, setRatesLoading] = useState(false);
  const [shipformVisible, setShipformVisible] = useState(false);
  const [selectedRates, setSelectedRates] = useState<Key[]>([]);
  const [updatedRates, setUpdatedRates] = useState<UpdatedRate[]>([]);
  const [status, setStatus] = useState<string>(UNKNOW_STATUS);
  const [rates, setRates] = useState<Rate[]>();
  const [quoteErrors, setQuoteErrors] = useState<string[]>();
  const [rateInterval, setRateInterval] = useState<number>(FAST_INTERVAL);

  const [shipParams, setShipParams] = useState<ShipmentParams>();
  const [quote, setQuote] = useState<Quote>();
  const [quoteValidHours] = useState<number>(24);

  const [pickupCityList, setPickupCityList] = useState<TCity[]>([]);
  const [destinationCityList, setDestinationCityList] = useState<TCity[]>([]);

  const [holidays, setHolidays] = useState<string[]>();
  const [holidaysLoading, setHolidaysLoading] = useState<boolean>(false);

  const disableChange = quote
    ? quote.business_status >= QuoteBusinessStatus.SUBMITED
    : false;

  const fectHolidays = async () => {
    setHolidaysLoading(true);
    try {
      const resp = await app.service.get('tools/holidays-from-now');
      setHolidays(resp.data);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    } finally {
      setHolidaysLoading(false);
    }
  };

  const checkFormData = () => {
    if (
      !pickupCityList
        .map((item) => item.id)
        .includes(form.getFieldValue('pickup_city_id'))
    ) {
      message.error(
        'Pickup city is not match with origin zipcode.Please reselect the pickup city.',
      );
      return false;
    }

    if (
      !destinationCityList
        .map((item) => item.id)
        .includes(form.getFieldValue('destination_city_id'))
    ) {
      message.error(
        'Destination city is not match with destination zipcode.Please reselect the destination city.',
      );
      return false;
    }
    return true;
  };

  const fetchQuote = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`ftl/quotes/${quoteIdRef.current}`);
      // console.log(resp.data);
      form.setFieldsValue({
        ...resp.data,
        pickup_accessorials: resp.data.pickup_accessorials ?? [],
        destination_accessorials: resp.data.destination_accessorials ?? [],
      });

      setStatus(resp.data.status);
      setQuote(resp.data);
      setQuoteErrors(resp.data.errors);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const fetchPickupCityList = async () => {
    try {
      const resp = await app.service.get(
        `tl/tools/citylist/${form.getFieldValue('pickup_zipcode')}`,
      );
      if (resp?.data) {
        setPickupCityList(resp.data);
      }
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const fetchDestinationCityList = async () => {
    try {
      const resp = await app.service.get(
        `tl/tools/citylist/${form.getFieldValue('destination_zipcode')}`,
      );
      if (resp?.data) {
        setDestinationCityList(resp.data);
      }
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const done = () => {
    if (status === UNKNOW_STATUS) {
      return -1;
    }
    return parseInt(status.split('/')[0]);
  };

  const total = () => {
    if (status === UNKNOW_STATUS) {
      return -1;
    }
    return parseInt(status.split('/')[1]);
  };

  const fetchRates = async () => {
    try {
      const resp = await app.service.get(
        `ftl/quotes/${quoteIdRef.current}/rates`,
      );

      setRates(resp.data.rates);
      if (resp.data.rates.length > 0) {
        setRateInterval(SLOW_INTERVAL);
      }
      setStatus(resp.data.quoteStatus);
      setQuoteErrors(resp.data.errors);
      return resp;
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const intervalFetchRates = async () => {
    setRatesLoading(true);
    try {
      await fetchRates();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const stopPolling = () => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
    }
  };

  const startPolling = () => {
    if (!timerIdRef.current) {
      timerIdRef.current = setInterval(intervalFetchRates, rateInterval);
    }
  };

  const restartPolling = () => {
    stopPolling();
    startPolling();
  };

  const resetStatus = () => {
    setStatus(UNKNOW_STATUS);
  };

  const handleStartGetRates = async () => {
    let values = null;
    try {
      values = await form.validateFields();
    } catch (error) {
      return;
    }

    const result = checkFormData();
    if (!result) {
      return;
    }

    setRatesLoading(true);
    resetStatus();
    setRateInterval(FAST_INTERVAL);

    const data = {
      ...values,
      equipment_type: 'DRV',
    };
    let resp = null;
    try {
      if (quoteIdRef.current) {
        resp = await app.service.put(
          `ftl/quotes/${quoteIdRef.current}/update-with-quote`,
          {
            data,
          },
        );
      } else {
        resp = await app.service.post('ftl/quotes', {
          data,
        });
      }
      quoteIdRef.current = resp.data.id;
      onCreated && onCreated();
      startPolling();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
      setRatesLoading(false);
    }
  };

  const handelSave = async (values: any) => {
    const result = checkFormData();
    if (!result) {
      return;
    }
    let resp = null;
    const data = {
      ...values,
      selectedRates,
      updatedRates,
    };
    try {
      if (quoteIdRef.current) {
        resp = await app.service.put(`ftl/quotes/${quoteIdRef.current}`, {
          data,
        });
      } else {
        resp = await app.service.post('ftl/quotes', {
          data,
        });
      }

      quoteIdRef.current = resp.data.id;
      message.success('Saved');
      onCreated && onCreated();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const onFormValueChange = (changedValues: any) => {
    setQuoteChanged(true);

    if (changedValues.pickup_zipcode) {
      form.setFieldValue('pickup_city_id', null);
      fetchPickupCityList();
    }

    if (changedValues.destination_zipcode) {
      form.setFieldValue('destination_city_id', null);
      fetchDestinationCityList();
    }
  };

  const handleSelect = async (record: Rate) => {
    const quoteFields = await form.validateFields();
    setShipParams({
      rate: record,
      quoteFields: {
        ...quoteFields,
      },
    });
    setShipformVisible(true);
  };

  useEffect(() => {
    if (quoteIdRef.current && model) {
      form.resetFields();
      fetchQuote();
      fetchRates();
    }
  }, [quoteIdRef.current]);

  useEffect(() => {
    if (form.getFieldValue('pickup_zipcode')) {
      fetchPickupCityList();
    }
  }, [form.getFieldValue('pickup_zipcode')]);

  useEffect(() => {
    if (form.getFieldValue('destination_zipcode')) {
      fetchDestinationCityList();
    }
  }, [form.getFieldValue('destination_zipcode')]);

  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    fectHolidays();
  }, []);

  useEffect(() => {
    setSelectedRates(
      rates?.filter((rate) => rate.selected).map((r) => r.id) ?? [],
    );
  }, [rates]);

  useEffect(() => {
    if (done() === total() && total() != -1) {
      setRatesLoading(false);
      stopPolling();
      setQuoteChanged(false);
    }
    if (done() < total()) {
      setRatesLoading(true);
      startPolling();
    }
  }, [status]);

  useEffect(() => {
    if (done() < total()) {
      setRatesLoading(true);
      restartPolling();
    }
  }, [rateInterval]);

  return (
    <>
      <Form
        layout="vertical"
        disabled={loading}
        onValuesChange={onFormValueChange}
        onFinish={handelSave}
        initialValues={{}}
        form={form}
      >
        <Row>
          <Space>
            <Form.Item
              name="user_id"
              label="Customer"
              rules={[{ required: true }]}
            >
              <UserSyncSelect style={{ width: '240px' }} />
            </Form.Item>
            <Text type="warning">
              The quotation is valid for {quoteValidHours} hours.
            </Text>
          </Space>
        </Row>
        <Row>
          <Col span={12}>
            <Space>
              <Form.Item
                label="Origin Zipcode"
                name="pickup_zipcode"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ width: 400 }}
                  disabled={disableChange}
                  placeholder="Enter pickup zipcode."
                />
              </Form.Item>
              <Form.Item
                label="Origin City"
                name="pickup_city_id"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: 400 }}
                  allowClear
                  disabled={disableChange}
                  placeholder="Please select city"
                  options={
                    pickupCityList?.map((item) => ({
                      value: item.id,
                      label: `${item.name}, ${item.state}`,
                    })) ?? []
                  }
                />
              </Form.Item>
            </Space>
          </Col>
          <Col span={12}>
            <Space>
              <Form.Item
                label="Destination Zipcode"
                name="destination_zipcode"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ width: 400 }}
                  disabled={disableChange}
                  placeholder="Enter destination zipcode."
                />
              </Form.Item>
              <Form.Item
                label="Destination City"
                name="destination_city_id"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: 400 }}
                  allowClear
                  disabled={disableChange}
                  placeholder="Please select city"
                  options={
                    destinationCityList?.map((item) => ({
                      value: item.id,
                      label: `${item.name}, ${item.state}`,
                    })) ?? []
                  }
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Divider />

        <Space direction="horizontal">
          <Form.Item
            label="Pickup Date"
            name="pickup_date"
            rules={[{ required: true }]}
            getValueFromEvent={(onChange) =>
              onChange ? moment(onChange).format('YYYY-MM-DD') : null
            }
            getValueProps={(v) => ({ value: v ? moment(v) : null })}
          >
            <DatePicker
              allowClear={false}
              disabled={disableChange}
              style={{ width: '100%' }}
              disabledDate={(current) => {
                if (holidaysLoading) {
                  return false;
                }
                return (
                  (current < moment().startOf('day') ||
                    holidays?.includes(moment(current).format('YYYY-MM-DD'))) ??
                  false
                );
              }}
            />
          </Form.Item>

          <Button
            disabled={disableChange}
            type="primary"
            loading={ratesLoading}
            onClick={handleStartGetRates}
          >
            Get Rates
          </Button>
          <Text
            type={
              total() === done() && done() === -1
                ? 'secondary'
                : total() === done() && !quoteErrors?.length
                ? 'success'
                : 'warning'
            }
          >
            {`Quote Progress: ${status}`}
          </Text>
          <Text>{quote?.quote_at ?? ''}</Text>
          <Text>{`Default Margin Setting ${
            quote?.margin_percent.toFixed(2) ?? '-'
          }%`}</Text>
        </Space>
        <Space direction="vertical">
          {quoteErrors?.map((error, index) => (
            <Text key={index} type="danger">
              {error}
            </Text>
          ))}
        </Space>
        <Row>
          <RateTable
            rates={rates ?? []}
            selectedRates={selectedRates}
            setSelectedRate={(key: Key) => setSelectedRates([key])}
            disableSelect={
              (quote?.business_status === QuoteBusinessStatus.DRAFT &&
                quoteChanged) ||
              (!!quote?.business_status &&
                quote?.business_status >= QuoteBusinessStatus.BOOKED)
            }
            handleSelect={handleSelect}
            handleUpdateRate={(rateId: number, newRate: number) => {
              setUpdatedRates([
                ...updatedRates.filter((r) => r.rateId !== rateId),
                { rateId, newRate },
              ]);
            }}
          />
        </Row>
        <Gap height="16px" />
        <Row>
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            {(!model?.id ||
              quote?.business_status === QuoteBusinessStatus.DRAFT) && (
              <Button type="primary" htmlType="submit" disabled={quoteChanged}>
                Save as Draft
              </Button>
            )}
            {(!model?.id ||
              [
                QuoteBusinessStatus.DRAFT,
                QuoteBusinessStatus.SUBMITED,
                QuoteBusinessStatus.CONFIRMED,
              ].includes(
                quote?.business_status ?? QuoteBusinessStatus.EXPIRED,
              )) && (
              <Button
                type="primary"
                disabled={quoteChanged}
                onClick={async () => {
                  const formValues = await form.getFieldsValue();
                  handelSave({
                    ...formValues,
                    business_status: QuoteBusinessStatus.CONFIRMED,
                  });
                }}
              >
                Confirm
              </Button>
            )}
          </Space>
        </Row>
      </Form>

      <ShipmentDrawer
        visible={shipformVisible}
        params={shipParams}
        onSaved={() => {
          history.push('/truckload/ftl/shipments');
        }}
        onClose={() => {
          setShipformVisible(false);
        }}
      />
    </>
  );
};

export default QuoteForm;
