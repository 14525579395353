import {
  Card,
  Tag,
  Space,
  Typography,
  Empty,
  Button,
  message,
  Table,
  Input,
} from 'antd';
import {
  RecognitionResult,
  DocumentType,
  InvoiceType,
} from '../interface/types';
import type { FileStatus, ShipmentData } from '../interface/types';
import { PlusOutlined } from '@ant-design/icons';
import NewBill from '../../bills/components/NewBill';
import { BILLABLE_TYPE } from '@/pages/accounting/bills/components/data';
import Decimal from 'decimal.js';
import { TLType } from '@/components/constants';
import { useState } from 'react';
import { ShipmentInterface } from '@/pages/truckload/ltl/shipments/Interfaces/ShipmentInterface';
import LTLShipmentDrawer from '@/pages/truckload/ltl/components/ShipmentDrawer';
import { FTLShipmentInterface } from '@/pages/truckload/ftl/shipments/Interfaces/ShipmentInterface';
import FTLShipmentDrawer from '@/pages/truckload/ftl/components/ShipmentDrawer';
import { CopyButton } from '@/components/CopyButton';
import { BillingCodeSelect } from '@/components/BillingCodeSelect';

const { Text } = Typography;

interface Props {
  result: RecognitionResult | null;
  onResultChange?: (key: string, value: string | any[] | null) => void;
  file: FileStatus | null;
  shipmentData?: ShipmentData;
  fileContent?: string;
  onBillCreated?: () => void;
}

const RecognitionResults = ({
  result,
  onResultChange,
  file,
  shipmentData,
  fileContent,
  onBillCreated,
}: Props) => {
  const getDocumentTypeTag = (
    type?: DocumentType,
    invoiceType?: InvoiceType,
  ) => {
    const colorMap: Record<string, string> = {
      invoice: 'blue',
      mbl: 'green',
      unknown: 'default',
    };

    const invoiceColorMap: Record<string, string> = {
      DRAY: 'purple',
      LTL: 'orange',
      FTL: 'cyan',
    };

    return (
      <Space>
        {type && (
          <Tag color={colorMap[type]}>{type.toString().toUpperCase()}</Tag>
        )}
        {type === 'invoice' && invoiceType && (
          <Tag color={invoiceColorMap[invoiceType]}>{invoiceType}</Tag>
        )}
      </Space>
    );
  };

  const getBillableType = (invoiceType?: InvoiceType): string => {
    switch (invoiceType) {
      case TLType.LTL.toUpperCase():
        return BILLABLE_TYPE.LTL;
      case TLType.FTL.toUpperCase():
        return BILLABLE_TYPE.FTL;
      case 'DRAY':
        return BILLABLE_TYPE.ORDER;
      default:
        return BILLABLE_TYPE.LTL;
    }
  };

  const BILL_TO_MAP: Record<string, string> = {
    [BILLABLE_TYPE.ORDER]: 'App\\Models\\Vendor',
    [BILLABLE_TYPE.LTL]: 'App\\Domains\\TL\\Models\\LTLVendor',
    [BILLABLE_TYPE.FTL]: 'App\\Domains\\FTL\\Models\\FTLVendor',
  };

  const BILLCODE_CATEGORY_MAP: Record<string, string> = {
    [BILLABLE_TYPE.ORDER]: 'DRAY',
    [BILLABLE_TYPE.LTL]: 'LTL',
    [BILLABLE_TYPE.FTL]: 'FTL',
  };

  // 修改 prepareInitialBillData 函数
  const prepareInitialBillData = (
    result: RecognitionResult,
    shipmentData: ShipmentData,
  ) => {
    const {
      invoice_date,
      invoice_number,
      due_date,
      terms,
      items,
      container_number,
    } = result;

    let saveData: any = {
      ...(invoice_date && { billed_at: invoice_date }),
      ...(invoice_number && { invoice_number }),
      ...(due_date && { due_at: due_date }),
      ...(terms && { terms }),
      ...(items && {
        charges: items.map((item) => ({
          ...(container_number && { containerNumber: container_number }),
          code: item.code,
          name: item.name || item.description,
          rate: item.rate,
          qty: item.qty,
        })),
      }),
      ...(shipmentData.vendor_id && {
        bill_to_id: shipmentData.vendor_id,
        bill_to_type: BILL_TO_MAP[getBillableType(file?.invoiceType)],
      }),
    };

    if (items) {
      const totalAmount = items
        .reduce(
          (acc, charge) =>
            acc.plus(
              new Decimal(charge.rate || 0).times(new Decimal(charge.qty || 0)),
            ),
          new Decimal(0),
        )
        .toNumber();
      const amountPaid = 0;
      const amountDue = new Decimal(totalAmount).minus(amountPaid).toNumber();
      saveData = {
        ...saveData,
        amount_total: totalAmount.toFixed(2),
        amount_due: amountDue.toFixed(2),
        amount_paid: amountPaid.toFixed(2),
      };
    }

    // 添加文件相关信息
    if (file?.fileId && fileContent) {
      const fileData = {
        name: file.fileName,
        content: fileContent,
        type: file.fileName.split('.').pop()?.toLowerCase() || '',
      };
      saveData.new_files = [fileData];
    }

    return saveData;
  };

  const handleValueChange = (key: string, value: string | any[]) => {
    if (onResultChange) {
      // 处理数组项的更新
      if (key.startsWith('items.')) {
        const [_, index, field] = key.split('.');
        const newItems = [...(result?.items || [])];
        newItems[parseInt(index)] = {
          ...newItems[parseInt(index)],
          [field]: value,
        };
        onResultChange('items', newItems);
      } else {
        onResultChange(key, value);
      }
    }
  };

  const renderLTLFTLResults = (result: RecognitionResult) => {
    return (
      <div style={{ padding: '0 16px' }}>
        {/* Basic Information Section */}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '16px',
            marginBottom: '24px',
          }}
        >
          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Invoice No
            </Text>
            <Input
              value={result.invoice_number}
              onChange={(e) =>
                handleValueChange('invoice_number', e.target.value)
              }
              bordered={false}
              style={{
                fontSize: '14px',
                fontWeight: 500,
                padding: '4px 8px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
              }}
            />
          </div>

          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Invoice Date
            </Text>
            <Input
              value={result.invoice_date}
              onChange={(e) =>
                handleValueChange('invoice_date', e.target.value)
              }
              bordered={false}
              style={{
                fontSize: '14px',
                fontWeight: 500,
                padding: '4px 8px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
              }}
            />
          </div>

          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Due Date
            </Text>
            <Input
              value={result.due_date}
              onChange={(e) => handleValueChange('due_date', e.target.value)}
              bordered={false}
              style={{
                fontSize: '14px',
                fontWeight: 500,
                padding: '4px 8px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
              }}
            />
          </div>

          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Terms
            </Text>
            <Input
              value={result.terms}
              onChange={(e) => handleValueChange('terms', e.target.value)}
              bordered={false}
              style={{
                fontSize: '14px',
                fontWeight: 500,
                padding: '4px 8px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
              }}
            />
          </div>

          <div style={{ gridColumn: '1 / -1' }}>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Bill From
            </Text>
            <Input
              value={result.bill_from}
              onChange={(e) => handleValueChange('bill_from', e.target.value)}
              bordered={false}
              style={{
                fontSize: '14px',
                fontWeight: 500,
                padding: '4px 8px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
              }}
            />
          </div>

          <div style={{ gridColumn: '1 / -1' }}>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Reference No
            </Text>
            <Input
              value={result.reference_number}
              onChange={(e) =>
                handleValueChange('reference_number', e.target.value)
              }
              bordered={false}
              style={{
                fontSize: '14px',
                fontWeight: 500,
                padding: '4px 8px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
              }}
            />
          </div>
        </div>
        {/* Charges Section */}
        {result.items && result.items.length > 0 && (
          <div>
            <Text
              strong
              style={{
                marginBottom: '16px',
                display: 'block',
                fontSize: '16px',
                color: '#262626',
              }}
            >
              Charges
            </Text>
            <div style={{ overflow: 'auto' }}>
              <Table
                dataSource={result.items}
                columns={[
                  {
                    title: 'Code',
                    dataIndex: 'code',
                    width: 150,
                    render: (text, record, index) => (
                      <BillingCodeSelect
                        value={text}
                        style={{
                          width: '100%',
                          minWidth: '120px',
                        }}
                        filterModel={(row, value) => {
                          return (
                            row.category ==
                            ((file?.invoiceType || '') as string)
                          );
                        }}
                        onSelect={(c) => {
                          if (c) {
                            const newItems = [...(result?.items || [])];
                            newItems[index] = {
                              ...newItems[index],
                              code: c.code,
                              name: c.name,
                            };
                            onResultChange?.('items', newItems);
                          }
                        }}
                      />
                    ),
                  },
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    width: 200,
                    render: (text, record, index) => (
                      <Input
                        value={text}
                        onChange={(e) =>
                          handleValueChange(
                            `items.${index}.name`,
                            e.target.value,
                          )
                        }
                        bordered={false}
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          padding: '4px 8px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '4px',
                          minWidth: '180px',
                        }}
                      />
                    ),
                  },
                  {
                    title: 'Rate',
                    dataIndex: 'rate',
                    width: 120,
                    render: (text, record, index) => (
                      <Input
                        value={text}
                        type="number"
                        step="0.01"
                        onChange={(e) => {
                          const value = e.target.value;
                          // 只允许数字和小数点
                          if (value === '' || /^\d*\.?\d*$/.test(value)) {
                            handleValueChange(`items.${index}.rate`, value);
                          }
                        }}
                        onBlur={(e) => {
                          // 失去焦点时格式化为两位小数
                          const value = e.target.value;
                          if (value) {
                            const formattedValue = Number(value).toFixed(2);
                            handleValueChange(
                              `items.${index}.rate`,
                              formattedValue,
                            );
                          }
                        }}
                        bordered={false}
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          padding: '4px 8px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '4px',
                          minWidth: '100px',
                        }}
                      />
                    ),
                  },
                  {
                    title: 'Qty',
                    dataIndex: 'qty',
                    width: 100,
                    render: (text, record, index) => (
                      <Input
                        value={text}
                        type="number"
                        step="0.01"
                        onChange={(e) => {
                          const value = e.target.value;
                          // 只允许数字和小数点
                          if (value === '' || /^\d*\.?\d*$/.test(value)) {
                            handleValueChange(`items.${index}.qty`, value);
                          }
                        }}
                        onBlur={(e) => {
                          // 失去焦点时格式化为两位小数
                          const value = e.target.value;
                          if (value) {
                            const formattedValue = Number(value).toFixed(2);
                            handleValueChange(
                              `items.${index}.qty`,
                              formattedValue,
                            );
                          }
                        }}
                        bordered={false}
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          padding: '4px 8px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '4px',
                          minWidth: '80px',
                        }}
                      />
                    ),
                  },
                  {
                    title: 'Amount',
                    dataIndex: 'amount',
                    width: 120,
                    render: (text, record, index) => (
                      <Input
                        value={text}
                        type="number"
                        step="0.01"
                        onChange={(e) => {
                          const value = e.target.value;
                          // 只允许数字和小数点
                          if (value === '' || /^\d*\.?\d*$/.test(value)) {
                            handleValueChange(`items.${index}.amount`, value);
                          }
                        }}
                        onBlur={(e) => {
                          // 失去焦点时格式化为两位小数
                          const value = e.target.value;
                          if (value) {
                            const formattedValue = Number(value).toFixed(2);
                            handleValueChange(
                              `items.${index}.amount`,
                              formattedValue,
                            );
                          }
                        }}
                        bordered={false}
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          padding: '4px 8px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '4px',
                          minWidth: '100px',
                        }}
                      />
                    ),
                  },
                ]}
                pagination={false}
                size="small"
                bordered
                style={{
                  backgroundColor: '#fff',
                  minWidth: '700px',
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderDrayResults = (result: RecognitionResult) => {
    return (
      <Table
        dataSource={[
          {
            key: 'invoice_number',
            label: 'Invoice No',
            value: result.invoice_number,
          },
          {
            key: 'invoice_date',
            label: 'Invoice Date',
            value: result.invoice_date,
          },
          { key: 'due_date', label: 'Due Date', value: result.due_date },
          { key: 'bill_from', label: 'Bill From', value: result.bill_from },
          { key: 'bill_to', label: 'Bill To', value: result.bill_to },
          {
            key: 'pickup_from',
            label: 'Pickup From',
            value: result.pickup_from,
          },
          {
            key: 'delivery_to',
            label: 'Delivery To',
            value: result.delivery_to,
          },
          {
            key: 'container_number',
            label: 'Container No',
            value: result.container_number,
          },
          { key: 'mbl_number', label: 'MBL No', value: result.mbl_number },
          { key: 'terms', label: 'Terms', value: result.terms },
          {
            key: 'reference_number',
            label: 'Reference No',
            value: result.reference_number,
          },
        ].filter((item) => item.value)}
        columns={[
          {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            render: (text) => (
              <Text className="tag-label" type="secondary">
                {text}:
              </Text>
            ),
          },
          {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => (
              <Input
                value={text}
                onChange={(e) => handleValueChange(record.key, e.target.value)}
                bordered={false}
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  padding: '4px 8px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '4px',
                }}
              />
            ),
          },
        ]}
        pagination={false}
        rowKey="key"
      />
    );
  };

  const [model, setModel] = useState<any>(null);
  const [shipformVisible, setShipformVisible] = useState(false);
  const handleShow = (m: any) => {
    setModel(m);
    setShipformVisible(true);
  };

  return (
    <div className="recognition-results-container">
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span>Recognition Results</span>
            {file && getDocumentTypeTag(file.documentType, file.invoiceType)}
          </div>
        }
        className="section-card"
      >
        {result ? (
          ['LTL', 'FTL'].includes(file?.invoiceType || '') ? (
            renderLTLFTLResults(result)
          ) : (
            renderDrayResults(result)
          )
        ) : (
          <Empty description="No recognition results" />
        )}
      </Card>

      <Card
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <span>Matched Shipment/Order Data</span>
            {shipmentData && (
              <NewBill
                billableId={parseInt(shipmentData.id)}
                billableType={getBillableType(file?.invoiceType)}
                container={result?.container_number}
                onSaved={() => {
                  message.success('Bill created successfully');
                  onBillCreated?.();
                }}
                initialValues={
                  result
                    ? prepareInitialBillData(result, shipmentData)
                    : undefined
                }
              >
                <Button type="primary" icon={<PlusOutlined />}>
                  New Bill
                </Button>
              </NewBill>
            )}
          </div>
        }
        className="section-card"
        style={{ marginTop: '16px' }}
      >
        {shipmentData ? (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Space>
                <Text strong>Shipment No:</Text>
                {file?.invoiceType === TLType.LTL.toUpperCase() ? (
                  <>
                    <Space>
                      <a
                        onClick={() =>
                          handleShow(
                            shipmentData.shipment_model as ShipmentInterface,
                          )
                        }
                      >
                        {shipmentData.shipment_model?.uid}
                      </a>
                      {
                        <CopyButton
                          value={shipmentData.shipment_model?.uid as string}
                        />
                      }
                    </Space>
                    <LTLShipmentDrawer
                      visible={shipformVisible}
                      model={{
                        ...model,
                        ...shipmentData.shipment_model,
                      }}
                      onSaved={(id: number) => {
                        setShipformVisible(false);
                      }}
                      onClose={() => {
                        setShipformVisible(false);
                      }}
                    />
                  </>
                ) : file?.invoiceType === TLType.FTL.toUpperCase() ? (
                  <>
                    <Space>
                      <a
                        onClick={() =>
                          handleShow(
                            shipmentData.shipment_model as FTLShipmentInterface,
                          )
                        }
                      >
                        {shipmentData.shipment_model?.uid}
                      </a>
                      {
                        <CopyButton
                          value={shipmentData.shipment_model?.uid as string}
                        />
                      }
                    </Space>
                    <FTLShipmentDrawer
                      visible={shipformVisible}
                      model={{
                        ...model,
                        ...shipmentData.shipment_model,
                      }}
                      onSaved={() => {
                        setShipformVisible(false);
                      }}
                      onClose={() => {
                        setShipformVisible(false);
                      }}
                    />
                  </>
                ) : (
                  <Text>{shipmentData.shipment_number}</Text>
                )}
              </Space>
              <Space>
                <Text strong>Status:</Text>
                <Tag
                  color={
                    shipmentData.business_status === 'Delivered'
                      ? 'green'
                      : 'blue'
                  }
                >
                  {shipmentData.business_status}
                </Tag>
              </Space>
            </Space>
          </div>
        ) : (
          <Empty description="No shipment data available" />
        )}
      </Card>
    </div>
  );
};

export default RecognitionResults;
