import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Row,
  message,
  Space,
  Popover,
  Divider,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { TLtlShipmentAction } from '../Interfaces/ActionInterface';

export const EditAction: React.FC<
TLtlShipmentAction & {
  action: string;
  name: string;
  value: string;
}
> = ({
  target,
  action,
  name,
  value,
  targetModel,
  task,
  onDone,
  onSaveOperation,
}) => {
  const app = useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    onSaveOperation(
      task,
      action,
      name,
      form.getFieldValue(name),
      setLoading,
      onDone,
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        placement="right"
        content={
          <>
            <Form
              layout="vertical"
              form={form}
              initialValues={{ [name]: value }}
            >
              <Form.Item name={name}>
                <Input placeholder={'Input'} allowClear />
              </Form.Item>
            </Form>
            <Divider />
            <Row>
              <Space>
                <Button type="primary" disabled={loading} onClick={handleSave}>
                  Save
                </Button>
              </Space>
            </Row>
          </>
        }
      >
        <Button type="link">{value ? value : <EditOutlined />}</Button>
      </Popover>
    </>
  );
};
