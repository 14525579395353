import { useState } from 'react';
import type { RecognitionResult, InvoiceType } from '../interface/types';
import { useApp } from '@/utils/useapp';
import { useDocumentClassify } from './useDocumentClassify';
import { generateAuthData } from '@/utils/drayaiAuth';

export function useRecognition() {
  const [recognitionResults, setRecognitionResults] = useState<
    Record<string, RecognitionResult>
  >({});
  const app = useApp();
  const { getBase64 } = useDocumentClassify();

  const startRecognition = async (
    file: File,
    fileId: string,
    invoiceType: InvoiceType,
  ) => {
    if (invoiceType === 'DRAY') {
      throw new Error(
        'DRAY type invoice recognition is temporarily not supported',
      );
    }
    try {
      const base64Content = await getBase64(file, false);
      const result: RecognitionResult = await app.service.api(
        `${DRAYAI_BASE_URL}/ai/invoice/extract`,
        {
          method: 'post',
          timeout: 600000,
          data: {
            file: {
              filename: file.name,
              content: base64Content,
            },
            type: invoiceType,
            ...generateAuthData(''),
          },
        },
      );

      result.status = 'success';
      result.invoice_type = invoiceType;

      try {
        const billCodeList = {
          invoice_type: invoiceType.toLowerCase(),
          items: [],
          ...generateAuthData(''),
        } as { invoice_type: InvoiceType; items: { description: string }[] };
        result.items.forEach((item: { description: string }) => {
          billCodeList.items.push({
            description: item.description,
          });
        });
        billCodeList.items = billCodeList.items.filter(
          (item) => item.description !== '',
        );
        const billCodeFill = await app.service.api(
          `${DRAYAI_BASE_URL}/ai/bill/code/classify`,
          {
            method: 'post',
            data: billCodeList,
          },
        );
        if (billCodeFill.items && Array.isArray(billCodeFill.items)) {
          // match billCodeFill.items with result.items with description
          result.items.forEach((item) => {
            const match = billCodeFill.items.find(
              (billCode: { description: string; code: string; name: string }) =>
                billCode.description === item.description,
            );
            if (match) {
              item.code = match.code;
              item.name = match.name;
            }
          });
        }
      } catch (error) {
        console.error('Bill code classification failed:', error);
      }

      setRecognitionResults((prev) => ({
        ...prev,
        [fileId]: result,
      }));

      return result;
    } catch (error) {
      console.error('Recognition failed:', error);
      throw error;
    }
  };

  const updateRecognitionResult = (
    fileId: string,
    key: string,
    value: string | any[] | null,
  ) => {
    setRecognitionResults((prev) => {
      if (!prev[fileId]) {
        return prev;
      }

      const updatedResult = { ...prev[fileId] };
      if (value === null) {
        delete updatedResult[key as keyof RecognitionResult];
      } else {
        (updatedResult[key as keyof RecognitionResult] as any) = value;
      }

      return {
        ...prev,
        [fileId]: updatedResult,
      };
    });
  };

  return {
    recognitionResults,
    startRecognition,
    updateRecognitionResult,
  };
}
