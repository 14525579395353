import React from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  message,
  Col,
  Checkbox,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { Header } from '@/components/CommonHeader';
import { Documents } from '@/components/Upload/Documents';

interface Props {
  model?: any;
  onCreated: () => void;
  onClose: () => void;
}

const VendorForm: React.FC<Props> = ({ model, onCreated, onClose }) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const id = model?.id;

  const [form] = useForm();

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`ftl/vendors/${id}`);
      form.setFieldsValue({
        ...resp.data,
        api_params: JSON.stringify(resp.data.api_params, null, 2),
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handelSave = async (values: any) => {
    let resp = null;

    if (values.api_params) {
      values.api_params = JSON.parse(values.api_params);
    }

    try {
      if (id) {
        resp = await app.service.put(`ftl/vendors/${id}`, {
          data: values,
        });
      } else {
        resp = await app.service.post('ftl/vendors', {
          data: values,
        });
      }

      onCreated && onCreated();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const validateJSON = (_: any, value: string) => {
    try {
      JSON.parse(value);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(new Error('The input is not valid JSON'));
    }
  };

  React.useEffect(() => {
    if (id) {
      form.resetFields();
      fetchData();
    }
  }, [id]);

  return (
    <>
      <Header title={id ? `Edit Vendor#${id}` : 'New Vendor'} />
      <Form
        layout="vertical"
        disabled={loading}
        onFinish={handelSave}
        initialValues={{}}
        form={form}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="Enter name." />
        </Form.Item>
        <Form.Item label="Code" name="code" rules={[{ required: true }]}>
          <Input placeholder="Enter code." />
        </Form.Item>
        <Form.Item
          label={
            <Space align="center">
              <span>Quick Link</span>
              <Form.Item
                name="use_shipment_id"
                valuePropName="checked"
                noStyle
                shouldUpdate
              >
                <Checkbox>
                  Use $ to represent the Vendor Shipment ID in the URL
                </Checkbox>
              </Form.Item>
            </Space>
          }
          name="quick_link"
        >
          <Input placeholder="Enter quick link URL." />
        </Form.Item>
        <Form.Item label="Terms" name="terms">
          <InputNumber
            style={{
              width: '100%',
            }}
            placeholder="Enter terms."
          />
        </Form.Item>
        <Form.Item label="Endpoint" name="endpoint">
          <Input placeholder="Enter endpoint." />
        </Form.Item>
        <Form.Item label="Token" name="token">
          <Input placeholder="Enter token." />
        </Form.Item>
        <Form.Item label="Secret Key" name="secret_key">
          <Input.TextArea rows={4} placeholder="Enter Secret Key." />
        </Form.Item>
        <Form.Item
          label="Api Params"
          name="api_params"
          rules={[{ validator: validateJSON }]}
        >
          <Input.TextArea rows={4} placeholder="Enter api params." />
        </Form.Item>
        <Form.Item
          label="Customer Portal Enable"
          name="customer_portal_enable"
          valuePropName="checked"
        >
          <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
        </Form.Item>
        <Form.Item
          label="Admin Portal Enable"
          name="admin_portal_enable"
          valuePropName="checked"
        >
          <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('id') ? (
              <Documents
                title="Documents"
                target="ftl_vendor"
                targetId={getFieldValue('id')}
              />
            ) : (
              <></>
            )
          }
        </Form.Item>
        <br />
        <Row gutter={10}>
          <Space direction="horizontal">
            <Button type="primary" loading={loading} htmlType="submit" block>
              Save
            </Button>
            <Button block onClick={() => onClose()}>
              Cancel
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};

export default VendorForm;
