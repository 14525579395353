import { Button, Popover, Table } from 'antd';
import React, { useState } from 'react';
import { RenderActions } from './RenderActions/index';
import { ColumnsType } from 'antd/es/table';
import { TaskTitlePopover } from './TaskTitlePopover';
import { RenderSla } from './RenderSla';
import { TitleSlaTooltip } from './TitleSlaTooltip';
import { RenderTaskColumn } from './RenderTaskColumn';
import { useApp } from '@/utils/useapp';
import useActionTasks from './useActionTasks';
import { TContainer } from '@/types';

export const formatActionInfo = (
  item: TItem,
  action: string,
  update_name?: string,
  value?: any,
) => {
  const data = {
    task: item.task,
    task_type: item.task_type,
    setting_container_task_id: item.setting_container_task_id,
    id: item?.id,
    name: update_name,
    value,
    action,
  };

  return data;
};

export type TItem = {
  task: string;
  task_type: string;
  whose_task: number | null;
  setting_container_task_id: number;
  is_observe_update: boolean;
  id?: number;
  sop: string;
  color_type: number;
  action: string;
  action_at?: string;
  sla?: number;
  reset_sla_memo?: string;
  show_reset_sla?: boolean;
  sla_text?: string;
  due_at?: string;
  finished_at?: string;
  is_overdue?: boolean;
  is_done?: boolean;
  is_filter_status?: boolean;
  disabled?: boolean;
  disabled_date?: boolean;
  text_color: number;
  action_by?: {
    name: string;
  };
  actions?: Array<string>;
};

export type TContainerProps = {
  container: any;
};
export type TForceFetchProps = {
  forceFetch: boolean;
  setForceFetch: (forceFetch: boolean) => void;
};
export type TCommonProps = {
  taskType: 'file' | 'vendor' | 'warehouse';
} & TContainerProps;

export type TOnDoneProps = {
  onDone?: () => void;
};
export type TItemActionProps = {
  action:
    | 'DONE'
    | 'NA'
    | 'UNDO'
    | 'YES'
    | 'NO'
    | 'INPUT'
    | 'INPUTANDACTION'
    | 'SEND POD';
  name?: any;
  value?: any;
};
export type TOnCheckProps = {
  onCheck: (
    _: {
      task: string;
      task_type: string;
      setting_container_task_id: number;
      id?: number | null;
    } & TItemActionProps,
  ) => void;
};

export type TItemProps = {
  item: TItem;
  container: TContainer;
};

export type TActionTasksProps = {
  children?: React.ReactElement;
  color: string;
} & TCommonProps &
  TOnDoneProps;

export const ActionTasks: React.FC<TActionTasksProps> = ({
  taskType,
  children,
  color,
  onDone,
  // onCheck,
  container,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { fetchData, updateContainerTask } = useActionTasks();

  const handleOpenChange = async (open: boolean) => {
    if (!open) {
      return;
    }

    setLoading(true);
    const resp = await fetchData(container.id, taskType, setLoading);
    setData(resp);
    setLoading(false);
  };

  const handleOnCheck = async (data: { [key: string]: any }) => {
    await updateContainerTask(container.id, data, afterUpdated);
  };

  const afterUpdated = () => {
    handleOpenChange(true);
    onDone && onDone();
  };

  const columns: ColumnsType<any> = [
    {
      title: <TaskTitlePopover />,
      dataIndex: 'task',
      render: (_, record: any) => (
        <RenderTaskColumn item={record} container={container} />
      ),
    },
    {
      title: <TitleSlaTooltip />,
      dataIndex: 'sla',
      width: 180,
      render: (_, record: any) => {
        return (
          <RenderSla
            item={record}
            container={container}
            showReset={true}
            onSaved={afterUpdated}
          />
        );
      },
    },
    {
      title: 'Action',
      width: 300,
      render: (_, record: any, index: number) => {
        return (
          <RenderActions
            taskType={taskType}
            item={record}
            container={container}
            onCheck={handleOnCheck}
            onDone={afterUpdated}
          />
        );
      },
    },
  ];

  return (
    <>
      <Popover
        mouseEnterDelay={0.05}
        mouseLeaveDelay={0.05}
        zIndex={1000}
        placement="left"
        onOpenChange={handleOpenChange}
        content={
          <div style={{ maxWidth: 1000 }}>
            <Table
              columns={columns}
              loading={loading}
              dataSource={data || []}
              bordered
              size="small"
              pagination={false}
              scroll={{ x: '100%', y: 700 }}
              rowKey="task"
            />
          </div>
        }
      >
        <Button type="link" size="small">
          <span className={`text-${color}`}>{children}</span>
        </Button>
      </Popover>
    </>
  );
};
