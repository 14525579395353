import React from 'react';
import { Button, Form, Input, Row, Space, Switch, message } from 'antd';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { Header } from '@/components/CommonHeader';

interface Props {
  model?: any;
  onCreated: () => void;
  onClose: () => void;
}

const CarrierForm: React.FC<Props> = ({ model, onCreated, onClose }) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const id = model?.id;

  const [form] = useForm();

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`ftl/carriers/${id}`);
      form.setFieldsValue({
        ...resp.data,
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handelSave = async (values: any) => {
    try {
      if (id) {
        await app.service.put(`ftl/carriers/${id}`, {
          data: values,
        });
      } else {
        await app.service.post('ftl/carriers', {
          data: values,
        });
      }

      onCreated && onCreated();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  React.useEffect(() => {
    if (id) {
      form.resetFields();
      fetchData();
    }
  }, [id]);

  return (
    <>
      <Header title={id ? `Edit Carrier#${id}` : 'New Carrier'} />
      <Form
        layout="vertical"
        disabled={loading}
        onFinish={handelSave}
        initialValues={{}}
        form={form}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="Enter name." />
        </Form.Item>
        <Form.Item label="Scac Code" name="scac_code">
          <Input placeholder="Enter scac code." />
        </Form.Item>
        <Form.Item label="Dot Number" name="dot_number">
          <Input placeholder="Enter dot number." />
        </Form.Item>
        <Form.Item label="Mc Number" name="mc_number">
          <Input placeholder="Enter mc number." />
        </Form.Item>
        <Row gutter={10}>
          <Space direction="horizontal">
            <Button type="primary" loading={loading} htmlType="submit" block>
              Save
            </Button>
            <Button block onClick={() => onClose()}>
              Cancel
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};

export default CarrierForm;
