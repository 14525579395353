import { useApp } from '@/utils/useapp';
import {
  Drawer,
  Space,
  Button,
  message,
  Row,
  Col,
  Tabs,
  Card,
  Spin,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import { useForm } from 'antd/lib/form/Form';
import BillForm from '../../../bills/components/BillDrawer/BillForm';
import { ApproveButton } from '@/pages/accounting/bills/components/BillDrawer/ApproveButton';
import React from 'react';
import { BILL_TYPE_ORDER } from '@/pages/accounting/bills/components/data';

interface IBillTab {
  invoiceableId: number;
  invoice?: any;
  containerNumber: string;
  setBills: (bills: any) => void;
}

export const BillsTab: React.FC<IBillTab> = ({
  invoiceableId,
  invoice,
  containerNumber,
  setBills,
  ...props
}) => {
  const app = useApp();

  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);
  const [tabs, setTabs] = React.useState<any>([]);
  const [containers, setContainers] = React.useState<Array<any>>([]);

  const fetchContainers = async () => {
    const resp = await app.service.get(`orders/${invoiceableId}/containers`);
    setContainers(resp.data);
  };

  const fetchBillData = async (billId: number) => {
    setLoading(true);

    try {
      const resp = await app.service.get(`orderBills/${billId}`);

      form.setFieldsValue(resp.data);

      fetchContainers();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const fetchBills = async () => {
    if (!invoiceableId) {
      return;
    }
    setLoading(true);
    try {
      const result = await app.service.get(
        'orderBills/getOrderBillsByOrderId',
        {
          params: {
            order_id: invoiceableId,
            container_number: containerNumber || undefined,
            invoice_id: invoice?.id || undefined,
          },
        },
      );

      setTabs(result.data);

      setBills(result.data);
      result.data[0] && fetchBillData(result.data[0].id);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleApprovedOrUnApproved = (billId: number) => {
    fetchBillData(billId);
  };

  const handleChangeTab = (activeKey: string) => {
    fetchBillData(Number(activeKey));
  };

  React.useEffect(() => {
    fetchBills();
  }, [invoiceableId]);

  const items = React.useMemo(
    () =>
      tabs.map((bill: any) => {
        return {
          label: `Bill ${bill.id}`,
          key: '' + bill.id,
          children: (
            <Card {...props} style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
              <div className="flex justify-end">
                <ApproveButton
                  form={form}
                  billId={bill.id}
                  onApprovedOrUnApproved={handleApprovedOrUnApproved}
                  size={'small'}
                  onSetLoading={setLoading}
                />
              </div>
              <BillForm
                form={form}
                disabled={true}
                containers={containers}
                billableType={BILL_TYPE_ORDER}
              />
            </Card>
          ),
        };
      }),
    [tabs],
  );

  return (
    <Spin spinning={loading}>
      {tabs.length > 0 ? (
        <Tabs type="card" items={items} onChange={handleChangeTab} />
      ) : (
        <Card style={{ width: '100%', textAlign: 'center' }}> Empty Bill</Card>
      )}
    </Spin>
  );
};
