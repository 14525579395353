import { UserSyncSelect } from '@/components/UserSyncSelect';
import { TAccount } from '@/types';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd';
import { debounce, find, set } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { use } from 'echarts';
import { Documents } from '@/components/Upload/Documents';

interface IPrepayOrderDetailProps {
  id?: number;
  accounts: Array<TAccount>;
  banks: Array<any>;
  onClose: (reload?: boolean) => void;
}
const FIXED = 1;
const FLOATING = 0;

const PrepayOrderDetail: FC<IPrepayOrderDetailProps> = ({
  banks,
  onClose,
  id,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);
  const [bank, setBank] = useState<number>();
  const [fileList, setFileList] = useState<any[]>([]);
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [exchangeRates, setExchangeRates] = useState<{
    [key: string]: number;
  }>({
    USD: 1,
  });

  const [form] = useForm();

  const exhcangeType = useWatch('exchange_type', form);
  const exchangeRate = useWatch('exchange_rate', form);
  const amount = useWatch('amount', form);
  const adjustment = useWatch('adjustment', form);

  const close = useCallback(() => {
    if (!wasChanged) {
      return onClose();
    }
    Modal.confirm({
      title: 'Warning',
      content: 'Are you sure you want to cancel the edited content?',
      onOk: () => onClose(),
      maskClosable: true,
    });
  }, [wasChanged]);

  const bankInfo = useMemo(() => {
    const info = find(banks, { id: bank });
    return info || null;
  }, [bank]);

  const cost = useMemo(() => {
    const info = find(banks, { id: bank });
    return parseFloat(`${info?.custom_properties?.exchange_rate_cost || 0}`);
  }, [bankInfo]);

  const rate = useMemo(() => {
    if (exhcangeType == FIXED) {
      return exchangeRate || 1;
    } else if (exhcangeType == FLOATING) {
      return exchangeRates[bankInfo?.currency] || 1;
    }
  }, [exchangeRate, exhcangeType, bankInfo, exchangeRates]);

  const usdCurrency = useMemo(() => {
    console.log(amount, +rate, cost);
    return Math.floor((amount / (+rate + cost)) * 100) / 100;
  }, [amount, rate]);

  const creditAmount = useMemo(() => {
    return +usdCurrency + (+adjustment || 0);
  }, [usdCurrency, adjustment]);

  const isEdit = useMemo(() => {
    return !!id;
  }, []);

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }
    setLoading(true);
    try {
      const result = await app.service.get(`prepaidOrders/${id}`);
      form.setFieldsValue({
        user_id: result.account.user_id,
        bank: result.bank_id,
        amount: result.prepaid_amount,
        exchange_rate: result.exchange_rate,
        exchange_type: result.exchange_type,
        adjustment: result.adjustment,
        remark: result.remark,
      });
      setBank(result.bank_id);
      setExchangeRates({
        ...exchangeRates,
        [result.bank.currency]: result.exchange_rate,
      });
      setOrderNumber(result.order_number);
    } catch (e: any) {
      message.error(e.toString() || 'Failed');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpload = (info: any) => {
    let fileList: any[] = [...info.fileList];

    fileList = fileList.slice(-1);

    setFileList(fileList);
    form.setFieldValue('document', info.file);
  };

  const onFinish = useCallback(async (values: any) => {
    setLoading(true);
    const formData = new FormData();
    if (!isEdit) {
      formData.append('documents[0]', values['document']);
      formData.append('user_id', values['user_id']);
      formData.append('amount', values['amount']);
      formData.append('bank', values['bank']);
      formData.append('exchange_rate', values['exchange_rate'] || 1);
      formData.append('exchange_type', values['exchange_type']);
      formData.append('adjustment', values['adjustment'] || 0);
      formData.append('remark', values['remark'] || '');
    }

    try {
      let services = null;
      if (isEdit) {
        services = app.service.put(`prepaidOrders/${id}`, {
          data: {
            ...values,
            exchange_rate: values['exchange_rate'] || 1,
          },
        });
      } else {
        services = await app.service.post('prepaidOrders', {
          data: formData,
        });
      }

      const result = await services;

      if (result) {
        message.success('Success');
        onClose(true);
      }
    } catch (e: any) {
      message.error(e.data?.message || 'Failed');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isEdit) {
      (async () => {
        for (const v of banks) {
          if (v.currency != 'USD') {
            try {
              const rate = await app.service.get(
                `prepaidOrders/exchange-rate?from=${v.currency}&to=USD`,
              );

              setExchangeRates({ ...exchangeRates, [v.currency]: rate || 1 });
            } catch (e) {
              message.error('Failed to get exchange rate for ' + v.currency);
              setLoading(false);
            }
          }
        }
      })();
    } else {
      loadData();
    }
  }, [banks]);

  useEffect(() => {
    if (exhcangeType == FLOATING) {
      form.setFieldsValue({ exchange_rate: exchangeRates[bankInfo?.currency] });
    }
  }, [exhcangeType, exchangeRates, bankInfo]);

  return (
    <Drawer width={900} open={true} closable={false} onClose={close}>
      <h2>{!isEdit ? 'New' : 'Editing ' + orderNumber}</h2>
      <Divider />
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onChange={() => setWasChanged(true)}
        onFinish={onFinish}
        initialValues={{ payment: 'offline' }}
        autoComplete="off"
      >
        <Spin spinning={loading} tip="Loading...">
          <Form.Item name="id" shouldUpdate hidden>
            <Input />
          </Form.Item>
          <Form.Item name="order_number" shouldUpdate hidden>
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 12 }}
            rules={[{ required: true }]}
            label="Customer"
            name="user_id"
          >
            <UserSyncSelect type="user" />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 12 }}
            name="bank"
            label="Payment method"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Bank Account"
              options={banks.map((v) => ({ label: v.name, value: v.id }))}
              onChange={(v) => setBank(v)}
            />
          </Form.Item>
          <Row>
            <Col span={10}>
              <Form.Item
                name="amount"
                label="Amount"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={0}
                  style={{ width: 150 }}
                  prefix={bankInfo?.currency}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                name="exchange_type"
                label="Exchange Type"
                initialValue={0}
                rules={[{ required: true }]}
              >
                <Select>
                  <Select.Option value={FLOATING}>Floating</Select.Option>
                  <Select.Option value={FIXED}>Fixed </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {bankInfo && bankInfo.currency !== 'USD' && (
            <Row>
              <Col span={10}>
                <Form.Item
                  label="Currency"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                >
                  <Input
                    readOnly
                    disabled
                    style={{ width: 150 }}
                    prefix="USD"
                    value={usdCurrency.toFixed(2)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="exchange_rate"
                  label="Exchange Rate"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                >
                  <Input
                    readOnly={form.getFieldValue('exchange_type') == FLOATING}
                    style={{ width: 120 }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Exchange Loss: "
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 8 }}
                >
                  <span className="ant-form-text"> {cost.toFixed(2)}</span>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={10}>
              <Form.Item
                name="adjustment"
                label="Adjustment"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <InputNumber style={{ width: 150 }} prefix={'USD'} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                label="Credit Amount"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <InputNumber
                  style={{ width: 150 }}
                  prefix={'USD'}
                  readOnly
                  disabled
                  value={creditAmount.toFixed(2)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item name="remark" label="Remark" labelCol={{ span: 12 }}>
                <Input.TextArea style={{ width: '200%', maxWidth: '200%' }} />
              </Form.Item>
            </Col>
          </Row>
          {!isEdit ? (
            <Form.Item
              name="document"
              label="Screenshot"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 12 }}
              rules={[{ required: true }]}
              valuePropName="document"
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e.fileList;
              }}
            >
              <Dragger
                beforeUpload={() => false}
                name="image"
                listType="picture"
                fileList={fileList}
                onChange={handleUpload}
                maxCount={1}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibit from
                  uploading company data or other band files
                </p>
              </Dragger>
            </Form.Item>
          ) : (
            <Form.Item
              label="Screenshot"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 12 }}
            >
              <Documents title="" target="prepaid_orders" targetId={id || 0} />
            </Form.Item>
          )}
          <Divider />
          <Row>
            <Col offset={4} className="space">
              <Button type="default" onClick={close}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={loading}
                onClick={() => form.submit()}
              >
                {!isEdit ? 'Submit' : 'Update'}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Drawer>
  );
};

export default PrepayOrderDetail;
