export const CODE_PIERPASS = 'PIEPA';
export const CODE_CLEAN_TRUCK_FEE = 'CLETR';
export const CODE_GATE_FEE = 'GATFE';
export const CODE_SECURITY_FEE = 'PORSE';
export const CODE_BOSTON_USAGE_FEE = 'PRTUG';
export const TERMINAL_FEES = [
  CODE_PIERPASS,
  CODE_CLEAN_TRUCK_FEE,
  CODE_GATE_FEE,
  CODE_SECURITY_FEE,
  CODE_BOSTON_USAGE_FEE,
];

export const STATE_ESTIMATE = 'estimate';

export const INVOICE_TYPE_PRIMARY = 0;
export const INVOICE_TYPE_ADDITIONAL = 1;
export const INVOICE_TYPE_MAP = {
  [INVOICE_TYPE_PRIMARY]: 'Primary',
  [INVOICE_TYPE_ADDITIONAL]: 'Addtional',
};

export const STATUS_IN_DISPUTE = 1;
export const STATUS_CLOSE = 6;

export const STATUS_MAP = {
  [STATUS_IN_DISPUTE]: 'In dispute',
  [STATUS_CLOSE]: 'Dispute closed',
};

export const INVOICE_TYPE_ORDER = 'App\\Models\\Order';
export const INVOICE_TYPE_LTL = 'App\\Domains\\TL\\Models\\LTLShipment';
export const INVOICE_TYPE_FTL = 'App\\Domains\\FTL\\Models\\FTLShipment';

export const PAYMENT_METHOD_CASH = 1;
export const PAYMENT_METHOD_PREPAY = 2;

export const PAYMENT_METHOD_MAP = {
  [PAYMENT_METHOD_CASH]: 'Cash',
  [PAYMENT_METHOD_PREPAY]: 'Prepay',
};
