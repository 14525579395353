const TITLE_SOLUTION_EXPERT = 'Solution Expert';
const TITLE_SOLUTION_SPECIALTY = 'Solution Specialty';
const TITLE_OP = 'OP';
const TITLE_OA = 'OA';
const TITLE_OTR = 'OTR';
const TITLE_CPS = 'CPS';
const TITLE_ENGINEER = 'Engineer';
const TITLE_TRACKING = 'Tracking';
const TITLE_TRAINEE = 'Trainee';
const TITLE_ACCOUNTING = 'Accounting';
const TITLE_CONTROLLER = 'Controller';

export const TITLES = [
  TITLE_SOLUTION_EXPERT,
  TITLE_SOLUTION_SPECIALTY,
  TITLE_OP,
  TITLE_OA,
  TITLE_OTR,
  TITLE_CPS,
  TITLE_ENGINEER,
  TITLE_TRACKING,
  TITLE_TRAINEE,
  TITLE_ACCOUNTING,
  TITLE_CONTROLLER,

  // 'Solution Expert',
  // 'Solution Specialty',
  // 'OP',
  // 'OA',
  // 'OTR',
  // 'CPS',
  // 'Engineer',
  // 'Tracking',
  // 'Trainee',
  // 'Accounting',
  // 'Controller',
];

export const OPDASHBOARD_TITLES = [
  TITLE_SOLUTION_EXPERT,
  TITLE_SOLUTION_SPECIALTY,
  TITLE_OP,
  TITLE_OA,
];
