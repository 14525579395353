import { Space, Tooltip } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import Decimal from 'decimal.js';

type Props = {
  rate: any;
  code: string;
  label?: string;
  qty?: number;
  showEmptyLabel?: boolean;
  container?: any;
};

const Explanation = ({ rate }: { rate: any }) =>
  rate.explanation && (
    <Tooltip title={rate.explanation}>
      <span className="text-pointer">
        <InfoCircleOutlined />
      </span>
    </Tooltip>
  );

const EmptyRateLabel: React.FC<{ label: string }> = ({ label }) => (
  <>
    <Space>
      <span className="text-danger">{label}</span> : -
    </Space>
  </>
);

export const RatePossibleChargeText: React.FC<Props> = ({
  rate,
  code,
  label,
  qty,
  showEmptyLabel,
  container,
}) => {
  const vendorPossibleCharges: any[] = get(rate, 'vendor.possible_charges', []);
  const irPossibleCharges: any[] = get(
    rate,
    'intermodal_region.possible_charges',
    [],
  );
  const vendorPs = vendorPossibleCharges.find((ps) => ps.code == code);
  const irPs = irPossibleCharges.find((ps) => ps.code == code);

  const compareRate = useMemo(() => {
    if (!container || !container.snapshot) {
      return null;
    }
    const possibleCharge = get(container.snapshot, 'possible_charges')?.find(
      (p) => p.code == code,
    );
    return possibleCharge?.rate;
  }, [container, code]);

  let showRedColor = false;

  if (vendorPs && irPs && parseFloat(vendorPs.rate) > parseFloat(irPs.rate)) {
    showRedColor = true;
  }

  if (vendorPs) {
    return showRedColor ? (
      <Tooltip title={`IR Possible Charge for ${code}: ${irPs.rate}`}>
        <Space>
          {label && <span>{label} :</span>}
          <span className="text-danger">
            <RenderCompareRate
              rate={vendorPs.rate}
              compareRate={compareRate}
              qty={qty}
            />
          </span>
          <Explanation rate={vendorPs} />
        </Space>
      </Tooltip>
    ) : (
      <Space>
        {label && <span>{label} :</span>}
        <RenderCompareRate
          rate={vendorPs.rate}
          compareRate={compareRate}
          qty={qty}
        />
        <Explanation rate={vendorPs} />
      </Space>
    );
  }

  if (showEmptyLabel && label) {
    return <EmptyRateLabel label={label} />;
  }
  return <>-</>;
};

export const getWhsRateAndHours = (
  rate: any,
): { whsRate: any; hours: number; freeHours: number } => {
  const whsRate = get(rate, 'vendor.possible_charges', []).find(
    (ps) => ps.code == 'WARWA',
  );
  const freeHours = get(whsRate, 'range.free', 0);

  let hours = 2;
  if (freeHours <= 2) {
    hours = 2 - Number(freeHours);
  }

  return {
    whsRate: whsRate,
    hours,
    freeHours,
  };
};

export const WhsWaitingRateText: React.FC<{
  whsRate: any;
  hours: number;
  freeHours?: number;
}> = ({ whsRate, hours, freeHours }) => {
  if (whsRate) {
    const amount = `${whsRate.rate} x ${hours}`;

    return (
      <Space>
        Warehouse Waiting Time:
        {freeHours ? (
          <Tooltip title={`${freeHours} HR Free`}>
            <span className="text-pointer">{amount}</span>
          </Tooltip>
        ) : (
          amount
        )}
        <Explanation rate={whsRate} />
      </Space>
    );
  }

  return (
    <>
      <EmptyRateLabel label="Warehouse Waiting Time" />
    </>
  );
};

export const RenderCompareRate: React.FC<{
  rate: number;
  compareRate: number;
  qty?: number;
}> = ({ rate, compareRate, qty }) => {
  if (rate && !compareRate) {
    return rate;
  } else if (!rate && compareRate) {
    return (
      <>
        {rate} (<span className="text-danger">{`-${compareRate}`}</span>)
      </>
    );
  }

  const diff = new Decimal(rate).minus(new Decimal(compareRate)).toNumber();
  const absDiff = new Decimal(diff).abs().toNumber();

  return (
    <div>
      {rate}
      {diff !== 0 && (
        <span className={diff > 0 ? 'text-danger' : 'text-success'}>
          ({diff > 0 ? `-${absDiff}` : `+${absDiff}`})
        </span>
      )}
      {`${qty ? ' x ' + qty : ''}`}
    </div>
  );
};
